import { put, fork, takeLatest, call, all } from 'redux-saga/effects';
import actions from './actions';
import * as notifications from '../notifications';
import api from '../api';

const API = new api();
API.createEntity('reports');

function* getReport() {
  yield all([
    // fork(getDailySale),
    // fork(getDailySaleByMethod),
    fork(getTopProducts),
    fork(getTopClients),
    fork(getTopRecommended),
    fork(getAppointmentsPerDate),
    fork(getClientsComparative),
    fork(getDemographicReport),
    fork(getFillPercentage),
    fork(getClientsWithOutstandingBalance),
  ]);
}

function* getTopClients() {
  try {
    const res = yield call(API.endpoints.reports.getAllPath, '/top/clients');
    yield put(actions.set('topClients', { loading: false, data: res.data }));
  } catch (error) {
    let message = error.message;
    if (error.response && error.response.data)
      message = error.response.data.message;
    yield put(actions.set('topClients', { loading: false, data: [] }));
    yield put(notifications.show('error', message));
  }
}

function* getTopProducts() {
  try {
    const res = yield call(API.endpoints.reports.getAllPath, '/top/products');
    yield put(actions.set('topProducts', { loading: false, data: res.data }));
  } catch (error) {
    let message = error.message;
    if (error.response && error.response.data)
      message = error.response.data.message;
    yield put(actions.set('topProducts', { loading: false, data: [] }));
    yield put(notifications.show('error', message));
  }
}

function* getTopRecommended() {
  try {
    const res = yield call(
      API.endpoints.reports.getAllPath,
      '/top/recommended'
    );
    yield put(
      actions.set('topRecommended', { loading: false, data: res.data })
    );
  } catch (error) {
    let message = error.message;
    if (error.response && error.response.data)
      message = error.response.data.message;
    yield put(actions.set('topRecommended', { loading: false, data: [] }));
    yield put(notifications.show('error', message));
  }
}

function* getAppointmentsPerDate() {
  try {
    const res = yield call(API.endpoints.reports.getAllPath, '/appointments');
    yield put(actions.set('appointments', { loading: false, data: res.data }));
  } catch (error) {
    let message = error.message;
    if (error.response && error.response.data)
      message = error.response.data.message;
    yield put(actions.set('appointments', { loading: false, data: [] }));
    yield put(notifications.show('error', message));
  }
}

function* getClientsComparative() {
  try {
    const res = yield call(
      API.endpoints.reports.getAllPath,
      '/clients/comparative'
    );
    yield put(actions.set('comparative', { loading: false, value: res.data }));
  } catch (error) {
    let message = error.message;
    if (error.response && error.response.data)
      message = error.response.data.message;
    yield put(actions.set('comparative', { loading: false, value: null }));
    yield put(notifications.show('error', message));
  }
}

function* getDailySale() {
  try {
    const res = yield call(API.endpoints.reports.getAllPath, '/daily/sale');
    yield put(actions.set('sale', { loading: false, value: res.data }));
  } catch (error) {
    let message = error.message;
    if (error.response && error.response.data)
      message = error.response.data.message;
    yield put(actions.set('sale', { loading: false, value: null }));
    yield put(notifications.show('error', message));
  }
}

function* getDailySaleByMethod() {
  try {
    const res = yield call(
      API.endpoints.reports.getAllPath,
      '/daily/methods/sale'
    );
    yield put(actions.set('saleByMethods', { loading: false, data: res.data }));
  } catch (error) {
    let message = error.message;
    if (error.response && error.response.data)
      message = error.response.data.message;
    yield put(actions.set('saleByMethods', { loading: false, data: [] }));
    yield put(notifications.show('error', message));
  }
}

function* getDemographicReport() {
  try {
    const res = yield call(API.endpoints.reports.getAllPath, '/demographic');
    yield put(actions.set('demographic', { loading: false, data: res.data }));
  } catch (error) {
    let message = error.message;
    if (error.response && error.response.data)
      message = error.response.data.message;
    yield put(actions.set('demographic', { loading: false, data: [] }));
    yield put(notifications.show('error', message));
  }
}

function* getFillPercentage() {
  try {
    const res = yield call(
      API.endpoints.reports.getAllPath,
      '/fill/percentage'
    );
    yield put(actions.set('fill', { loading: false, data: res.data }));
  } catch (error) {
    let message = error.message;
    if (error.response && error.response.data)
      message = error.response.data.message;
    yield put(actions.set('fill', { loading: false, data: {} }));
    yield put(notifications.show('error', message));
  }
}

function* getClientsWithOutstandingBalance() {
  try {
    const res = yield call(
      API.endpoints.reports.getAllPath,
      '/outstanding/balance'
    );
    yield put(
      actions.set('clientsWithOutstandingBalance', {
        loading: false,
        data: res.data,
      })
    );
  } catch (error) {
    let message = error.message;
    if (error.response && error.response.data)
      message = error.response.data.message;
    yield put(
      actions.set('clientsWithOutstandingBalance', { loading: false, data: {} })
    );
    yield put(notifications.show('error', message));
  }
}

export default function* index() {
  yield takeLatest(actions.GET_REPORT, getReport);
}
