import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
    chartContainer: {
        padding: 10
    },
    space: {
        marginBottom: 10
    },
    spacelg: {
        marginBottom: 20
    }
}));