const actions = {
    GET_ALL: "ACTIVITY/GET_ALL",
    SET_ACTIVITIES: "ACTIVITY/SET_ACTIVITIES",
    SET: "ACTIVITY/SET",
    GET_SALES_BY_METHOD: "ACTIVITY/GET_SALES_BY_METHOD",
    SET_SALES_BY_METHOD: "ACTIVITY/SET_SALES_BY_METHOD",

    getAll: (startDate, endDate) => ({
        type: actions.GET_ALL,
        payload: { startDate, endDate }
    }),
    setActivities: (data) => ({
        type: actions.SET_ACTIVITIES,
        payload: { data }
    }),
    set: (type, data) => ({
        type: actions.SET,
        payload: { type, data }
    }),
    getSalesByMethod: (startDate, endDate) => ({
        type: actions.GET_SALES_BY_METHOD,
        payload: { startDate, endDate }
    }),
    setSalesByMethod: (data) => ({
        type: actions.SET_SALES_BY_METHOD,
        payload: { data }
    })
}

export default actions;