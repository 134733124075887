/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import {
  Grid,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  TablePagination,
  CircularProgress,
  Input,
  InputAdornment,
} from '@material-ui/core';
import { GetAppOutlined, Add, Search as SearchIcon } from '@material-ui/icons';
import { useStyles } from './Clients.styles';
import ItemList from './components/ItemList';
import { connect } from 'react-redux';
import actions from '../../../redux/client/actions';
import * as notifyActions from '../../../redux/notifications';
import ClientDialog from './components/ClientDialog';
import { debounce } from 'lodash';

const Dashboard = ({
  clients,
  loading,
  exportLoading,
  error,
  created,
  getAll,
  create,
  showNotify,
  selectProfile,
  exportToExcel,
}) => {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [query, setQuery] = useState('');

  // useEffect(() => {
  // 	getAll({ text: query, page, limit: rowsPerPage });
  // }, []);

  const debouncedFetchHits = useRef(
    debounce((query, page, rowsPerPage) => {
      getAll({ text: query, page, limit: rowsPerPage });
    }, 500)
  ).current;

  useEffect(() => {
    debouncedFetchHits(query, page, rowsPerPage);
  }, [query]);
  // }, [debouncedFetchHits, page, query, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getAll({ text: query, page: newPage, limit: rowsPerPage });
  };

  const handleChangeRowsPerPage = (event) => {
    const newPage = 0;
    const newLimit = parseInt(event.target.value);
    setRowsPerPage(newLimit);
    setPage(newPage);
    getAll({ text: query, page: newPage, limit: newLimit });
  };

  const hideDialog = () => setDialogOpen(false);

  const showDialog = () => setDialogOpen(true);

  const saveClient = (client) => create(client);

  useEffect(() => {
    if (error) {
      showNotify('error', error);
    }
  }, [error, showNotify]);

  useEffect(() => {
    if (created) {
      getAll({ page, limit: rowsPerPage });
    }
  }, [created, getAll, page, rowsPerPage]);

  const onItemSelected = (client) => selectProfile(client.id);

  return (
    <div>
      {/* <SearchBox showAddButton /> */}

      <div style={{ marginTop: 25 }} />

      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar variant="dense">
          <Typography variant="h6" color="inherit" className={classes.title}>
            Pacientes
          </Typography>

          <Input
            id="search-products"
            value={query}
            onChange={(ev) => setQuery(ev.target.value)}
            onFocus={(ev) => ev.target.select()}
            autoFocus
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
            aria-describedby="search"
            inputProps={{
              'aria-label': 'search',
            }}
          />

          <Button
            variant="contained"
            color="secondary"
            disableElevation
            startIcon={<Add />}
            className={classes.addClientButton}
            onClick={showDialog}
          >
            Nuevo Paciente
          </Button>

          <div className={classes.wrapper}>
            <IconButton
              aria-label="export"
              color="inherit"
              onClick={exportToExcel}
            >
              <GetAppOutlined />
            </IconButton>
            {exportLoading && (
              <CircularProgress size={42} className={classes.fabProgress} />
            )}
          </div>

          {/* <IconButton aria-label="export" color="inherit" onClick={exportToExcel}>
						<GetAppOutlined />
					</IconButton> */}
        </Toolbar>
      </AppBar>

      <Grid
        container
        direction="column"
        className={classes.mainContainer}
        spacing={2}
      >
        {!loading &&
          clients.data.map((item) => (
            <ItemList
              key={item.id}
              client={item}
              onClick={() => onItemSelected(item)}
            />
          ))}

        {loading && (
          <Grid item style={{ display: 'flex', minHeight: 200 }}>
            <CircularProgress color="secondary" />
          </Grid>
        )}

        <Grid item xs={12}>
          {clients.count > 0 && (
            <TablePagination
              component="div"
              count={clients.count}
              rowsPerPageOptions={[5, 10, 20, 50]}
              labelRowsPerPage="Registros por página"
              labelDisplayedRows={({ from, to, count, page }) =>
                `${from}-${to} de ${count}`
              }
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Grid>
      </Grid>

      <ClientDialog visible={dialogOpen} hide={hideDialog} save={saveClient} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  clients: state.client.data,
  loading: state.client.loading,
  exportLoading: state.client.exportLoading,
  error: state.client.error,
  created: state.client.created,
});

const mapDispatchToProps = (dispatch) => ({
  getAll: (params) => dispatch(actions.getAll(params)),
  create: (client) => dispatch(actions.create(client)),
  showNotify: (type, message) => dispatch(notifyActions.show(type, message)),
  selectProfile: (id) => dispatch(actions.selectProfileById(id)),
  exportToExcel: () => dispatch(actions.exportToExcel()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
