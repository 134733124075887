import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  event: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 2,
    padding: "1px 4px",
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
      // color: '#444',
    },
  },
  eventIcon: {
    fontSize: 16,
    color: "white",
  },
  eventType: {
    width: 10,
    height: 10,
    borderRadius: "50%",
    display: "inline-block",
    marginRight: 10,
  },
  doneIcon: {
    fontSize: 12,
    marginRight: 4,
  },
}));