import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
    mainContainer: {
        marginTop: 15,
    },
    container: {
    },
    cardClient: {
    },
    title: {
        flexGrow: 1,
    },
    textDescription: {
        color: "gray"
    },
    textName: {
        fontWeight: 800,
        fontSize: theme.spacing(2.5)
    },
    avatar: {
        height: theme.spacing(8),
        width: theme.spacing(8)
    },
    textValue: {
        fontWeight: 450
    },
    buttonSection: {
        marginTop: 30
    },
    addClientButton: {
        marginLeft: 20,
        borderRadius: 8
    },

    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    fabProgress: {
        color: theme.palette.secondary.main,
        position: 'absolute',
        top: 2,
        left: 3,
        zIndex: 1,
    },
}));