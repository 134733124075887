import React, { useState } from "react";
import {
  Dialog,
  makeStyles,
  DialogActions,
  DialogContent,
  TextField,
  Button,
  DialogTitle,
  Grid,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	container: {
		padding: 20,
		height: "auto",
	},
	textField: {
		width: "100%",
	},
	textFieldNotes: {
		width: "100%",
		height: 80,
	},
}));

function DialogChangePassword({ visible, onHide, onSave }) {
	const classes = useStyles();
	const [password, setPassword] = useState("");
	const [confirmedPassword, setConfirmedPassword] = useState("");

	const done = () => {
        const passwordValidation = validatePassword();
		if (passwordValidation.isValid) {
            onSave(confirmedPassword);
            handleCLose();
        }
        else{
            alert(passwordValidation.error);
        }
		
    };
    const handleCLose =() =>{
        setPassword("");
        setConfirmedPassword("");
        onHide();
    }
	function validatePassword() {
		if ((password.length > 3) & (confirmedPassword.length > 3)) {
			if (password === confirmedPassword) {
				return { isValid: true, error: "" };
			} else {
				return {
					isValid: false,
					error: "Las contraseñas no coinciden",
				};
			}
		} else {
			return {
				isValid: false,
				error: "La contraseña debe tener más de 3 caracteres",
			};
		}
	}
	return (
		<Dialog
			className={classes.container}
			open={visible}
			onClose={handleCLose}
			maxWidth="sm"
			fullWidth
		>
			<DialogTitle>Cambiar contraseña</DialogTitle>
			<DialogContent>
				<Grid container direction="column" spacing={2}>
					<Grid item>
						<TextField
                            type="password"
							value={password}
							fullWidth
							id="passwordText"
							label="Nueva contraseña"
							variant="outlined"
							onChange={(ev) => setPassword(ev.target.value)}
						/>
					</Grid>
					<Grid item>
						<TextField
                            type="password"
							value={confirmedPassword}
							fullWidth
							id="confirmedPasswordText"
							label="Confirmar contraseña"
							variant="outlined"
							onChange={(ev) =>
								setConfirmedPassword(ev.target.value)
							}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions style={{ justifyContent: "space-between" }}>
				
					<Button onClick={onHide} color="primary" disableElevation>
						Cancelar
					</Button>
                    
					<Button
						variant="contained"
						color="secondary"
						disableElevation
						onClick={done}
					>
						Guardar
					</Button>
				
			</DialogActions>
		</Dialog>
	);
}

export default DialogChangePassword;
