import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    card: {
        boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.05)',
        borderRadius: 12,
        // marginBottom: 30,
        background: "#fff",
        // minHeight: 150,
        padding: '10px 15px',
        '&:hover': {
            cursor: 'pointer',
        },
    }
}));

function Card({ borderRadius, minHeight, marginBottom, children, ...rest }){
    const classes = useStyles();
    return (
        <div className={classes.card} style={{ borderRadius, minHeight, marginBottom }} {...rest}>
            {children}
        </div>
    )
}

export default Card;