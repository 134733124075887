import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';
import Card from "../../../../components/Card";
import { CardHeader, CardContent } from '@material-ui/core';

const AppointmentsChart = ({
  appointments
}) => {

  const [series, setSeries] = useState([
    {
      name: 'Citas agendadas',
      data: []
    }
  ]);

  useEffect(() => {
    const data = appointments.data.map(item => [
      moment(item.date).toDate(),
      item.count
    ]);

    setSeries([
      {
        name: 'Citas agendadas',
        data
      }
    ]);

  }, [appointments]);

  const defaults = {
    options: {
      chart: {
        type: "line",
        height: 180,
        // foreColor: "#666",
        stacked: true,
        dropShadow: {
          enabled: true,
          enabledSeries: [0],
          top: -2,
          left: 2,
          blur: 5,
          opacity: 0.06
        },
        toolbar: {
          tools: {
            download: false,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true
          }
        },
      },
      colors: ['#FF5F5F', '#23262d'],
      stroke: {
        // curve: ['smooth', 'straight'],
        curve: 'straight',
        width: 4,
        colors: ['#FF5F5F']
      },
      fill: {
        type: 'gradient',
        gradient: {
          opacityFrom: .95,
          opacityTo: 0.65,
        }
      },
      dataLabels: {
        enabled: false
      },
      markers: {
        size: 5,
        fillOpacity: 0,
        strokeOpacity: 0,
        hover: {
          size: 10
        }
      },
      grid: {
        padding: {
          left: 15,
          right: 5
        }
      },
      xaxis: {
        type: "datetime",
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          formatter: function (value) {
            if (!value) return value;

            return moment(value).format("DD/MMM/YYYY");
          }
        }
      },
      yaxis: [
        {
          title: {
            text: 'Citas agendadas'
          },
          labels: {
            formatter: function (value) {
              if (!value) return value;

              return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
            }
          }
        }
      ],
      // grid: {
      //     padding: {
      //         left: 15,
      //         right: 15
      //     }
      // },
      tooltip: {
        x: {
          format: 'dd MMM yyyy'
        },
      },
      legend: {
        position: 'top',
        horizontalAlign: 'center'
      }
    }
  };

  return (
    <Card style={{ padding: 0, borderRadius: 3 }}>
      <CardHeader title='Citas agendadas' subheader='Por día' />
      <CardContent>
        <ReactApexChart
          options={defaults.options}
          series={series}
          type="area"
          width="100%"
          height="180"
        />
      </CardContent>
    </Card>
  );
}

export default AppointmentsChart;