import actions from "./actions";

const initialState = {
    user: {},
    loading: false,
}

const reducer = (state = initialState, action) => {
    const { payload, type } = action;
    switch (type) {

        case actions.LOGIN: {
            return {
                ...state,
                loading: true
            }
        }
        case actions.SET: {
            return {
                ...state,
                loading: false,
                [payload.type]: payload.data
            }
        }
        case actions.LOGOUT: {
            return initialState;
        }
        case actions.CHANGE_PASSWORD: {
            return initialState;
        }
        default:
            return state;
    }
}

export default reducer;