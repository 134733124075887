/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import Insights from './components/Insights';
import { useStyles } from './Dashboard-styles';
import DemographicChart from './components/DemographicChart';
import { useSelector, useDispatch } from 'react-redux';
import actions from '../../../redux/reports/actions';
import clientActions from '../../../redux/client/actions';
import FillChart from './components/FillChart';
import TopProductsChart from './components/TopProductsChart';
import AppointmentsChart from './components/AppointmentsChart';
import TopRecommendedChart from './components/TopRecommendedChart';
import TopClientsChart from './components/TopClientsChart';
import { PeopleTwoTone } from '@material-ui/icons';
import BirthdayListClients from './components/BirthdayListClients';
import ClientsWithOutstandingBalance from './components/ClientsWithOutstandingBalance';

const Dashboard = () => {
  const classes = useStyles();

  const demographic = useSelector((state) => state.report.demographic);
  const appointments = useSelector((state) => state.report.appointments);
  const fill = useSelector((state) => state.report.fill);
  const comparative = useSelector((state) => state.report.comparative);
  // const sale = useSelector((state) => state.report.sale);
  // const saleByMethods = useSelector((state) => state.report.saleByMethods);
  const topProducts = useSelector((state) => state.report.topProducts);
  const topRecommended = useSelector((state) => state.report.topRecommended);
  const topClients = useSelector((state) => state.report.topClients);
  const clientsWithOutstandingBalance = useSelector(
    (state) => state.report.clientsWithOutstandingBalance
  );
  const { birthdayList, loading: loadingBirthdayList } = useSelector(
    (state) => state.client
  );

  const dispatch = useDispatch();
  const getReport = () => dispatch(actions.getReport());
  const getBirthdayList = () => dispatch(clientActions.getBirthdayList());

  useEffect(() => {
    getReport();
    getBirthdayList();
  }, []);

  const selectProfile = (id) => dispatch(clientActions.selectProfileById(id));

  return (
    <div>
      <Grid
        container
        justifyContent="space-between"
        style={{ marginBottom: 20 }}
      >
        <Insights
          title="Pacientes"
          total={fill.data.total}
          percentage={comparative.value}
          icon={<PeopleTwoTone />}
        />
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <BirthdayListClients
            list={birthdayList}
            loading={loadingBirthdayList}
            onSelectItem={selectProfile}
          />
        </Grid>
        <Grid item xs={6}>
          <ClientsWithOutstandingBalance
            data={clientsWithOutstandingBalance.data}
            loading={clientsWithOutstandingBalance.loading}
            onSelectItem={selectProfile}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AppointmentsChart {...{ appointments }} />
        </Grid>
      </Grid>

      <div className={classes.space} />

      <Grid container spacing={2} justifyContent="space-between">
        <Grid item xs={6}>
          <DemographicChart {...{ demographic }} />
          <div className={classes.spacelg} />
          <TopClientsChart value={topClients} />
        </Grid>
        <Grid container item xs={6} spacing={2}>
          <FillChart {...{ fill }} />
          <TopProductsChart value={topProducts} />
          <TopRecommendedChart value={topRecommended} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
