import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import {
  ArrowBackTwoTone,
  ArrowForwardTwoTone,
  TodayTwoTone,
  Done,
} from "@material-ui/icons";
import moment from "moment";
import "../../../assets/calendar/styles.css";
import { useStyles } from "./Calendar.styles";
import EventItem from "./components/EventItem";
import { Card, CardContent, LinearProgress } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../redux/appointments/actions";
import catalogActions from "../../../redux/catalogs/actions";
// import Year from "./components/Year";
import CustomToolbar from "./components/CustomToolbar";
require("moment/locale/es.js");

const localizer = momentLocalizer(moment);
localizer.formats.yearHeaderFormat = "YYYY";

const Calendar = () => {
  const classes = useStyles();
  const { clientId } = useParams();
  const [date, setDate] = useState(new Date());
  const [eventWidth, setEventWidth] = useState("100%");
  const [resources, setResources] = useState([]);
  const [view, setView] = useState("month");

  const appointments = useSelector((state) => state.appointments);
  const rooms = useSelector((state) => state.catalogs.rooms);

  const dispatch = useDispatch();

  const getAppointments = (type, date) => dispatch(actions.getAll(type, date));
  const getRooms = () => dispatch(catalogActions.getRooms());
  const showDialog = (open, event, isNew, clientId) =>
    dispatch(actions.showDialog(open, event, isNew, clientId));

  function handleEventSelect(event) {
    showDialog(true, event, false, clientId ? clientId : null);
  }

  function handleSelect(event) {
    let weekday = moment(event.start).weekday();
    if (weekday === 6) return; // Domingos no hay citas

    showDialog(true, event, true, clientId ? clientId : null);
  }
  const targetRef = useRef();
  useEffect(() => {
    getAppointments(view, new Date());
    getRooms();
  }, []);

  useEffect(() => {
    setResources(
      rooms.map((item) => {
        return {
          id: item.id,
          title: item.name,
        };
      })
    );
  }, [rooms]);

  useLayoutEffect(() => {
    if (targetRef.current) {
      const { offsetWidth: width } = targetRef.current;
      setEventWidth(width / 7 - 12);
    }
  }, []);

  return (
    <React.Fragment>
      {appointments.loading && <LinearProgress color="secondary" />}
      <Card className={classes.root} variant="outlined">
        <CardContent>
          <BigCalendar
            date={date}
            onNavigate={(day) => {
              getAppointments(view, day);
              setDate(day);
            }}
            onView={(v) => {
              setView(v);
              getAppointments(v, date);
            }}
            popup
            length={5}
            localizer={localizer}
            defaultDate={new Date()}
            defaultView={view}
            culture="es"
            events={appointments.data}
            style={{ height: "80vh" }}
            selectable
            step={15}
            // timeslots={1}
            min={new Date(0, 0, 0, 8, 0, 0)}
            max={new Date(0, 0, 0, 20, 0, 0)}
            onSelectEvent={handleEventSelect}
            onSelectSlot={handleSelect}
            resources={resources}
            elementProps={{
              ref: targetRef,
            }}
            views={{
              day: true,
              // week: true,
              month: true,
              agenda: true,
              // year: Year,
            }}
            // messages={{ year: 'Year' }}
            components={{
              event: (data) => (
                <EventItem event={data.event} classes={classes} />
              ),
              month: {
                event: (data) => (
                  <EventItem
                    width={eventWidth}
                    event={data.event}
                    classes={classes}
                  />
                ),
              },
              agenda: {
                event: (event) => (
                  <div>
                    {event.event.confirmed && (
                      <Done className={classes.doneIcon} />
                    )}
                    <span
                      className={classes.eventType}
                      style={{ backgroundColor: event.event.color }}
                    />
                    <span>{event.title}</span>
                  </div>
                ),
                time: (data) => <span>{data.label}</span>,
                // date: (data)
              },
              week: {
                event: (data) => <p>{data.title}</p>,
              },
              toolbar: CustomToolbar,
            }}
            messages={{
              allDay: "Todo el día",
              previous: <ArrowBackTwoTone fontSize="small" />,
              next: <ArrowForwardTwoTone fontSize="small" />,
              today: <TodayTwoTone fontSize="small" />,
              day: <span>Día</span>,
              week: <span>Semana</span>,
              month: <span>Mes</span>,
              agenda: <span>Agenda</span>,
            }}
          />
        </CardContent>
        {/* <Button onClick={()=>{setDate(new Date())}}>Change Date</Button> */}
      </Card>
    </React.Fragment>
  );
};

export default Calendar;
