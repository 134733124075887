import actions from './actions';

const initialState = {
  data: {
    data: [],
    count: 0,
  },
  loading: false,
  error: null,
  created: null,
  updated: null,
  removed: null,
  options: [],
  selected: null,
  isProfileSelected: false,
  optionsLoading: false,
  dialog: {
    data: {},
    open: false,
  },
  observations: [],
  appointments: [],
  balance: 0,
  exportLoading: false,
  notesLoading: false,
  notes: '',
  birthdayList: [],
};

const reducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case actions.GET_ALL: {
      return {
        ...state,
        loading: true,
        error: null,
        created: false,
        data: {
          data: [],
          count: 0,
        },
      };
    }
    case actions.GET_BY_ID: {
      return state;
    }
    case actions.CREATE: {
      return {
        ...state,
        loading: true,
        error: null,
        created: false,
      };
    }
    case actions.UPDATE: {
      return {
        ...state,
        loading: true,
        error: null,
        updated: false,
      };
    }
    case actions.REMOVE: {
      return {
        ...state,
        loading: true,
        error: null,
        removed: false,
      };
    }
    case actions.SELECT_PROFILE: {
      return {
        ...state,
        observations: [],
        appointments: [],
        selected: null,
        isProfileSelected: payload.id !== null && payload.id !== undefined,
      };
    }
    case actions.SEARCH: {
      return {
        ...state,
        optionsLoading: true,
      };
    }
    case actions.SET: {
      return {
        ...state,
        loading: false,
        optionsLoading: false,
        exportLoading: false,
        [payload.type]: payload.data,
      };
    }
    case actions.SHOW_DIALOG: {
      return {
        ...state,
        dialog: {
          open: payload.open,
          data: payload.data || {},
        },
      };
    }
    case actions.GET_OBSERVATIONS: {
      return {
        ...state,
        observations: [],
      };
    }
    case actions.GET_APPOINTMENTS: {
      return {
        ...state,
        appointments: [],
      };
    }
    case actions.GET_BALANCE: {
      return {
        ...state,
        balance: 0,
      };
    }
    case actions.EXPORT_EXCEL: {
      return {
        ...state,
        exportLoading: true,
      };
    }
    case actions.GET_NOTES: {
      return {
        ...state,
        notesLoading: true,
        notes: '',
      };
    }
    case actions.SAVE_NOTES: {
      return {
        ...state,
        notesLoading: true,
      };
    }
    case actions.GET_BIRTHDAY_LIST: {
      return {
        ...state,
        loading: true,
        error: null,
        created: false,
        birthdayList: [],
      };
    }

    default:
      return state;
  }
};

export default reducer;
