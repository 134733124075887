import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 30,
  },
  title: {
    width: "100%",
    fontSize: 16,
    fontWeight: 600,
  },
  sectionTitle: {
    fontSize: 34,
    fontWeight: 800,
  },
  titleGray: {
    fontSize: 16,
    fontWeight: 400,
    color: "#555",
    letterSpacing: 1,
  },
  subtitleGray: {
    fontSize: 14,
    fontWeight: 400,
    // color: "#BDBDBD",
    letterSpacing: 1,
  },
  description: {
    width: "100%",
    fontSize: 12,
    color: "gray",
  },
  tag: {
    margin: 5,
  },
  tagName: {
    width: "100%",
    fontSize: 12,
  },
  time: {
    width: "100%",
    fontSize: 11,
    fontWeight: 600,
    textAlign: "right",
  },
  buttonGoToAppointment: {
    color: "white",
    height: "90%",
  },
  appointmentTime: {
    marginRight: 3,
    backgroundColor: theme.palette.secondary.main,
    color: "white",
    padding: "1px 5px",
    borderRadius: 4,
    display: "inline-block",
    verticalAlign: "middle",
  },
  buttonNewRegistry: {
    height: 47,
    width: 250,
    borderRadius: 12,
    fontSize: 20,
  },
  activityHeaderSection: {
    marginBottom: 40,
    height: 60,
  },
  appointmentHeaderSection: {
    marginBottom: 40,
    height: 60,
  },
  headerIcon: {
    height: 30,
    width: 30,
    marginRight: 15,
  },
  appointmentsContainer: {
    padding: 15,
  },
  appbar: {
    padding: 0,
    marginBottom: 15,
  },
  appbartitle: {
    flexGrow: 1,
    fontWeight: 600,
    fontSize: 22,
  },
  spaceBottom: {
    marginBottom: 25,
  },
  addNewBtn: {
    borderRadius: 8,
  },
  borderLeft: {
    borderLeftWidth: 4,
    borderLeftStyle: "solid",
    paddingLeft: 4,
  },
  canceled: {
    fontSize: 10,
    textTransform: "uppercase",
    backgroundColor: theme.palette.secondary.dark,
    color: "white",
    padding: "0px 2px",
  },
  confirmed: {
    fontSize: 10,
    textTransform: "uppercase",
    backgroundColor: "#2fa915",
    color: "white",
    padding: "0px 2px",
  },
  green: {
    color: "#2fa915",
  },
  balanceInput: {
    fontSize: "3em",
    "& .MuiInputAdornment-root .MuiTypography-root": {
        fontSize: "1em",
        color: "inherit"
    },
    color: "inherit"
  },
  borderless: {
    border: "none",
  },
}));
