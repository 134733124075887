const actions = {
    GET_ALL_ACTIVES: "PRODUCTS/GET_ALL_ACTIVES",
    GET_ALL: "PRODUCTS/GET_ALL",
    SET: "PRODUCTS/SET",
    CREATE: "PRODUCTS/CREATE",
    UPDATE: "PRODUCTS/UPDATE",
    REMOVE: "PRODUCTS/REMOVE",

    getAllActives: () => ({
        type: actions.GET_ALL_ACTIVES,
    }),
    getAll: () => ({
        type: actions.GET_ALL,
    }),
    set: (type, data) => ({
        type: actions.SET,
        payload: { type, data }
    }),
    create: (data) => ({
        type: actions.CREATE,
        payload: { data }
    }),
    update: (id, data) => ({
        type: actions.UPDATE,
        payload: { id, data }
    }),
    remove: (id) => ({
        type: actions.REMOVE,
        payload: { id }
    }),
};

export default actions;