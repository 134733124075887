import { put, takeEvery, takeLatest, call, all } from 'redux-saga/effects';
import actions from './actions';
import * as notifications from '../notifications';
import api from '../api';

const API = new api();
API.createEntity('products');

function* getAllActives() {
  try {
    const res = yield call(API.endpoints.products.getAll);
    yield put(actions.set('data', res.data));
  } catch (error) {
    let message = error.message;
    if (error.response && error.response.data)
      message = error.response.data.message;
    yield put(actions.set('error', message));
  }
}

function* getAll() {
  try {
    const res = yield call(API.endpoints.products.getAllPath, '?all=true');
    yield put(actions.set('list', res.data));
  } catch (error) {
    let message = error.message;
    if (error.response && error.response.data)
      message = error.response.data.message;
    yield put(actions.set('error', message));
  }
}

function* create({ payload }) {
  try {
    const res = yield call(API.endpoints.products.post, payload.data);
    if (res.data) {
      all([
        yield put(actions.set('creating', false)),
        yield put(notifications.show('success', 'Nuevo producto creado')),
        yield call(getAll),
      ]);
    } else {
      yield put(notifications.show('error', 'Ha ocurrido un error inesperado'));
    }
  } catch (error) {
    let message = error.message;
    if (error.response && error.response.data)
      message = error.response.data.message;
    yield put(notifications.show('error', message));
  }
}

function* update({ payload }) {
  try {
    const res = yield call(
      API.endpoints.products.update,
      payload.id,
      payload.data
    );
    if (res.data) {
      all([
        yield put(actions.set('creating', false)),
        yield put(notifications.show('success', 'Producto actualizado')),
        yield call(getAll),
      ]);
    } else {
      yield put(notifications.show('error', 'Ha ocurrido un error inesperado'));
    }
  } catch (error) {
    let message = error.message;
    if (error.response && error.response.data)
      message = error.response.data.message;
    yield put(notifications.show('error', message));
  }
}

function* remove({ payload }) {
  try {
    const res = yield call(API.endpoints.products.delete, payload.id);
    if (res.data) {
      all([
        yield put(notifications.show('success', 'Se ha eliminado el producto')),
        yield call(getAll),
      ]);
    } else {
      yield put(
        notifications.show('error', 'Error al intentar eliminar un producto')
      );
    }
  } catch (error) {
    let message = error.message;
    if (error.response && error.response.data)
      message = error.response.data.message;
    yield put(notifications.show('error', message));
  }
}

export default function* index() {
  yield takeLatest(actions.GET_ALL_ACTIVES, getAllActives);
  yield takeLatest(actions.GET_ALL, getAll);
  yield takeEvery(actions.CREATE, create);
  yield takeEvery(actions.UPDATE, update);
  yield takeEvery(actions.REMOVE, remove);
}
