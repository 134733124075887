/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  makeStyles,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import SearchBox from '../../../components/SearchBox';
import {
  DatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from '@material-ui/pickers';
import EventIcon from '@material-ui/icons/Event';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import catalogActions from '../../../redux/catalogs/actions';
import clientActions from '../../../redux/client/actions';
import { getClientById } from '../../../redux/client/saga';

import appointmentActions from '../../../redux/appointments/actions';
import * as notifyActions from '../../../redux/notifications';
require('moment/locale/es.js');

function DialogAppointment({ handleOpen }) {
  const [appointment, setAppointment] = useState({
    id: '',
    title: '',
    start: new Date(),
    time: '10:00',
    end: new Date(),
    clientId: '',
    client: null,
    typeId: '',
    resourceId: '',
    confirmed: false,
  });
  const [contact, setContact] = useState(null);

  const selected = useSelector((state) => state.client.selected);
  const clientCreated = useSelector((state) => state.client.created);
  const types = useSelector((state) => state.catalogs.types);
  const rooms = useSelector((state) => state.catalogs.rooms);
  const error = useSelector((state) => state.appointments.error);
  const canceling = useSelector((state) => state.appointments.canceling);
  const created = useSelector((state) => state.appointments.created);
  const loading = useSelector((state) => state.appointments.loading);
  const dialog = useSelector((state) => state.appointments.dialog);

  useEffect(() => {
    if (dialog.event) {
      const { event } = dialog;

      console.log('event', event);
      appointmentActions.initDialog();

      const time = moment(event.start).format('HH:mm');

      async function fetchClient() {
        const fetchedClient = await getClientById(dialog.selectedClientId);

        if (fetchedClient) {
          setClientSelected(fetchedClient);
          setAppointment({
            ...appointment,
            id: event.id,
            title: fetchedClient.name,
            time,
            start: event.start,
            end: event.start,
            clientId: fetchedClient.id,
            client: fetchedClient,
            typeId: event.typeId,
            resourceId: event.resourceId,
            confirmed: event.confirmed,
          });
          setContact({
            id: fetchedClient.id,
            phone: fetchedClient.phone,
            email: fetchedClient.email,
          });
        }
      }

      if (dialog.selectedClientId && dialog.isNew) {
        fetchClient();
      } else {
        setAppointment({
          ...appointment,
          id: event.id,
          title: event.title,
          time,
          start: event.start,
          end: event.start,
          typeId: event.typeId,
          resourceId: event.resourceId,
          clientId: event.clientId,
          client: event.client,
          confirmed: event.confirmed,
        });
        setContact(event.client);
      }

      getAppointmentTypes();
      getRooms();
    }
  }, [dialog.event]);

  const [clientSelected, setClientSelected] = useState(null);

  useEffect(() => {
    if (selected && selected.id) {
      setClientSelected(selected);
      setAppointment({
        ...appointment,
        clientId: selected.id,
        title: selected.name,
      });
      setContact({
        id: selected.id,
        phone: selected.phone,
        email: selected.email,
      });
    }
  }, [selected]);

  useEffect(() => {
    if (clientCreated && clientCreated.id) {
      setClientSelected(clientCreated);
      setAppointment({
        ...appointment,
        clientId: selected.id,
        title: selected.name,
      });
      setContact({
        id: selected.id,
        phone: selected.phone,
        email: selected.email,
      });
    }
  }, [clientCreated]);

  useEffect(() => {
    if (created) {
      handleOpen(false);
    } else if (!canceling) {
      handleOpen(false);
    }
  }, [error, created, canceling]);

  const onClientSelected = (client) => {
    let clientId = '',
      title = '';
    if (client && client.id) {
      clientId = client.id;
      title = client.name;
    }
    setAppointment({
      ...appointment,
      clientId,
      title,
    });
    setContact({
      id: client.id,
      phone: client.phone,
      email: client.email,
    });
  };

  const dispatch = useDispatch();

  const getAppointmentTypes = () =>
    dispatch(catalogActions.getAppointmentTypes());
  const getRooms = () => dispatch(catalogActions.getRooms());
  const showClientDialog = (open, client) =>
    dispatch(clientActions.showDialog(open, client));
  const showNotify = (type, message) =>
    dispatch(notifyActions.show(type, message));
  const create = (appointment) =>
    dispatch(appointmentActions.create(appointment));
  const update = (id, appointment) =>
    dispatch(appointmentActions.update(id, appointment));
  const cancel = (id, clientId) =>
    dispatch(appointmentActions.cancel(id, clientId));
  const selectProfile = (id) => dispatch(clientActions.selectProfileById(id));
  // const getClient = (id) => dispatch(clientActions.getById(id));

  const handleDateChange = (date) => {
    let weekday = moment(date).weekday();
    if (weekday === 6) {
      // domingos no hay citas
      showNotify('warning', 'No hay citas en domingo');
    } else {
      setAppointment({ ...appointment, start: date, end: date });
    }
  };

  const handleTypeChange = (ev) => {
    const { value } = ev.target;
    setAppointment({
      ...appointment,
      typeId: value,
    });
  };

  const handleResourceChange = (ev) => {
    const { value } = ev.target;
    setAppointment({
      ...appointment,
      resourceId: value,
    });
  };

  const handleConfirmedChange = (ev) => {
    const { checked } = ev.target;
    console.log('checked', checked);
    setAppointment({
      ...appointment,
      confirmed: checked,
    });
  };

  const onClosing = () => {
    setClientSelected(null);
    setAppointment({
      id: '',
      title: '',
      start: new Date(),
      // hour: 10,
      time: '10:00',
      end: new Date(),
      clientId: '',
      typeId: '',
      resourceId: '',
    });
    handleOpen(false);
  };
  const done = () => {
    if (validForm()) {
      if (appointment.id) update(appointment.id, appointment);
      else create(appointment);
    } else
      showNotify(
        'warning',
        'Revisa los campos requeridos: Paciente, Tipo de Consulta y Espacio'
      );
  };

  const validForm = () =>
    appointment.clientId && appointment.typeId && appointment.resourceId;

  const classes = useStyles();
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Dialog
        open={dialog.open}
        onClose={() => onClosing()}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>
          {dialog.isNew ? (
            <div>
              <span>Nueva cita</span>
              {appointment.title && (
                <span className={classes.badge}>{appointment.title}</span>
              )}
            </div>
          ) : (
            <Button
              color="primary"
              variant="text"
              onClick={() => {
                console.log('appointment', appointment);
                selectProfile(appointment.clientId);
                handleOpen(false);
              }}
            >
              {appointment.title}
            </Button>
          )}
        </DialogTitle>
        <DialogContent>
          {dialog.isNew && (
            <>
              <SearchBox
                {...{
                  showClientDialog,
                  onClientSelected,
                  clientSelected,
                  autoClear: false,
                }}
              />
              <div style={{ marginTop: 20 }} />
            </>
          )}

          {contact && (
            <div className={classes.box}>
              {contact.phone && (
                <div>
                  <span>Teléfono:</span>
                  <span className={classes.valueText}>
                    {' ' + contact.phone}
                  </span>
                </div>
              )}
              {contact.email && (
                <div>
                  <span>Email:</span>
                  <span className={classes.valueText}>
                    {' ' + contact.email}
                  </span>
                </div>
              )}
            </div>
          )}

          <DatePicker
            margin="normal"
            id="start-date"
            label="Fecha de cita"
            format="LL"
            autoOk
            fullWidth
            value={appointment.start}
            inputVariant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EventIcon />
                </InputAdornment>
              ),
            }}
            onChange={handleDateChange}
          />

          <TimePicker
            autoOk
            fullWidth
            inputVariant="outlined"
            margin="normal"
            label="Hora de cita"
            clearable
            minutesStep={5}
            value={appointment.start}
            onChange={handleDateChange}
          />

          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel id="type-label">Tipo de consulta</InputLabel>
            <Select
              labelId="type-label"
              value={appointment.typeId}
              onChange={handleTypeChange}
              name="typeId"
              inputProps={{
                id: 'typeId',
              }}
              label="Tipo de consulta"
            >
              <MenuItem value="">
                <Typography
                  variant="inherit"
                  className={classes.option}
                ></Typography>
              </MenuItem>
              {types.map((value) => (
                <MenuItem key={value.id} value={value.id}>
                  <div
                    className={classes.dot}
                    style={{ backgroundColor: value.color }}
                  ></div>
                  <Typography variant="inherit" className={classes.option}>
                    {value.name}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel id="room-label">Espacio</InputLabel>
            <Select
              labelId="room-label"
              value={appointment.resourceId}
              onChange={handleResourceChange}
              name="resourceId"
              inputProps={{
                id: 'resourceId',
              }}
              required={true}
              label="Espacio"
            >
              <MenuItem value="">
                <Typography
                  variant="inherit"
                  className={classes.option}
                ></Typography>
              </MenuItem>
              {rooms.map((value) => (
                <MenuItem key={value.id} value={value.id}>
                  <Typography variant="inherit" className={classes.option}>
                    {value.name}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {appointment.id && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={appointment.confirmed}
                  onChange={handleConfirmedChange}
                  name="confirmed"
                  color="secondary"
                />
              }
              label="Cita confirmada"
            />
          )}
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-between' }}>
          {appointment.id ? (
            <Button
              color="secondary"
              variant="outlined"
              disabled={canceling}
              onClick={() => cancel(appointment.id, appointment.clientId)}
            >
              Cancelar cita
            </Button>
          ) : (
            <span></span>
          )}
          <div style={{ display: 'flex' }}>
            <Button variant="text" onClick={handleOpen}>
              Cerrar
            </Button>
            <div className={classes.wrapper}>
              <Button
                variant="contained"
                color="secondary"
                disableElevation
                disabled={loading}
                onClick={done}
              >
                Guardar
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </MuiPickersUtilsProvider>
  );
}

export default DialogAppointment;

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 20,
  },
  dot: {
    display: 'inline-block',
    verticalAlign: 'middle',
    width: 15,
    height: 15,
    borderRadius: '50%',
    marginRight: 10,
  },
  option: {
    verticalAlign: 'middle',
  },
  wrapper: {
    // margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  badge: {
    fontSize: '12px',
    verticalAlign: 'middle',
    marginLeft: '10px',
    backgroundColor: theme.palette.primary.light,
    color: 'white',
    padding: '2px 6px',
    borderRadius: '2px',
    fontWeight: '600',
  },
  box: {
    border: '1px solid',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
    marginBottom: '10px',
    padding: '10px 16px',
  },
  valueText: {
    fontWeight: 100,
    fontSize: 16,
  },
}));
