const actions = {
    GET: "CONFIGURATION/GET",
    SET_PROFILE_ACTIVE: "CONFIGURATION/SET_PROFILE_ACTIVE",
    SELECT_PROFILE: "CONFIGURATION/SELECT_PROFILE",

    setProfileActive: (data) => ({
        type: actions.SET_PROFILE_ACTIVE,
        payload: { data }
    }),
    selectProfile: (data) => ({
        type: actions.SELECT_PROFILE,
        payload: { data }
    }),
    get: () => ({
        type: actions.GET,
    }),
};

export default actions;