import React, { useState, useEffect } from "react";
import {
  Dialog,
  makeStyles,
  DialogActions,
  DialogContent,
  Button,
  DialogTitle,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 20,
    height: "auto",
  },
}));

function EditDateRegistryDialog({ visible, onHide, onSave, data }) {
  const classes = useStyles();
  const [newDate, setNewDate] = useState(new Date());

  useEffect(() => {
    if (data && data.createdAt) setNewDate(data.createdAt);
  }, [data]);

  const done = () => {
    onSave(data.id, newDate);
    onHide();
  };

  return (
    <Dialog
      className={classes.container}
      open={visible}
      onClose={onHide}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Cambiar fecha del registro</DialogTitle>
      <DialogContent>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            fullWidth
            margin="dense"
            autoOk
            disableFuture
            inputVariant="outlined"
            size="small"
            format="DD-MM-YYYY"
            id="birthday"
            invalidDateMessage="Formato de fecha inválido"
            label="Fecha nacimiento"
            value={newDate}
            onChange={(date, value) =>{
              setNewDate(moment.isMoment(date) ? date.set({hour: 7}).toDate() : value)
            }
            }
            KeyboardButtonProps={{
              "aria-label": "birthday",
            }}
          />
        </MuiPickersUtilsProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={onHide} color="primary" disableElevation>
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disableElevation
          onClick={done}
        >
          Actualizar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditDateRegistryDialog;
