import { all, takeEvery, call, put } from "redux-saga/effects";
import actions from "./actions";
import clientActions from "../client/actions";
import { history } from "../store";

function* setProfile({ payload }) {
  yield all([
    call(selectProfile),
    put(clientActions.getObservations(payload.data.id)),
    put(clientActions.getAppointments(payload.data.id)),
  ]);
}

function* selectProfile() {
  yield history.push("/main/profile");
}

export default function* index() {
  yield takeEvery(actions.SELECT_PROFILE, setProfile);
}