import actions from "./actions";

const initialState = {
    profileActive: false,
    profile: null,
};

const reducer = (state = initialState, action) => {
    const {payload,type} = action;
    switch(type){
        
        case actions.SET_PROFILE_ACTIVE: {
            return {
                ...state,
                profileActive: payload.data,
                profile: null,
            }
        }
        case actions.SELECT_PROFILE: {
            return {
                ...state,
                profile: payload.data,
                profileActive: true,
            }
        }
        case actions.GET: {
            return state;
        }

        default:
            return state;
    }
}

export default reducer;