/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/DeleteTwoTone';
import SearchIcon from '@material-ui/icons/Search';
import { connect } from 'react-redux';
import actions from '../../../redux/products/actions';
import {
  CircularProgress,
  Checkbox,
  Input,
  InputAdornment,
} from '@material-ui/core';
import { currency } from '../../../utils';
import DialogProduct from './components/DialogProduct';
import DialogRemoveConfirmation from './components/DialogRemoveConfirmation';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'name', align: 'left', label: 'Nombre' },
  { id: 'type', align: 'left', label: 'Tipo' },
  { id: 'price', align: 'right', label: 'Precio' },
  { id: 'priceStar', align: 'right', label: 'Precio especial' },
  { id: 'priceDistributor', align: 'right', label: 'Precio distribuidor' },
  { id: 'active', align: 'center', label: 'Activo' },
  { id: 'remove', align: 'center', label: '' },
];

function ProductsHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow style={{ cursor: 'pointer' }}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

ProductsHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  title: {
    flex: '1 1 100%',
  },
}));

const ProductsToolbar = ({ onNewClick, query, setQuery }) => {
  const classes = useToolbarStyles();

  return (
    <Toolbar className={clsx(classes.root)}>
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Productos
      </Typography>

      <Input
        id="search-products"
        value={query}
        onChange={(ev) => setQuery(ev.target.value)}
        onFocus={(ev) => ev.target.select()}
        autoFocus
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
        aria-describedby="search"
        inputProps={{
          'aria-label': 'search',
        }}
      />

      <Tooltip title="Crear nuevo">
        <IconButton aria-label="create" onClick={onNewClick}>
          <AddIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};

ProductsToolbar.propTypes = {
  onNewClick: PropTypes.func.isRequired,
};

const Products = ({
  loading,
  creating,
  list,
  getAll,
  create,
  update,
  remove,
}) => {
  const classes = useStyles();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('active');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [dialogVisible, setDialogVisible] = React.useState(false);
  const [confirmation, setConfirmation] = React.useState({
    visible: false,
    id: null,
  });
  const [itemSelected, setItemSelected] = React.useState(null);
  const [query, setQuery] = React.useState('');
  const [products, setProducts] = React.useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, products.length - page * rowsPerPage);

  useEffect(() => {
    getAll();
  }, []);

  useEffect(() => {
    setProducts(list);
  }, [list]);

  useEffect(() => {
    if (query && query.length > 2) {
      const filtered = list.filter((x) =>
        x.name.toLowerCase().includes(query.toLowerCase())
      );
      setProducts(filtered);
    } else {
      setProducts(list);
    }
  }, [query]);

  useEffect(() => {
    if (!creating) hideDialog();
  }, [creating]);

  const showDialog = () => setDialogVisible(true);
  const hideDialog = () => {
    setItemSelected(null);
    setDialogVisible(false);
  };

  const onItemClick = (item) => {
    setItemSelected(item);
    showDialog();
  };

  const onRemoveAgree = (id) => {
    remove(id);
    setConfirmation({ visible: false, id: null });
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <ProductsToolbar onNewClick={showDialog} {...{ query, setQuery }} />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="products table"
          >
            <ProductsHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(products, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `products-table-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => onItemClick(row)}
                      tabIndex={-1}
                      key={row.id}
                      style={{ cursor: 'pointer' }}
                    >
                      <TableCell component="th" id={labelId} scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="center">
                        {row.type === 'service' ? 'Tratamiento' : 'Producto'}
                      </TableCell>
                      <TableCell align="right">{currency(row.price)}</TableCell>
                      <TableCell align="right">
                        {currency(row.priceStar)}
                      </TableCell>
                      <TableCell align="right">
                        {currency(row.priceDistributor)}
                      </TableCell>
                      <TableCell align="center" padding="checkbox">
                        <Checkbox checked={row.active} />
                      </TableCell>
                      <TableCell align="center" padding="checkbox">
                        <Tooltip title="Eliminar producto">
                          <IconButton
                            aria-label="remove"
                            onClick={(ev) => {
                              ev.stopPropagation();
                              setConfirmation({ visible: true, id: row.id });
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {loading && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} align="center">
                    <CircularProgress color="secondary" size={26} />
                  </TableCell>
                </TableRow>
              )}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={products.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <DialogProduct
        visible={dialogVisible}
        onHide={hideDialog}
        loading={creating}
        data={itemSelected}
        onSave={create}
        onEdit={update}
      />

      <DialogRemoveConfirmation
        visible={confirmation.visible}
        onHide={() => setConfirmation({ visible: false, id: null })}
        onAgree={onRemoveAgree}
        value={confirmation.id}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  list: state.products.list,
  loading: state.products.loading,
  creating: state.products.creating,
});
const mapDispatchToProps = (dispatch) => ({
  getAll: () => dispatch(actions.getAll()),
  create: (data) => dispatch(actions.create(data)),
  update: (id, data) => dispatch(actions.update(id, data)),
  remove: (id) => dispatch(actions.remove(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Products);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));
