const actions = {
  GET_BY_CLIENT: "REGISTRY/GET_BY_CLIENT",
  CREATE: "REGISTRY/CREATE",
  UPDATE: "REGISTRY/UPDATE",
  PAY: "REGISTRY/PAY",
  CHANGE_DATE: "REGISTRY/CHANGE_DATE",
  SET: "REGISTRY/SET",
  DELETE: "REGISTRY/DELETE",

  set: (type, data) => ({
    type: actions.SET,
    payload: { type, data },
  }),
  create: (clientId, data) => ({
    type: actions.CREATE,
    payload: { clientId, data },
  }),
  update: (id, clientId, data) => ({
    type: actions.UPDATE,
    payload: { id, clientId, data },
  }),
  pay: (id, payment, clientId) => ({
    type: actions.PAY,
    payload: { id, payment, clientId },
  }),
  changeDate: (id, newDate, clientId) => ({
    type: actions.CHANGE_DATE,
    payload: { id, newDate, clientId},
  }),
  getRegistriesByClient: (id) => ({
    type: actions.GET_BY_CLIENT,
    payload: { id },
  }),
  deleteRegistry: (id) => ({
    type: actions.DELETE,
    payload: { id }
  }),
};

export default actions;
 