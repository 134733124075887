export const SHOW = "NOTIFICATIONS/SHOW";
export const CLOSE = "NOTIFICATION/CLOSE";

export const show = (type, message) => ({
    type: SHOW,
    payload: { type, message }
});

export const close = () => ({
    type: CLOSE
});

/*
TYPE
- success
- error
- warning
- info
*/

const initialState = {
    open: false,
    message: "",
    type: "info"
}

export const reducer = (state = initialState, action) => {
    const { payload, type } = action;
    switch (type) {
        case SHOW: {
            return {
                ...state,
                open: true,
                message: payload.message,
                type: payload.type
            }
        }

        case CLOSE: {
            return {
                ...state,
                open: false,
                message: "",
                type: ""
            }
        }

        default:
            return state;
    }
}