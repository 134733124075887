/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  TableRow,
  TableCell,
  DialogContent,
  TextField,
  InputAdornment,
  Table,
  TableBody,
  Dialog,
  IconButton,
  Slide,
  Card,
  CardContent,
  Tooltip,
  Select,
  MenuItem,
} from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Close';
import { useSelector } from 'react-redux';
import { currency, capitalize } from '../../../utils';
import RegistryService from '../profile/service/RegistryService';
import { getClientById } from '../../../redux/client/saga';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogRegistryV2({
  open,
  handleOpen,
  registry,
  isNew,
  index,
  products,
  employees,
  create,
  update,
  loading,
  balance,
  notify,
}) {
  const classes = useStyles();

  const [data, setData] = useState(RegistryService.getInitState());
  const [pendingValue, setPendingValue] = useState(null);
  const [payDialogVisible, setPayDialogVisible] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const hidePayDialog = () => setPayDialogVisible(false);
  const showPayDialog = () => setPayDialogVisible(true);
  const selectedClient = useSelector((state) => state.client.selected);
  const [isDistinguished, setIsDistinguished] = useState(false);

  const addPayment = (result) => {
    hidePayDialog();
    setData({
      ...data,
      isPaymentAdded: true,
      paymentAmount: result.amount,
      paymentMethod: result.method,
    });
  };

  useEffect(() => {
    if (!isNew && registry) {
      setData(registry);
      setSelectedEmployees(registry.attendedBy);
    } else {
      setData(RegistryService.getInitState());
      setSelectedEmployees([]);
    }
    async function fetchClient() {
      if (selectedClient) {
        const fetchedClient = await getClientById(selectedClient.id);
        setIsDistinguished(fetchedClient.distinguished);
      }
    }
    fetchClient();
  }, [isNew, registry]);

  useEffect(() => {
    setIsEditable(true);
    // setIsEditable(isNew || index === 0);
  }, [index]);

  const removeProduct = (id) =>
    setData({
      ...data,
      products: data.products.filter((x) => x.id !== id),
    });

  const addProduct = (newValue) => {
    RegistryService.addProduct(
      newValue,
      isDistinguished ? 'star' : 'normal',
      data.products,
      (list) => {
        setData({ ...data, products: list });
        setPendingValue(null);
      }
    );
  };
  const changePriceType = (id, priceType) => {
    RegistryService.changePriceType(id, priceType, data.products, (list) => {
      setData({ ...data, products: list });
    });
  };
  const changeQuantityToProduct = (id, quantity, priceType) => {
    RegistryService.changeQuantityToProduct(
      id,
      quantity,
      priceType,
      data.products,
      (list) => {
        setData({ ...data, products: list });
      }
    );
  };

  const changeCommentsToProduct = (id, comments) => {
    RegistryService.changeCommentsToProduct(
      id,
      comments,
      data.products,
      (list) => {
        setData({
          ...data,
          products: list,
        });
      }
    );
  };

  const calculateTotal = () =>
    RegistryService.calculateTotal(data, balance, (newData) =>
      setData(newData)
    );

  useEffect(() => {
    calculateTotal();
  }, [data.products, data.discount, data.paymentAmount]);

  const isValid = () => {
    const totalHasError = data.total < 0;
    const typeHasError = !data.type;

    if (typeHasError) notify('info', 'Selecciona un tipo de registro');
    else if (totalHasError)
      notify('info', 'El total no puede ser menor a cero');

    return !typeHasError && !totalHasError;
  };

  const clearData = () => {
    setData(RegistryService.getInitState());
  };

  const handleCancelEdit = () => {
    clearData();
    handleOpen();
  };

  const done = () => {
    if (isValid()) {
      const request = {
        ...data,
        attendedBy: selectedEmployees,
        discount: data.discount > 0 ? data.discount : 0,
        paymentAmount: data.paymentAmount > 0 ? data.paymentAmount : 0,
        products: data.products.map((item) => {
          return {
            id: item.id,
            quantity: item.quantity,
            comments: item.comments,
            priceType: item.priceType,
          };
        }),
      };

      if (data.id) update(data.id, request);
      else create(request);
    }
  };

  const handleCommentsChange = (e) => {
    const text = capitalize(e.target.value);
    setData({
      ...data,
      comments: text,
    });
  };
  const handleChangePriceType = (id, pValue) => {
    changePriceType(id, pValue);
  };

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={handleCancelEdit}
        TransitionComponent={Transition}
      >
        <RegistryService.Toolbar
          {...{ classes, loading, isNew, data, setData, isEditable }}
          onClose={handleCancelEdit}
          onSave={done}
        />

        <DialogContent className={classes.content}>
          <Card>
            <CardContent>
              {data.type === RegistryService.Type.CONSULTA && (
                <>
                  <TextField
                    multiline
                    fullWidth
                    minRows={3}
                    size="small"
                    value={data.comments}
                    onChange={handleCommentsChange}
                    margin="normal"
                    variant="outlined"
                    label="Consulta"
                  />

                  <Autocomplete
                    multiple
                    id="tags-standard"
                    options={employees}
                    value={selectedEmployees}
                    onChange={(event, newValue) => {
                      setSelectedEmployees(newValue);
                    }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Atendido por"
                        placeholder="Personal"
                        fullWidth
                        margin="normal"
                        size="small"
                      />
                    )}
                  />
                </>
              )}

              <Autocomplete
                key={pendingValue}
                id="product-search"
                options={products}
                getOptionLabel={(option) => option.name}
                margin="normal"
                style={{ width: '100%' }}
                value={pendingValue}
                openOnFocus
                clearOnBlur
                clearOnEscape
                onChange={(event, newValue) => {
                  addProduct(newValue);
                  setPendingValue({});
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Productos y tratamientos"
                    fullWidth
                    margin="normal"
                    size="small"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                  />
                )}
              />
            </CardContent>
          </Card>

          <RegistryService.EmptyMessage products={data.products} />

          {data.products && data.products.length > 0 && (
            <Card className={classes.table}>
              <CardContent>
                <Table size="small" aria-label="products table">
                  <RegistryService.HeaderProductsTable />

                  <TableBody>
                    {data.products.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row" width="5%">
                          <IconButton
                            aria-label="delete"
                            onClick={() => removeProduct(row.id)}
                            size="small"
                          >
                            <DeleteIcon fontSize="inherit" />
                          </IconButton>
                        </TableCell>
                        <TableCell width="10%">
                          <input
                            value={row.quantity}
                            onChange={(ev) => {
                              const { value } = ev.target;
                              changeQuantityToProduct(
                                row.id,
                                value,
                                row.priceType
                              );
                            }}
                            type="number"
                            onFocus={(ev) => ev.target.select()}
                            style={{
                              width: 52,
                              height: 40,
                              textAlign: 'center',
                            }}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell>
                          <input
                            value={row.comments}
                            onChange={(ev) => {
                              const { value } = ev.target;
                              changeCommentsToProduct(row.id, value);
                            }}
                            placeholder="Notas"
                            onFocus={(ev) => ev.target.select()}
                            style={{
                              width: '100%',
                              height: 40,
                            }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Select
                            value={row.priceType}
                            onChange={(e) =>
                              handleChangePriceType(row.id, e.target.value)
                            }
                          >
                            <MenuItem value="normal">Normal</MenuItem>
                            <MenuItem value="star">Especial</MenuItem>
                            <MenuItem value="distributor">
                              Distribuidor
                            </MenuItem>
                          </Select>
                        </TableCell>
                        <TableCell width="15%" align="right">
                          {currency(
                            row.priceType === 'star'
                              ? row.priceStar || row.price
                              : row.priceType === 'distributor'
                              ? row.priceDistributor || row.price
                              : row.price
                          )}
                          {isDistinguished && (
                            <Tooltip title="Precio especial" placement="right">
                              <span className={classes.mark}>*</span>
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell width="15%" align="right">
                          {currency(row.amount)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          )}

          <RegistryService.TotalsCard
            {...{ data, setData, showPayDialog, balance }}
          />
        </DialogContent>
      </Dialog>

      <RegistryService.PaymentDialog
        visible={payDialogVisible}
        onHide={hidePayDialog}
        onSave={addPayment}
        total={data.outstandingBalance}
        {...{ notify, classes }}
      />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  selectType: {
    width: 200,
  },
  content: {
    padding: '20px 40px',
    backgroundColor: '#f3f3f3',
  },
  table: {
    marginTop: 20,
  },
  input: {
    marginBottom: 20,
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  cellInput: {
    width: 150,
    paddingRight: '0 !important',
  },
  root: {
    // backgroundColor: "transparent"
    backgroundColor: 'rgba(0,0,0,0.04)',
  },
  deleteButton: {
    background: theme.palette.error.main,
    color: '#fff',
    marginRight: 20,
    '&:hover': {
      background: theme.palette.error.dark,
    },
  },
  mark: {
    color: theme.palette.secondary.main, //"#D4AF37",
    verticalAlign: 'inherit',
    marginLeft: 2,
    fontSize: 22,
    cursor: 'pointer',
  },
}));
