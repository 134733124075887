import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import actions from '../redux/catalogs/actions';
import { useDispatch, useSelector } from 'react-redux';

const filter = createFilterOptions();

export default function AutocompletePeopleRecommend({ value, setValue }) {
  const people = useSelector((state) => state.catalogs.people);

  const dispatch = useDispatch();
  const getPeople = () => dispatch(actions.getPeopleWhoRecommend());

  useEffect(() => {
    getPeople();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Autocomplete
      value={value}
      fullWidth
      margin="dense"
      size="small"
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          setValue({
            name: newValue,
          });
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          setValue({
            name: newValue.inputValue,
          });
        } else {
          setValue(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (params.inputValue !== '') {
          filtered.push({
            inputValue: params.inputValue,
            name: `Agregar "${params.inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="people-who-recommend"
      options={people}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.name;
      }}
      renderOption={(option) => option.name}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          margin="dense"
          size="small"
          label="Recomendado por"
          variant="outlined"
        />
      )}
    />
  );
}
