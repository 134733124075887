const actions = {
	LOGIN: "USER/LOGIN",
	LOGOUT: "USER/LOGOUT",
	SET: "USER/SET",
	CHANGE_PASSWORD: "USER/CHANGE_PASSWORD",

	set: (type, data) => ({
		type: actions.SET,
		payload: { type, data },
	}),
	login: (credentials) => ({
		type: actions.LOGIN,
		payload: { credentials },
	}),
	logout: () => ({
		type: actions.LOGOUT,
	}),
	changePassword: (data) => ({
		type: actions.CHANGE_PASSWORD,
		payload: { data },
	}),
};

export default actions;
