import React, { useState, useEffect, useRef } from 'react';
import {
  Paper,
  IconButton,
  InputBase,
  makeStyles,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from '@material-ui/core';
import { SearchTwoTone, PermIdentityTwoTone } from '@material-ui/icons';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import { useSelector, useDispatch } from 'react-redux';
import actions from '../redux/client/actions';
import { debounce } from 'lodash';

const SearchBox = ({
  showClientDialog,
  onClientSelected,
  clientSelected,
  autoClear = true,
}) => {
  const classes = useStyles();

  const options = useSelector((state) => state.client.options);
  const loading = useSelector((state) => state.client.optionsLoading);
  const [selected, setSelected] = useState(null);
  const [query, setQuery] = useState('');

  const dispatch = useDispatch();

  const search = (text) => dispatch(actions.search(text));

  useEffect(() => {
    if (clientSelected) {
      setSelected(clientSelected);
      console.log(clientSelected);
    }
  }, [clientSelected]);

  const debouncedFetchHits = useRef(
    debounce((query) => search(query), 500)
  ).current;

  useEffect(() => {
    debouncedFetchHits(query);
  }, [debouncedFetchHits, query]);

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    id: 'search-box',
    options: options || [],
    getOptionSelected: (option, value) => option.id === value.id,
    autoHighlight: true,
    getOptionLabel: (option) => {
      return autoClear ? '' : option.name;
    },
    selectOnFocus: true,
    clearOnEscape: true,
    value: selected,
    loading: loading,
    onInputChange: (ev) => {
      if (ev && ev.target) {
        const { value } = ev.target;
        if (value && value.length > 2 && !value.includes('Crear paciente'))
          setQuery(value);
        // search(value);
      }
    },
    filterOptions: (options, params) => {
      const filtered = options;

      if (params.inputValue !== '') {
        filtered.push({
          inputValue: params.inputValue,
          name: `Crear paciente con el nombre: "${params.inputValue}"`,
        });
      }

      return filtered;
    },
    onChange: (event, newValue) => {
      if (newValue && newValue.inputValue) {
        showClientDialog(true, { name: newValue.inputValue });
      } else {
        if (newValue && newValue.id) {
          setSelected(newValue);
          if (onClientSelected) onClientSelected(newValue);
        }
      }
    },
  });

  return (
    <div style={{ flex: 1 }}>
      <Paper
        elevation={0}
        component="form"
        className={classes.root}
        {...getRootProps()}
      >
        <IconButton className={classes.iconButton} aria-label="menu">
          <SearchTwoTone />
        </IconButton>
        <InputBase
          className={classes.input}
          placeholder="Buscar paciente"
          inputProps={{ 'aria-label': 'clients' }}
          {...getInputProps()}
          onFocus={(ev) => ev.target.select()}
        />
      </Paper>

      {groupedOptions.length > 0 ? (
        <Paper
          {...getListboxProps()}
          style={{ position: 'relative', zIndex: 9999999 }}
        >
          <List dense={true} className={classes.listbox}>
            {groupedOptions.map((option, index) => (
              <ListItem {...getOptionProps({ option, index })}>
                <ListItemAvatar>
                  <Avatar>
                    <PermIdentityTwoTone />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={option.name}
                  secondary={option.email || option.phone}
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      ) : null}
    </div>
  );
};

export default SearchBox;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    boxShadow: '-3px 10px 56px -6px rgba(214, 214, 214, 1)',
    borderRadius: 8,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  label: {
    display: 'block',
  },
  listbox: {
    width: '100%',
    margin: 0,
    padding: 0,
    zIndex: 4,
    position: 'absolute',
    boxShadow: '-3px 10px 56px -6px rgba(214, 214, 214, 1)',
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    maxHeight: 200,
    '& li[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
      cursor: 'pointer',
    },
    '& li:active': {
      backgroundColor: theme.palette.action.selected,
    },
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
