import React, { useEffect, useState } from "react";
import { Grid, AppBar, Toolbar, Typography } from "@material-ui/core";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import { useStyles } from "../Profile-styles";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";

export default function Balance({ balance, setNewBalance }) {
  const classes = useStyles();

  const [amount, setAmount] = useState(0);

  useEffect(() => {
    setAmount(balance | 0);
  }, [balance]);

  const updateBalance = (ev) => setNewBalance(amount);

  return (
    <Grid item xs={12}>
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar variant="dense" className={classes.appbar}>
          <AttachMoneyIcon className={classes.headerIcon} />
          <Typography
            variant="h6"
            color="inherit"
            className={classes.appbartitle}
          >
            Balance
          </Typography>
        </Toolbar>
      </AppBar>

      <div style={{ marginBottom: 30 }}>
        <CurrencyTextField
          variant="outlined"
          fullWidth
          margin="none"
          value={amount}
          currencySymbol="$"
          InputProps={{
            classes: {
              root: classes.balanceInput,
              notchedOutline: classes.borderless,
            },
          }}
          outputFormat="number"
          decimalCharacter="."
          digitGroupSeparator=","
          onChange={(event, value) => setAmount(value)}
          style={{
            color: amount > 0 ? "#ffc107" : amount < 0 ? "#D21F3C" : "#333",
          }}
          onBlur={updateBalance}
        />
      </div>
    </Grid>
  );
}
