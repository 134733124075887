import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function DialogRemoveClientConfirmation({
  visible,
  onHide,
  onAgree,
  value
}) {

  return (
    <Dialog
      open={visible}
      onClose={onHide}
      aria-labelledby="confirm-dialog"
      aria-describedby="dialog-description"
    >
      <DialogTitle id="confirm-dialog">{"¿Quieres eliminar este perfil de paciente?"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="dialog-description">
          Una vez eliminado ya no será posible verlo en la lista pacientes.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onHide} color="primary">Cancelar</Button>
        <Button
          onClick={() => onAgree(value)}
          color="secondary"
          autoFocus
          disableElevation
          variant="contained"
        >Eliminar</Button>
      </DialogActions>
    </Dialog>
  );
}