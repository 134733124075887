const actions = {
    GET_APPOINTMENT_TYPES: "CATALOGS/GET_APPOINTMENT_TYPES",
    GET_OBSERVATIONS: "CATALOGS/GET_OBSERVATIONS",
    GET_ROOMS: "CATALOGS/GET_ROOMS",
    GET_EMPLOYEES: "CATALOGS/EMPLOYEES",
    GET_PEOPLE_WHO_RECOMMEND: "CATALOGS/GET_PEOPLE_WHO_RECOMMEND",
    ADD_CATALOG_ITEM: "CATALOGS/ADD_CATALOG_ITEM",
    UPDATE_CATALOG_ITEM: "CATALOGS/UPDATE_CATALOG_ITEM",
    DELETE_CATALOG_ITEM: "CATALOGS/DELETE_CATALOG_ITEM",
    SET: "CATALOGS/SET",
    

    getAppointmentTypes: () => ({
        type: actions.GET_APPOINTMENT_TYPES,
    }),
    getObservations: () => ({
        type: actions.GET_OBSERVATIONS,
    }),
    getRooms: () => ({
        type: actions.GET_ROOMS,
    }),
    getEmployees: () => ({
        type: actions.GET_EMPLOYEES,
    }),
    getPeopleWhoRecommend: () => ({
        type: actions.GET_PEOPLE_WHO_RECOMMEND,
    }),
    addCatalogItem:(catalogName, data) =>(
        {
            type: actions.ADD_CATALOG_ITEM,
            payload: {catalogName, data}
        }
    ),
    updateCatalogItem:(catalogName, data) =>(
        {
            type: actions.UPDATE_CATALOG_ITEM,
            payload: {catalogName, data}
        }
    ),
    deleteCatalogItem:(catalogName, data) =>(
        {
            type: actions.DELETE_CATALOG_ITEM,
            payload: {catalogName, data}
        }
    ),
    set: (type, data) => ({
        type: actions.SET,
        payload: { type, data }
    }),
};

export default actions;