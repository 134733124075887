import React, { useState, useEffect } from 'react';
import {
  CssBaseline,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Grid,
  Typography,
  Snackbar,
  Hidden,
  Collapse,
  Divider,
  Menu,
  MenuItem,
} from '@material-ui/core';
import {
  Dashboard,
  EventTwoTone,
  People as PeopleIcon,
  BallotOutlined as BallotIcon,
  KeyboardBackspace as KeyboardBackspaceIcon,
  Menu as MenuIcon,
  Timeline as ActivityIcon,
} from '@material-ui/icons';
import logo from '../../assets/images/logo_white.png';
import { NavLink } from 'react-router-dom';
import { useStyles } from './Main.styles';
import { connect } from 'react-redux';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import EditIcon from '@material-ui/icons/EditTwoTone';
import IconButton from '@material-ui/core/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../../redux/store';
import * as notifyActions from '../../redux/notifications';
import appointmentsActions from '../../redux/appointments/actions';
import clientActions from '../../redux/client/actions';
import userActions from '../../redux/user/actions';
import profileActions from '../../redux/profile/actions';
import MuiAlert from '@material-ui/lab/Alert';
import SearchBox from './../../components/SearchBox';
import DialogNewClient from './shared/DialogNewClient';
import DialogAppointment from './shared/DialogAppointment';
import DialogChangePassword from './userSettings/ChangePassword/DialogChangePassword';
import BasicInfo from './profile/components/BasicInfo';
import ObservationCheck from './../../components/ObservationCheck';
import DialogObservations from './shared/DialogObservations';
import { getShortName } from '../../utils';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import CategoryIcon from '@material-ui/icons/Category';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import BirthdayIcon from '../../assets/images/birthdayLogoWhite.jpg';

const USER_MAGUE = '9eb9ff37-c2fd-470b-b04c-96cdec93da9d';
const USER_ADMIN = '683a4af0-b1c7-4b86-a4dd-0f57d2c2d899';

const Main = ({
  children,
  to,
  isProfileSelected,
  profile,
  notification,
  clientCreated,
  clientUpdated,
  updateClient,
  removeClient,
  clientDialog,
  appointmentDialog,
  observationsDialog,
  closeNotification,
  createClient,
  showClientDialog,
  showAppointmentsDialog,
  showObservationsDialog,
  hideObservationsDialog,
  distinguish,
  observations,
  addObservation,
  removeObservation,
  selectProfile,
  logout,
  changePassword,
}) => {
  function Alert(props) {
    return (
      <MuiAlert
        elevation={6}
        variant="filled"
        {...props}
        severity={props.severity || 'info'}
      />
    );
  }

  const [client, setClient] = useState({});
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    if (profile && profile.id) {
      setClient(profile);
    }
  }, [profile]);

  useEffect(() => {
    const id = localStorage.getItem('id');
    console.info('test', id);
    if (id === USER_MAGUE || id === USER_ADMIN) {
      console.info('logout');
      logout();
    }
  }, [logout]);

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CssBaseline />
      {isProfileSelected ? (
        <ProfileDrawer
          {...{
            client,
            setClient,
            showObservationsDialog,
            showClientDialog,
            removeObservation,
            distinguish,
            observations,
            mobileOpen,
            handleDrawerToggle,
          }}
        />
      ) : (
        <DefaultDrawer
          {...{ to, logout, mobileOpen, handleDrawerToggle, changePassword }}
        />
      )}
      <main className={classes.content}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Hidden lgUp>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <SearchBox
            {...{ showClientDialog }}
            clientSelected={profile}
            onClientSelected={(item) => selectProfile(item.id)}
          />
        </div>

        <div style={{ marginTop: 30 }} />

        {children}
      </main>

      <DialogNewClient
        visible={clientDialog.open}
        onHide={showClientDialog}
        onSave={createClient}
        onEdit={updateClient}
        onRemove={removeClient}
        data={clientDialog.data}
      />

      <DialogAppointment
        selectedClientId={appointmentDialog.selectedClientId}
        open={appointmentDialog.open}
        event={appointmentDialog.event}
        isNew={appointmentDialog.isNew}
        handleOpen={() => showAppointmentsDialog(false)}
      />

      <DialogObservations
        visible={observationsDialog.visible}
        onHide={hideObservationsDialog}
        onSave={(text) =>
          addObservation(client.id, observationsDialog.data.id, text)
        }
        onRemove={() =>
          removeObservation(client.id, observationsDialog.data.id)
        }
        data={observationsDialog.data}
      />

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={notification.open}
        onClose={closeNotification}
        autoHideDuration={6000}
      >
        <Alert
          onClose={closeNotification}
          severity={notification ? notification.type : 'info'}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

function DefaultDrawer({
  to,
  logout,
  mobileOpen,
  handleDrawerToggle,
  changePassword,
}) {
  const [catalogsOpen, setCatalogsOpen] = useState(false);
  const [anchorSettingsMenu, setAnchorSettingsMenu] = useState(null);
  const [dialogChangePasswordOpen, setDialogChangePasswordOpen] =
    useState(false);

  const renderLink = React.forwardRef((itemProps, ref) => (
    <NavLink to={to} {...itemProps} innerRef={ref} />
  ));
  const classes = useStyles();
  const catalogs = [
    { id: 1, name: 'Observaciones', internalName: 'observations' },
    { id: 2, name: 'Tipos de consulta', internalName: 'appointmentTypes' },
    { id: 3, name: 'Espacios', internalName: 'rooms' },
    { id: 4, name: 'Personal', internalName: 'employees' },
  ];
  function closeSettingsMenu() {
    setDialogChangePasswordOpen(false);
    setAnchorSettingsMenu(null);
    return;
  }
  function handleChangePassword(newPassword) {
    changePassword({ password: newPassword });
  }
  const drawer = (
    <>
      <div className={classes.toolbar}>
        <Grid
          container
          direction="row-reverse"
          className={classes.toolbarContainer}
        >
          <IconButton
            onClick={(event) => {
              setAnchorSettingsMenu(event.currentTarget);
            }}
          >
            <MoreHorizIcon className={classes.settingsIcon} />
          </IconButton>
          <Menu
            id="settingMenu"
            anchorEl={anchorSettingsMenu}
            keepMounted
            open={Boolean(anchorSettingsMenu)}
            onClose={() => {
              setAnchorSettingsMenu(null);
            }}
          >
            <MenuItem
              onClick={() => {
                setDialogChangePasswordOpen(true);
              }}
            >
              {' '}
              Cambiar contraseña{' '}
            </MenuItem>
          </Menu>
          <DialogChangePassword
            visible={dialogChangePasswordOpen}
            onHide={() => {
              closeSettingsMenu();
            }}
            onSave={handleChangePassword}
          />
        </Grid>
      </div>
      <img alt="logo" src={logo} className={classes.logo} />
      <List>
        <ListItem
          button
          component={renderLink}
          to="/main"
          exact
          activeClassName={classes.active}
        >
          <ListItemIcon className={classes.iconContainer}>
            <Dashboard className={classes.icon} />
          </ListItemIcon>
          <ListItemText
            primary="Dashboard"
            classes={{
              primary: classes.text,
            }}
          />
        </ListItem>
        <ListItem
          button
          component={renderLink}
          to="/main/calendar"
          exact
          activeClassName={classes.active}
        >
          <ListItemIcon className={classes.iconContainer}>
            <EventTwoTone className={classes.icon} />
          </ListItemIcon>
          <ListItemText
            primary="Calendario"
            classes={{
              primary: classes.text,
            }}
          />
        </ListItem>
        <ListItem
          button
          component={renderLink}
          to="/main/clients"
          exact
          activeClassName={classes.active}
        >
          <ListItemIcon className={classes.iconContainer}>
            <PeopleIcon className={classes.icon} />
          </ListItemIcon>
          <ListItemText
            primary="Pacientes"
            classes={{
              primary: classes.text,
            }}
          />
        </ListItem>
        <ListItem
          button
          component={renderLink}
          to="/main/activity"
          exact
          activeClassName={classes.active}
        >
          <ListItemIcon className={classes.iconContainer}>
            <ActivityIcon className={classes.icon} />
          </ListItemIcon>
          <ListItemText
            primary="Actividad"
            classes={{
              primary: classes.text,
            }}
          />
        </ListItem>
        <ListItem
          button
          component={renderLink}
          to="/main/products"
          exact
          activeClassName={classes.active}
        >
          <ListItemIcon className={classes.iconContainer}>
            <BallotIcon className={classes.icon} />
          </ListItemIcon>
          <ListItemText
            primary="Productos"
            classes={{
              primary: classes.text,
            }}
          />
        </ListItem>
        <ListItem button onClick={() => setCatalogsOpen(!catalogsOpen)}>
          <ListItemIcon className={classes.iconContainer}>
            <CategoryIcon className={classes.icon} />
          </ListItemIcon>
          <ListItemText
            primary="Catálogos"
            classes={{
              primary: classes.text,
            }}
          />
          {catalogsOpen ? (
            <ExpandLess className={classes.icon} />
          ) : (
            <ExpandMore className={classes.icon} />
          )}
        </ListItem>
        <Collapse in={catalogsOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {catalogs.map((catalogo, key) => (
              <ListItem
                key={key}
                button
                className={classes.nestedListItem}
                component={renderLink}
                to={'/main/catalogs/' + catalogo.internalName}
                exact
                activeClassName={classes.active}
              >
                {/* <ListItemIcon>
									<CategoryIcon className={classes.icon} />
								</ListItemIcon> */}
                <ListItemText
                  primary={catalogo.name}
                  classes={{ primary: classes.subtext }}
                />
              </ListItem>
            ))}
          </List>
        </Collapse>
      </List>

      <Divider
        style={{
          backgroundColor: '#2A2A2A',
          height: 0.6,
          marginTop: 50,
        }}
      />
      <ListItem button onClick={() => logout()}>
        <ListItemIcon className={classes.iconContainer}>
          <KeyboardBackspaceIcon className={classes.icon} />
        </ListItemIcon>
        <ListItemText
          primary="Salir"
          classes={{
            primary: classes.text,
          }}
        />
      </ListItem>
    </>
  );

  return (
    <>
      <Hidden lgUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <Drawer
          className={classes.drawer}
          variant="permanent"
          open
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="left"
        >
          {drawer}
        </Drawer>
      </Hidden>
    </>
  );
}

function ProfileDrawer({
  client,
  setClient,
  observations,
  showObservationsDialog,
  showClientDialog,
  distinguish,
  removeObservation,
  handleDrawerToggle,
  mobileOpen,
}) {
  const classes = useStyles();
  const birthdayList = useSelector((state) => state.client.birthdayList) || [];
  const dispatch = useDispatch();
  const goBack = () => {
    dispatch(clientActions.selectProfileById(null));
    history.goBack();
    // history.push("/main/clients");
  };
  const isTheirBirthday = () => {
    let flag = false;
    if (birthdayList.length > 0) {
      birthdayList.forEach((item) => {
        if (item.id === client.id) {
          flag = true;
        }
      });
    }
    return flag;
  };

  // const setDistinguishedClient = () => {
  //   distinguish(client.id, !client.distinguished);
  //   setClient({ ...client, distinguished: !client.distinguished });
  // };

  const drawer = (
    <>
      <Grid container direction="column">
        <Grid item container direction="row" justifyContent="center">
          <Grid xs={1} item>
            <IconButton onClick={() => goBack()}>
              <ChevronLeftIcon className={classes.backIcon} />
            </IconButton>
          </Grid>
          <Grid item xs={2}>
            <Grid container justifyContent="center">
              <Avatar
                className={classes.userAvatar}
                src={isTheirBirthday() ? BirthdayIcon : null}
              />
            </Grid>
          </Grid>
          <Grid item xs={7} className={classes.rowName}>
            <div className={classes.rowIcons}>
              <Typography
                variant="subtitle1"
                className={classes.profileNameText}
              >
                {getShortName(client.name)}
              </Typography>
            </div>
            {client.email && (
              <Typography variant="body2" className={classes.primaryText}>
                {client.email}
              </Typography>
            )}
          </Grid>
          <Grid item xs={2}>
            <div>
              {/* <IconButton
								aria-label="distinguish"
								className={classes.margin}
								style={{
									color: "#D4AF37",
									padding: "0 12px",
								}}
								onClick={() => setDistinguishedClient()}
							>
								{client.distinguished ? (
									<StarIcon color="inherit" style={{ height: 25, width: 25 }} />
								) : (
										<StarOutlinedIcon
											color="inherit"
											style={{ height: 25, width: 25 }}
										/>
									)}
							</IconButton> */}
              <IconButton
                aria-label="edit"
                style={{ padding: '0 12px' }}
                onClick={() => showClientDialog(true, client)}
              >
                <EditIcon
                  fontSize="small"
                  color="secondary"
                  style={{ height: 25, width: 25 }}
                />
              </IconButton>
            </div>
          </Grid>
        </Grid>

        <BasicInfo {...{ classes }} data={client} />

        <Grid item className={classes.profileDataSection}>
          <Typography className={classes.sectionTitle} variant="h5">
            Observaciones
          </Typography>

          {observations &&
            observations.length > 0 &&
            observations.map((item, index) => (
              <ObservationCheck
                key={index}
                label={item.name}
                description={item.comments}
                selected={item.active}
                onSelected={(checked) => {
                  showObservationsDialog({
                    id: item.id,
                    title: item.name,
                    observations: item.comments,
                    checked: !checked,
                  });
                }}
              />
            ))}
        </Grid>
      </Grid>
    </>
  );

  return (
    <>
      <Hidden lgUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.profileDrawer,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <Drawer
          className={classes.profileDrawer}
          variant="permanent"
          open
          classes={{
            paper: classes.profileDrawer,
          }}
          anchor="left"
        >
          {drawer}
        </Drawer>
      </Hidden>
    </>
  );
}

const mapStateToProps = (state) => ({
  isProfileSelected: state.client.isProfileSelected,
  profile: state.client.selected,
  notification: state.notifications,
  clientCreated: state.client.created,
  clientUpdated: state.client.updated,
  clientDialog: state.client.dialog,
  appointmentDialog: state.appointments.dialog,
  observationsDialog: state.profile.dialogObservations,
  observations: state.client.observations,
  appointments: state.client.appointments,
});

const mapDispatchToProps = (dispatch) => ({
  closeNotification: () => dispatch(notifyActions.close()),
  createClient: (client) => dispatch(clientActions.create(client)),
  updateClient: (id, client) => dispatch(clientActions.update(id, client)),
  removeClient: (id) => dispatch(clientActions.remove(id)),
  showClientDialog: (open, client) =>
    dispatch(clientActions.showDialog(open, client)),
  showAppointmentsDialog: (open, event, isNew) =>
    dispatch(appointmentsActions.showDialog(open, event, isNew)),
  distinguish: (id, value) => dispatch(clientActions.distinguish(id, value)),
  showObservationsDialog: (data) => dispatch(profileActions.showDialog(data)),
  hideObservationsDialog: () => dispatch(profileActions.hideDialog()),
  addObservation: (clientId, observationId, comments) =>
    dispatch(clientActions.addObservation(clientId, observationId, comments)),
  removeObservation: (clientId, observationId) =>
    dispatch(clientActions.removeObservation(clientId, observationId)),
  selectProfile: (id) => dispatch(clientActions.selectProfileById(id)),
  logout: () => dispatch(userActions.logout()),
  changePassword: (data) => dispatch(userActions.changePassword(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
