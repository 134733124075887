import React, { useCallback, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  makeStyles,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Fab,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { currency, isNull } from 'src/utils';
import moment from 'moment';
import { addDays } from 'date-fns';

import { useSelector, useDispatch } from 'react-redux';
import activityActions from 'src/redux/activities/actions';

import DateRangePicker from './components/DateRangePicker';
import TotalCards from './components/TotalCards';
import { KeyboardArrowUp } from '@material-ui/icons';

const Activity = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const activities = useSelector((s) => s.activities.data);
  const isLoading = useSelector((s) => s.activities.isLoading);

  const getActivities = useCallback(
    (startDate, endDate) =>
      dispatch(
        activityActions.getAll(
          moment(startDate).format('YYYY-MM-DD'),
          moment(endDate).format('YYYY-MM-DD')
        )
      ),
    [dispatch]
  );

  const getSalesByMethod = useCallback(
    (startDate, endDate) =>
      dispatch(
        activityActions.getSalesByMethod(
          moment(startDate).format('YYYY-MM-DD'),
          moment(endDate).format('YYYY-MM-DD')
        )
      ),
    [dispatch]
  );

  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
    key: 'selection',
  });

  function onDateChange(date) {
    setDateRange(date);
    if (date.startDate !== date.endDate) {
      getActivities(date.startDate, date.endDate);
      getSalesByMethod(date.startDate, date.endDate);
    }
  }

  useEffect(() => {
    getActivities(dateRange.startDate, dateRange.endDate);
    getSalesByMethod(dateRange.startDate, dateRange.endDate);
  }, [dateRange.endDate, dateRange.startDate, getActivities, getSalesByMethod]);

  const [showScroll, setShowScroll] = useState(false);
  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };
  window.addEventListener('scroll', checkScrollTop);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div>
      <DateRangePicker dateRange={dateRange} onDateChange={onDateChange} />

      <TotalCards />

      {isLoading ? (
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          className={classes.container}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <div style={{ marginBottom: 75 }}>
          {activities.map((activity, index) => (
            <Accordion key={index}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid className={classes.titleContainer}>
                  <Typography> {activity.client} </Typography>
                  <Typography>
                    {' '}
                    Total: {currency(isNull(activity.total, 0))}{' '}
                  </Typography>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.header}> Fecha </TableCell>
                      <TableCell className={classes.header}>
                        {' '}
                        Método de pago{' '}
                      </TableCell>
                      <TableCell className={classes.header}> Total </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {activity.payments.map((payment, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {' '}
                          {payment.date
                            ? moment(payment.date).format('DD/MM/YYYY')
                            : ''}{' '}
                        </TableCell>
                        <TableCell> {payment.method} </TableCell>
                        <TableCell> {currency(payment.total)} </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </AccordionDetails>
            </Accordion>
          ))}

          <Fab
            color="secondary"
            aria-label="scrollTop"
            className={classes.fabArrowUp}
            style={{ display: showScroll ? 'flex' : 'none' }}
            onClick={scrollTop}
          >
            <KeyboardArrowUp />
          </Fab>
        </div>
      )}

      {activities.length === 0 && (
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          className={classes.container}
        >
          <Typography variant="h5" className={classes.noResultsText}>
            {' '}
            No hay datos en el rango de fechas seleccionado{' '}
          </Typography>
        </Grid>
      )}
    </div>
  );
};

export default Activity;

const useStyles = makeStyles(() => ({
  card: {
    boxShadow: '-3px 10px 56px -6px rgba(214, 214, 214, 1)',
    borderRadius: 3,
    marginBottom: 30,
    background: '#fff',
    padding: '5px 10px',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  header: {
    fontWeight: 550,
  },
  noResultsText: {
    color: '#ccc',
  },
  container: {
    minHeight: 200,
  },
  fabArrowUp: {
    position: 'fixed',
    bottom: 20,
    right: 25,
  },
}));
