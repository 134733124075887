import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        width: '100%'
      },
    mainContainer: {
        marginTop: 15,
    },
    titleSection:{
        marginBottom:15,
    },
    title: {
        flex: '1 1 100%',
      },
    
}));