import actions from "./actions";

const initialState = {
    types: [],
    observations: [],
    rooms: [],
    employees: [],
    people: [],
    loading: false,
}

const reducer = (state = initialState, action) => {
    const { payload, type } = action;
    switch (type) {

        case actions.GET_APPOINTMENT_TYPES: {
            return {
                ...state,
                loading: true,
                types: [],
            }
        }
        case actions.GET_OBSERVATIONS: {
            return {
                ...state,
                loading: true,
                observations: [],
            }
        }
        case actions.GET_ROOMS: {
            return {
                ...state,
                loading: true,
                rooms: [],
            }
        }
        case actions.GET_EMPLOYEES: {
            return {
                ...state,
                loading: true,
                employees: [],
            }
        }
        case actions.GET_PEOPLE_WHO_RECOMMEND: {
            return {
                ...state,
                loading: true,
                people: [],
            }
        }
        case actions.SET: {
            return {
                ...state,
                loading: false,
                [payload.type]: payload.data,
            };
        }

        default:
            return state;
    }
}

export default reducer;