import React from 'react';
import { Grid, Typography, Avatar, makeStyles } from '@material-ui/core';
import Card from '../../../../components/Card';

const ItemList = ({ client, onClick }) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} className={classes.container}>
      <Card onClick={onClick}>
        <Grid container direction="row" xl={12} className={classes.cardClient}>
          <Grid item xs={1}>
            <Avatar className={classes.avatar} src={client.photo} />
          </Grid>
          <Grid item xs={4}>
            <Grid container direction="column" justifyContent="center">
              <Typography variant="subtitle2" className={classes.textName}>
                {client.name}
              </Typography>
              <Typography variant="caption" className={classes.textDescription}>
                Nombre
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <Grid container direction="column">
              <Typography variant="subtitle1">{client.age}</Typography>
              <Typography variant="caption" className={classes.textDescription}>
                Edad
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Grid container direction="column">
              <Typography variant="subtitle1" noWrap style={{ width: '100%' }}>
                {client.email}
              </Typography>
              <Typography variant="caption" className={classes.textDescription}>
                Correo
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Grid container direction="column">
              <Typography variant="subtitle1">{client.phone}</Typography>
              <Typography variant="caption" className={classes.textDescription}>
                Teléfono
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

export default ItemList;

const useStyles = makeStyles((theme) => ({
  container: {
    // height: 86,
    maxHeight: 84,
  },
  textDescription: {
    color: 'gray',
  },
  textName: {
    fontWeight: 500,
    fontSize: 16,
  },
  avatar: {
    height: theme.spacing(6),
    width: theme.spacing(6),
  },
}));
