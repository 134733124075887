import actions from './actions';

const initialState = {
  comparative: {
    loading: false,
    value: null,
  },
  sale: {
    loading: false,
    value: 0,
  },
  saleByMethods: {
    loading: false,
    data: [],
  },
  topProducts: {
    loading: false,
    data: [],
  },
  topClients: {
    loading: false,
    data: [],
  },
  topRecommended: {
    loading: false,
    data: [],
  },
  demographic: {
    loading: false,
    data: [],
  },
  appointments: {
    loading: false,
    data: [],
  },
  fill: {
    loading: false,
    data: {},
  },
  clientsWithOutstandingBalance: {
    loading: false,
    data: {},
  },
};

const reducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case actions.GET_REPORT: {
      return {
        ...state,
        comparative: {
          loading: true,
          value: null,
        },
        sale: {
          loading: true,
          value: 0,
        },
        saleByMethods: {
          loading: true,
          data: [],
        },
        demographic: {
          loading: true,
          data: [],
        },
        appointments: {
          loading: true,
          data: [],
        },
        topProducts: {
          loading: true,
          data: [],
        },
        topClients: {
          loading: true,
          data: [],
        },
        topRecommended: {
          loading: true,
          data: [],
        },
        fill: {
          loading: true,
          data: {},
        },
        clientsWithOutstandingBalance: {
          loading: true,
          data: {},
        },
      };
    }
    case actions.SET: {
      return {
        ...state,
        [payload.type]: payload.data,
      };
    }
    default:
      return state;
  }
};

export default reducer;
