import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import Card from "../../../../components/Card";
import { CardHeader, CardContent, Grid } from '@material-ui/core';

const TopRecommendedChart = ({ value }) => {

  const [categories, setCategories] = useState([]);
  const [series, setSeries] = useState([]);

  useEffect(() => {
    const { data } = value;
    const categories = [];
    const series = [];

    if (data) {
      data.forEach(item => {
        categories.push(item.label);
        series.push(item.count);
      });

      setCategories(categories);
      setSeries(series);
    }

  }, [value]);

  const defaults = {
    options: {
      chart: {
        stacked: true,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: true
        }
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0
          }
        }
      }],
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      colors: ['#1E1E1E', '#FF5F5F'],

      xaxis: {
        categories: categories,
      },
      legend: {
        position: 'top'
      },
      fill: {
        opacity: 1
      }
    },
    series: [{
      name: 'Recomendados',
      data: series
    }]
  };

  return (
    <Grid item xs={12}>
      <Card style={{ padding: 0, borderRadius: 3 }}>
        <CardHeader title='Top 10 recomendados' subheader='Conteo de más recomendaciones de pacientes' />
        <CardContent>
          <ReactApexChart
            options={defaults.options}
            series={defaults.series}
            type="bar"
            width="100%"
            height="500"
          />
        </CardContent>
      </Card>
    </Grid>
  );
}

export default TopRecommendedChart;