import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { green, grey } from '@material-ui/core/colors';
import { ArrowUpward as UpIcon } from '@material-ui/icons';
import { round } from '../../../../utils';

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: '-3px 10px 56px -6px rgba(214, 214, 214, 1)',
    borderRadius: 3,
    // marginBottom: 30,
    background: '#fff',
    padding: '5px 10px',
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  percentage: {
    fontSize: 12,
  },
  arrowIcon: {
    fontSize: 12,
    verticalAlign: 'middle',
  },
  quantity: {
    fontWeight: 'bold',
  },
  title: {
    fontSize: 14,
  },
  expandIcon: {
    fontSize: 16,
    color: '#ccc',
  },
}));

function InsightCard({ title, quantity, percentage, icon, color }) {
  const classes = useStyles();
  return (
    <div className={classes.card}>
      <Grid container>
        <Grid item xs={12} className={classes.item}>
          <Typography
            className={classes.quantity}
            variant="h6"
            style={{ color: color || grey[900] }}
          >
            {' '}
            {quantity}{' '}
          </Typography>
          {icon}
        </Grid>
        <Grid item xs={12} className={classes.item}>
          <Typography className={classes.title}> {title} </Typography>
          {percentage && (
            <Typography
              className={classes.percentage}
              style={{ color: green[500] }}
            >
              {' '}
              <UpIcon className={classes.arrowIcon} /> {round(percentage)} %
            </Typography>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default InsightCard;
