import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { getShortDate } from '../../../../utils';

export default function BasicInfo({ classes, data }) {

  return (
    <Grid item className={classes.profileDataSection}>
      <Grid>
        <Typography
          variant="body1"
          className={classes.primaryText}
        >{data.name}</Typography>
        <Typography
          variant="caption"
          className={classes.secondaryText}
        >Nombre</Typography>
      </Grid>
      <Grid container direction="row" className={classes.spaceTop}>
        <Grid item xs>
          <Typography
            variant="body1"
            className={classes.primaryText}
          >{data.age} años</Typography>
          <Typography
            variant="caption"
            className={classes.secondaryText}
          >Edad</Typography>
        </Grid>
        <Grid item xs>
          <Typography
            variant="body1"
            className={classes.primaryText}
          >{getShortDate(data.birthday)}</Typography>
          <Typography
            variant="caption"
            className={classes.secondaryText}
          >Fecha de nacimiento</Typography>
        </Grid>
      </Grid>
      <Grid className={classes.spaceTop}>
        <Typography
          variant="body1"
          className={classes.primaryText}
        >{data.email}</Typography>
        <Typography
          variant="caption"
          className={classes.secondaryText}
        >Email</Typography>
      </Grid>
      <Grid container direction="row" className={classes.spaceTop}>
        <Grid item xs>
          <Typography
            variant="body1"
            className={classes.primaryText}
          >{data.phone}</Typography>
          <Typography
            variant="caption"
            className={classes.secondaryText}
          >Teléfono</Typography>
        </Grid>
        <Grid item xs>
          <Typography
            variant="body1"
            className={classes.primaryText}
          >{data.emergencyPhone}</Typography>
          <Typography
            variant="caption"
            className={classes.secondaryText}
          >Tel. emergencias</Typography>
        </Grid>
      </Grid>

      <Grid className={classes.spaceTop}>
        <Typography
          variant="body1"
          className={classes.primaryText}
        >{data.recommendedBy}</Typography>
        <Typography
          variant="caption"
          className={classes.secondaryText}
        >Recomendado por</Typography>
      </Grid>
    </Grid>
  )
}
