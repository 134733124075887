import React from 'react';
import {
  Grid,
  Typography,
  Button,
  TextField,
  MuiThemeProvider,
  Snackbar,
  CircularProgress,
} from '@material-ui/core';
import {
  Person as PersonIcon,
  VisibilityOutlined as LockIcon,
} from '@material-ui/icons';
import { useStyles, theme } from './Login-styles';
import { connect } from 'react-redux';
import styled from 'styled-components';
import actions from '../../redux/user/actions';
import * as notifyActions from '../../redux/notifications';
import logo_img from '../../assets/images/logo_white.png';
import { Formik } from 'formik';
import MuiAlert from '@material-ui/lab/Alert';

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Login({ login, error, notification, loading, closeNotification }) {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <main className={classes.layout}>
        <Grid container>
          <Grid className={classes.logo_container} item md={12} xs={12}>
            <img alt="logo" className={classes.logo} src={logo_img} />
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
            style={
              !error ? { paddingBottom: '20px' } : { paddingBottom: '0px' }
            }
          >
            <Typography className={classes.title} variant="h6">
              Iniciar sesión
            </Typography>
          </Grid>
          {error && (
            <Grid item md={12} xs={12} style={{ paddingTop: '0px' }}>
              <Typography
                style={{ color: 'red', padding: '10px', margin: '0px' }}
              >
                Las credenciales son incorrectas
              </Typography>
            </Grid>
          )}
        </Grid>

        <Formik
          initialValues={{ username: '', password: '' }}
          onSubmit={(values) => {
            login({ username: values.username, password: values.password });
          }}
        >
          {({ values, handleChange, handleSubmit }) => (
            <MuiThemeProvider theme={theme}>
              <form onSubmit={handleSubmit}>
                <TextField
                  id="username"
                  name="username"
                  placeholder="Nombre de usuario"
                  className={classes.textfield}
                  variant="outlined"
                  fullWidth
                  value={values.username}
                  onChange={handleChange}
                  InputProps={{
                    endAdornment: <PersonIcon />,
                  }}
                />
                <TextField
                  id="password"
                  name="password"
                  type="password"
                  variant="outlined"
                  fullWidth
                  placeholder="Contraseña"
                  className={classes.textfield}
                  value={values.password}
                  onChange={handleChange}
                  InputProps={{
                    endAdornment: <LockIcon />,
                  }}
                />
                <Grid container alignItems="center" justifyContent="flex-end">
                  {/*<Typography
												className={classes.colorPrimary}
												style={{ padding: 10 }}
											>
												¿Olvidaste tu contraseña?
											</Typography>*/}
                  <div className={classes.wrapper}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={loading}
                    >
                      Iniciar Sesión
                    </Button>
                    {loading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </Grid>
              </form>
            </MuiThemeProvider>
          )}
        </Formik>
      </main>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={notification.open}
        onClose={closeNotification}
        autoHideDuration={6000}
      >
        <Alert
          onClose={closeNotification}
          severity={notification ? notification.type : 'info'}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
  notification: state.notifications,
});

const mapDispatchToProps = (dispatch) => ({
  login: (username, password) => dispatch(actions.login(username, password)),
  closeNotification: () => dispatch(notifyActions.close()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
