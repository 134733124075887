/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
  TextField,
  Grid,
  Avatar,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { isNumber, validateEmail } from '../../../../utils';
import AutocompletePeopleRecommend from '../../../../components/AutocompletePeopleRecommend';
require('moment/locale/es.js');

export default function ClientDialog({ visible, hide, save, name }) {
  const classes = useStyles();
  const [client, setClient] = useState({});
  const [validation, setValidation] = useState({
    isValid: false,
    name: name ? 'valid' : 'incomplete',
    phone: 'incomplete',
    email: 'incomplete',
    birthday: 'valid',
  });

  const handleChangeText = (name, e) =>
    setClient({ ...client, [name]: e.target.value });

  useEffect(() => {
    if (validation.isValid) {
      save({
        name: client.name,
        phone: client.phone,
        email: client.email ? client.email : null,
        recommendedBy: client.recommendedBy ? client.recommendedBy : null,
        birthday: client.birthday ? client.birthday : null,
      });
      hide();
    }
  }, [validation]);

  useEffect(() => {
    setClient({
      name: name || '',
      phone: '',
      emergencyPhone: '',
      email: '',
      birthday: new Date(),
      recommendedBy: '',
    });
  }, [visible]);

  const validForm = () => {
    let name = 'valid',
      email = 'valid',
      phone = 'valid',
      emergencyPhone = 'valid',
      birthday = 'valid';

    if (!client.name || client.name < 3) name = 'invalid';
    if (client.email && !validateEmail(client.email)) email = 'invalid';
    if (!client.phone || client.phone.length !== 10) phone = 'invalid';
    if (client.emergencyPhone && client.emergencyPhone.length !== 10)
      emergencyPhone = 'invalid';
    if (!moment(client.birthday).isValid()) birthday = 'invalid';

    setValidation({
      ...validation,
      isValid:
        name === 'valid' &&
        email === 'valid' &&
        phone === 'valid' &&
        emergencyPhone === 'valid' &&
        birthday === 'valid',
      name,
      email,
      phone,
      emergencyPhone,
      birthday,
    });
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={visible}
      onClose={hide}
      aria-labelledby="dialog-title"
    >
      <DialogTitle id="dialog-title">Nuevo paciente</DialogTitle>
      <DialogContent>
        <Grid container direction="row" spacing={1}>
          <Grid item xs={12}>
            <Avatar className={classes.avatar} src={client.profileUrl} />
          </Grid>

          <Grid item xs={12}>
            <TextField
              error={validation.name === 'invalid'}
              fullWidth
              id="name"
              margin="dense"
              label="Nombre"
              size="small"
              value={client.name}
              onChange={(ev) => handleChangeText('name', ev)}
              required={true}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                fullWidth
                margin="dense"
                autoOk
                disableFuture
                inputVariant="outlined"
                size="small"
                format="DD-MM-YYYY"
                id="birthday"
                invalidDateMessage="Formato de fecha inválido"
                label="Fecha nacimiento"
                value={client.birthday}
                onChange={(date, value) => {
                  setClient({
                    ...client,
                    birthday: moment.isMoment(date)
                      ? date.endOf('day').toDate()
                      : value,
                  });
                }}
                KeyboardButtonProps={{
                  'aria-label': 'birthday',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              margin="dense"
              error={validation.email === 'invalid'}
              helperText={
                validation.email === 'invalid'
                  ? 'El correo electrónico es incorrecto'
                  : ''
              }
              id="email"
              label="Correo electrónico"
              size="small"
              onChange={(ev) => handleChangeText('email', ev)}
              value={client.email}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              margin="dense"
              error={validation.phone === 'invalid'}
              id="phone"
              label="Teléfono"
              required={true}
              size="small"
              onChange={(ev) => {
                const { value } = ev.target;

                if (!value || (isNumber(value) && value.length <= 10))
                  handleChangeText('phone', ev);
              }}
              value={client.phone}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              margin="dense"
              id="emergencyPhone"
              error={validation.emergencyPhone === 'invalid'}
              label="Teléfono de emergencias"
              size="small"
              onChange={(ev) => {
                const { value } = ev.target;

                if (!value || (isNumber(value) && value.length <= 10))
                  handleChangeText('emergencyPhone', ev);
              }}
              value={client.emergencyPhone}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12}>
            <AutocompletePeopleRecommend
              value={client.recommendedBy}
              setValue={(item) =>
                setClient({ ...client, recommendedBy: item ? item.name : '' })
              }
            />
            {/* <TextField
              fullWidth
              id="recommendedBy"
              margin="dense"
              label="Recomendado por"
              size="small"
              value={client.recommendedBy}
              onChange={(ev) => handleChangeText('recommendedBy', ev)}
              variant="outlined" /> */}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={hide} color="primary">
          Cerrar
        </Button>
        <Button onClick={validForm} color="secondary">
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  avatar: {
    height: theme.spacing(12),
    width: theme.spacing(12),
    margin: '0 auto',
  },
}));
