import React from 'react';
import Card from "../../../../components/Card";
import { CardHeader, CardContent, Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';

const TopProductsChart = ({ value }) => {

  return (
    <Grid item xs={12}>
      <Card style={{ padding: 0, borderRadius: 3 }}>
        <CardHeader
          title='Top 10 productos'
          subheader="Lista de productos más vendidos"
        />
        <CardContent>
          <TableContainer>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Producto</TableCell>
                  <TableCell align="right">Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {value.data.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="right">{row.count}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default TopProductsChart;