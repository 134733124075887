import {
  put,
  takeEvery,
  takeLatest,
  call,
  all,
  select,
} from 'redux-saga/effects';
import actions from './actions';
import appointmentsActions from '../appointments/actions';
import * as notifications from '../notifications';
import { history } from '../store';
import api from '../api';

const API = new api();
API.createEntity('clients');

function* getAll({ payload }) {
  try {
    const res = yield call(API.endpoints.clients.getClients, payload.params);
    yield put(actions.set('data', res.data));
  } catch (error) {
    let message = error.message;
    if (error.response && error.response.data)
      message = error.response.data.message;
    yield put(actions.set('error', message));
  }
}

function* getBirthdayList({ payload }) {
  try {
    const res = yield call(API.endpoints.clients.getAllPath, '/birthdaylist');
    yield put(actions.set('birthdayList', res.data));
  } catch (error) {
    let message = error.message;
    if (error.response && error.response.data)
      message = error.response.data.message;
    yield put(actions.set('error', message));
  }
}

export async function getClientById(id) {
  if (!id) return;
  try {
    const res = await API.endpoints.clients.getOne(id);
    if (res.data) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
    // let message = error.message;
    // if (error.response && error.response.data)
    //   message = error.response.data.message;
  }
}

function* selectProfileById({ payload }) {
  if (!payload.id) return;

  try {
    const res = yield call(API.endpoints.clients.getOne, payload.id);
    if (res.data) {
      all([
        yield history.push('/main/profile'),
        yield call(selectProfileById, { payload: { id: null } }),
        // yield call(getAppointments, { payload: { clientId: payload.id } }),
        yield put(actions.set('selected', res.data)),
        yield put(actions.set('isProfileSelected', true)),
      ]);
    }
  } catch (error) {
    let message = error.message;
    if (error.response && error.response.data)
      message = error.response.data.message;
    yield put(actions.set('error', message));
  }
}

function* getAppointments({ payload }) {
  const API2 = new api();
  API2.createEntity('appointments');
  try {
    const client = yield select((state) => state.client.selected);
    const res = yield call(
      API2.endpoints.appointments.getAppointmentsByClient,
      client.id
    );
    yield put(actions.set('appointments', res.data));
  } catch (error) {
    let message = error.message;
    if (error.response && error.response.data)
      message = error.response.data.message;
    yield put(actions.set('error', message));
  }
}

function* search({ payload }) {
  try {
    const res = yield call(API.endpoints.clients.searchClients, payload.text);
    yield put(actions.set('options', res.data));
  } catch (error) {
    let message = error.message;
    if (error.response && error.response.data)
      message = error.response.data.message;
    yield put(actions.set('error', message));
  }
}

function* create({ payload }) {
  try {
    const res = yield call(API.endpoints.clients.post, payload.client);
    if (res.data) {
      // const { id } = res.data;
      // yield put(actions.set("created", res.data));
      all([
        // yield history.push("/main/profile"),
        yield put(notifications.show('success', 'Paciente creado con éxito')),
        // yield call(getObservations, { payload: { id: id } }),
        // yield call(getAppointments, { payload: { clientId: id } }),
        yield put(actions.set('selected', res.data)),
        yield call(getAll, {
          payload: { params: { text: '', page: 0, limit: 5 } },
        }),
        // yield put(actions.set("isProfileSelected", true)),
      ]);
    } else {
      yield put(notifications.show('error', 'Ha ocurrido un error inesperado'));
    }
  } catch (error) {
    let message = error.message;
    if (error.response && error.response.data)
      message = error.response.data.message;
    yield put(actions.set('error', message));
  }
}

function* update({ payload }) {
  try {
    const res = yield call(
      API.endpoints.clients.update,
      payload.id,
      payload.client
    );
    if (res.data) {
      // const { id } = res.data;
      // yield put(actions.set("updated", res.data));
      all([
        yield history.push('/main/profile'),
        yield put(notifications.show('success', 'Actualización de datos')),
        yield put(actions.set('selected', res.data)),
        yield put(actions.set('isProfileSelected', true)),
      ]);
    }
  } catch (error) {
    let message = error.message;
    if (error.response && error.response.data)
      message = error.response.data.message;
    yield put(actions.set('error', message));
  }
}

function* remove({ payload }) {
  try {
    const res = yield call(API.endpoints.clients.delete, payload.id);
    if (res.data) {
      all([
        yield history.push('/main/calendar'),
        yield put(notifications.show('success', 'Perfil eliminado')),
        yield put(actions.set('dialog', { data: {}, open: false })),
        yield put(appointmentsActions.getAll('month', new Date())),
        yield put(actions.set('isProfileSelected', false)),
        yield put(actions.set('selected', null)),
        yield put(actions.set('options', [])),
      ]);
    }
  } catch (error) {
    let message = error.message;
    if (error.response && error.response.data)
      message = error.response.data.message;
    yield put(actions.set('error', message));
  }
}

function* distinguish({ payload }) {
  try {
    yield call(API.endpoints.clients.distinguish, payload.id, payload.value);
    // yield put(actions.set("created", res.data));
  } catch (error) {
    let message = error.message;
    if (error.response && error.response.data)
      message = error.response.data.message;
    yield put(actions.set('error', message));
  }
}

function* getObservations({ payload }) {
  try {
    const client = yield select((state) => state.client.selected);
    const res = yield call(API.endpoints.clients.getObservations, client.id);
    yield put(actions.set('observations', res.data));
  } catch (error) {
    let message = error.message;
    if (error.response && error.response.data)
      message = error.response.data.message;
    yield put(actions.set('error', message));
  }
}

function* getBalance({ payload }) {
  try {
    const client = yield select((state) => state.client.selected);
    const res = yield call(API.endpoints.clients.getBalance, client.id);
    yield put(actions.set('balance', res.data));
  } catch (error) {
    let message = error.message;
    if (error.response && error.response.data)
      message = error.response.data.message;
    yield put(actions.set('error', message));
  }
}

function* addQuantityToBalance({ payload }) {
  try {
    const res = yield call(
      API.endpoints.clients.addQuantityToBalance,
      payload.clientId,
      payload.quantity
    );
    yield put(actions.set('balance', res.data));
  } catch (error) {
    let message = error.message;
    if (error.response && error.response.data)
      message = error.response.data.message;
    yield put(actions.set('error', message));
  }
}

function* setNewBalance({ payload }) {
  try {
    const client = yield select((state) => state.client.selected);

    const res = yield call(
      API.endpoints.clients.setNewBalance,
      client.id,
      payload.quantity
    );
    // yield put(actions.set("balance", res.data));
    if (res.data)
      yield put(notifications.show('success', 'Balance actualizado'));
  } catch (error) {
    let message = error.message;
    if (error.response && error.response.data)
      message = error.response.data.message;
    yield put(actions.set('error', message));
  }
}

function* addObservation({ payload }) {
  try {
    yield call(
      API.endpoints.clients.addObservation,
      payload.clientId,
      payload.observationId,
      payload.comments
    );
    yield call(getObservations, { payload: { id: payload.clientId } });
  } catch (error) {
    let message = error.message;
    if (error.response && error.response.data)
      message = error.response.data.message;
    yield put(actions.set('error', message));
  }
}

function* removeObservation({ payload }) {
  try {
    yield call(
      API.endpoints.clients.removeObservation,
      payload.clientId,
      payload.observationId
    );
    yield call(getObservations, { payload: { id: payload.clientId } });
  } catch (error) {
    let message = error.message;
    if (error.response && error.response.data)
      message = error.response.data.message;
    yield put(actions.set('error', message));
  }
}

function* exportData() {
  try {
    yield call(
      API.endpoints.clients.exportData,
      '/generate/excel',
      'Pacientes'
    );
    yield put(actions.set('exportLoading', false));
  } catch (error) {
    let message = error.message;
    if (error.response && error.response.data)
      message = error.response.data.message;
    yield put(notifications.show('error', message));
  }
}

function* saveNotes({ payload }) {
  const client = yield select((state) => state.client.selected);

  try {
    yield call(API.endpoints.clients.saveNotes, client.id, payload.notes);

    yield getNotes(payload.clientId);
  } catch (error) {
    console.log(error);
  } finally {
    yield put(actions.set('notesLoading', false));
  }
}

function* getNotes() {
  try {
    const client = yield select((state) => state.client.selected);
    const result = yield call(API.endpoints.clients.getNotes, client.id);
    yield put(actions.set('notes', result.data || ''));
  } catch (error) {
    console.log(error);
    yield put(actions.set('notes', ''));
  } finally {
    yield put(actions.set('notesLoading', false));
  }
}

export default function* index() {
  yield takeEvery(actions.GET_ALL, getAll);
  yield takeLatest(actions.CREATE, create);
  yield takeEvery(actions.UPDATE, update);
  yield takeEvery(actions.REMOVE, remove);
  yield takeLatest(actions.SEARCH, search);
  yield takeEvery(actions.DISTINGUISH, distinguish);
  yield takeEvery(actions.GET_OBSERVATIONS, getObservations);
  yield takeEvery(actions.ADD_OBSERVATION, addObservation);
  yield takeEvery(actions.REMOVE_OBSERVATION, removeObservation);
  yield takeEvery(actions.GET_APPOINTMENTS, getAppointments);
  yield takeEvery(actions.SELECT_PROFILE, selectProfileById);
  yield takeEvery(actions.GET_BALANCE, getBalance);
  yield takeEvery(actions.ADD_TO_BALANCE, addQuantityToBalance);
  yield takeEvery(actions.SET_NEW_BALANCE, setNewBalance);
  yield takeEvery(actions.EXPORT_EXCEL, exportData);
  yield takeEvery(actions.SAVE_NOTES, saveNotes);
  yield takeEvery(actions.GET_NOTES, getNotes);
  yield takeEvery(actions.GET_BIRTHDAY_LIST, getBirthdayList);
  yield takeEvery(actions.GET_BY_ID, getClientById);
}
