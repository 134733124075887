import React, { useState, useEffect } from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import RadioButtonUnchecked from '@material-ui/icons/FiberManualRecordTwoTone';
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked';
import { withStyles } from '@material-ui/core';
import theme from '../assets/theme';

const Control = withStyles({
  root: {
    display: 'block',
    color: 'lightgray',
  },
  label: {
    verticalAlign: 'middle'
  }
})(FormControlLabel);

export default function ObservationCheck({ label, description, selected, onSelected }) {
  const [value, setValue] = useState(false);

  useEffect(() => {
    setValue(selected);
  }, [selected]);

  return (
    <div style={{ marginBottom: 10 }}>
      <Control
        label={label}
        labelPlacement="end"
        style={{
          color: value ? theme.palette.secondary.main : 'lightgray'
        }}
        control={
          <Checkbox
            color="secondary"
            checked={value}
            onChange={(ev) => {
              const { checked } = ev.target;
              onSelected(checked);
            }}
            size="small"
            style={{ color: 'lightgray' }}
            icon={<RadioButtonUnchecked />}
            checkedIcon={<RadioButtonChecked color="secondary" />}
          />
        }
      />
      <Typography variant="caption" style={{
        color: 'lightgray',
        margin: '-10px 0 0 28px',
        display: 'block',
        whiteSpace: 'pre-wrap'
      }}>{value ? description : ''}</Typography>
    </div>
  )
}
