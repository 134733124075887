import React, { useState, useRef, useEffect } from 'react';
import { Grid, TextField, CircularProgress } from '@material-ui/core';
import { debounce } from 'lodash';
import { useDispatch } from 'react-redux';
import actions from 'src/redux/client/actions';

let isFirstRender = true;
export default function Notes({ loading, notes }) {
  const [text, setText] = useState(notes);

  const dispatch = useDispatch();

  const saveNotes = (notes) => dispatch(actions.saveNotes(notes));

  useEffect(() => {
    setText(notes);
  }, [notes, setText]);

  const debouncedSaveNotes = useRef(
    debounce((text) => saveNotes(text), 500)
  ).current;

  useEffect(() => {
    if (text !== undefined && !isFirstRender) debouncedSaveNotes(text);
  }, [debouncedSaveNotes, text]);

  return (
    <Grid item xs={12}>
      <div style={{ marginBottom: 30, position: 'relative' }}>
        {loading && (
          <CircularProgress
            color="secondary"
            style={{
              width: 15,
              height: 15,
              position: 'absolute',
              right: 10,
              bottom: 10,
            }}
          />
        )}
        <TextField
          id="notes-field"
          label="Notas"
          multiline
          fullWidth
          minRows={4}
          variant="outlined"
          value={text}
          onChange={(ev) => {
            isFirstRender = false;
            setText(ev.target.value);
          }}
        />
      </div>
    </Grid>
  );
}
