import React, { Fragment } from 'react';
import {
  Grid,
  Typography,
  Button,
  Toolbar,
  AppBar,
  IconButton,
  Divider,
  Tooltip,
} from '@material-ui/core';
import Card from '../../../../components/Card';
import { useStyles } from '../Profile-styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { getPrettyDate, getLongDate } from '../../../../utils';
import { history } from '../../../../redux/store';

function AppointmentList({
  appointments: data,
  showDialog,
  client,
  removeProfile,
}) {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar variant="dense" className={classes.appbar}>
          <DateRangeIcon className={classes.headerIcon} />
          <Typography
            variant="h6"
            color="inherit"
            className={classes.appbartitle}
          >
            Citas
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            disableElevation
            className={classes.addNewBtn}
            onClick={() => {
              removeProfile();
              history.push('/main/calendar/' + client.id);
            }}
            // onClick={() => showDialog(
            // 	true,
            // 	{
            // 		start: new Date(),
            // 		end: new Date(),
            // 		// hour: 10,
            // 		clientId: client.id,
            // 		title: client.name,
            // 	},
            // 	false
            // )}
          >
            Nueva cita
          </Button>
        </Toolbar>
      </AppBar>

      <Card>
        <Grid
          className={classes.appointmentsContainer}
          container
          direction="column"
          spacing={3}
        >
          {data.map((item, index) => (
            <Fragment key={item.title}>
              {item.appointments.length > 0 ? (
                <>
                  <Grid item>
                    <Typography className={classes.titleGray}>
                      {item.title}
                    </Typography>
                  </Grid>
                  {item.appointments.map((value, key) => (
                    <Fragment key={value.id}>
                      <Grid
                        item
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Grid
                          item
                          className={classes.borderLeft}
                          style={{ borderLeftColor: value.color }}
                        >
                          <Typography variant="caption">
                            {value.type}
                          </Typography>
                          {value.room && (
                            <Typography variant="subtitle2">
                              {value.room}
                            </Typography>
                          )}
                          <Tooltip title={getLongDate(value.start)} arrow>
                            <Typography className={classes.subtitleGray}>
                              {getPrettyDate(value.start)}
                            </Typography>
                          </Tooltip>
                        </Grid>
                        {item.title === 'Próximas' && !value.canceled && (
                          <Grid item>
                            <IconButton
                              color="primary"
                              aria-label="go"
                              size="small"
                              onClick={() => showDialog(true, value, false)}
                            >
                              <ArrowForwardIcon />
                            </IconButton>
                          </Grid>
                        )}
                        {value.canceled && (
                          <span className={classes.canceled}>Cancelada</span>
                        )}
                        {value.confirmed && (
                          <span className={classes.confirmed}>Confirmada</span>
                        )}
                      </Grid>
                    </Fragment>
                  ))}
                  <Divider style={{ marginBottom: 30 }} />
                </>
              ) : item.title === 'Próximas' ? (
                <Typography key={item.title} variant="caption" align="center">
                  No hay citas próximas
                </Typography>
              ) : (
                <></>
              )}
            </Fragment>
          ))}
        </Grid>
      </Card>
    </Grid>
  );
}

export default AppointmentList;
