import { put, takeEvery, call } from "redux-saga/effects";
import actions from "./actions";
import api from "../api";

const API = new api();
API.createEntity("catalogs");

function* getAppointmentTypes() {
  try {
    const res = yield call(
      API.endpoints.catalogs.getAllPath,
      "/appointment/type"
    );
    yield put(actions.set("types", res.data));
  } catch (error) {
    let message = error.message;
    if (error.response && error.response.data)
      message = error.response.data.message;
    console.error("Error", message);
  }
}

function* getPeopleWhoRecommend() {
  try {
    const res = yield call(
      API.endpoints.catalogs.getAllPath,
      "/people/recommend"
    );
    yield put(actions.set("people", res.data));
  } catch (error) {
    let message = error.message;
    if (error.response && error.response.data)
      message = error.response.data.message;
    console.error("Error", message);
  }
}

function* getObservations() {
  try {
    const res = yield call(API.endpoints.catalogs.getAllPath, "/observations");
    yield put(actions.set("observations", res.data));
  } catch (error) {
    let message = error.message;
    if (error.response && error.response.data)
      message = error.response.data.message;
    console.error("Error", message);
  }
}

function* getRooms() {
  try {
    const res = yield call(API.endpoints.catalogs.getAllPath, "/rooms");
    yield put(actions.set("rooms", res.data));
  } catch (error) {
    let message = error.message;
    if (error.response && error.response.data)
      message = error.response.data.message;
    console.error("Error", message);
  }
}
function* getEmployees() {
  try {
    const res = yield call(API.endpoints.catalogs.getAllPath, "/employees");
    yield put(actions.set("employees", res.data));
  } catch (error) {
    let message = error.message;
    if (error.response && error.response.data)
      message = error.response.data.message;
    console.error("Error", message);
  }
}

function* addCatalogItem({ payload }) {
  switch (payload.catalogName) {
    case "rooms": {
      try {
        yield call(
          API.endpoints.catalogs.postPath,
          "/rooms",
          payload.data
        );
        yield getRooms();
      } catch (error) {
        let message = error.message;
        if (error.response && error.response.data)
          message = error.response.data.message;
        console.error("Error", message);
      }
      return;
    }
    case "observations": {
      try {
        yield call(
          API.endpoints.catalogs.postPath,
          "/observations",
          payload.data
        );
        yield getObservations();
      } catch (error) {
        let message = error.message;
        if (error.response && error.response.data)
          message = error.response.data.message;
        console.error("Error", message);
      }
      return;
    }
    case "appointmentTypes": {
      try {
        yield call(
          API.endpoints.catalogs.postPath,
          "/appointment/type",
          payload.data
        );
        yield getAppointmentTypes();
      } catch (error) {
        let message = error.message;
        if (error.response && error.response.data)
          message = error.response.data.message;
        console.error("Error", message);
      }
      return;
    }
    case "employees": {
      try {
        yield call(
          API.endpoints.catalogs.postPath,
          "/employees",
          payload.data
        );
        yield getEmployees();
      } catch (error) {
        let message = error.message;
        if (error.response && error.response.data)
          message = error.response.data.message;
        console.error("Error", message);
      }
      return;
    }
    default: {
      return;
    }
  }
}
function* updateCatalogItem({ payload }) {
  switch (payload.catalogName) {
    case "observations": {
      try {
        yield call(
          API.endpoints.catalogs.updatePath,
          "/observations",
          payload.data.id,
          payload.data
        );
        yield getObservations();
      } catch (error) {
        let message = error.message;
        if (error.response && error.response.data)
          message = error.response.data.message;
        console.error("Error", message);
      }
      return;
    }
    case "rooms": {
      try {
        yield call(
          API.endpoints.catalogs.updatePath,
          "/rooms",
          payload.data.id,
          payload.data
        );
        yield getRooms();
      } catch (error) {
        let message = error.message;
        if (error.response && error.response.data)
          message = error.response.data.message;
        console.error("Error", message);
      }
      return;
    }
    case "appointmentTypes": {
      try {
        yield call(
          API.endpoints.catalogs.updatePath,
          "/appointment/type",
          payload.data.id,
          payload.data
        );
        yield getAppointmentTypes();
      } catch (error) {
        let message = error.message;
        if (error.response && error.response.data)
          message = error.response.data.message;
        console.error("Error", message);
      }
      return;
    }
    case "employees": {
      try {
        yield call(
          API.endpoints.catalogs.updatePath,
          "/employees",
          payload.data.id,
          payload.data
        );
        yield getEmployees();
      } catch (error) {
        let message = error.message;
        if (error.response && error.response.data)
          message = error.response.data.message;
        console.error("Error", message);
      }
      return;
    }
    default: {
      return;
    }
  }
}
function* deleteCatalogItem({ payload }) {}
export default function* index() {
  yield takeEvery(actions.GET_APPOINTMENT_TYPES, getAppointmentTypes);
  yield takeEvery(actions.GET_OBSERVATIONS, getObservations);
  yield takeEvery(actions.GET_ROOMS, getRooms);
  yield takeEvery(actions.GET_EMPLOYEES, getEmployees);
  yield takeEvery(actions.ADD_CATALOG_ITEM, addCatalogItem);
  yield takeEvery(actions.UPDATE_CATALOG_ITEM, updateCatalogItem);
  yield takeEvery(actions.DELETE_CATALOG_ITEM, deleteCatalogItem);
  yield takeEvery(actions.GET_PEOPLE_WHO_RECOMMEND, getPeopleWhoRecommend);
}
