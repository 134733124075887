import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from '@material-ui/core';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';

export default function DialogProduct({
  visible,
  onHide,
  onSave,
  onEdit,
  data,
  loading,
}) {
  const classes = useStyles();
  const [client, setClient] = useState({});
  const [validation, setValidation] = useState({
    isValid: false,
    name: 'incomplete',
    type: 'incomplete',
    price: 'incomplete',
  });

  const handleChangeText = (name, e) =>
    setClient({ ...client, [name]: e.target.value });

  useEffect(() => {
    if (validation.isValid) {
      let params = {
        name: client.name,
        type: client.type,
        price: parseFloat(client.price),
        priceStar: parseFloat(
          client.priceStar ? client.priceStar : client.price
        ),
        priceDistributor: parseFloat(
          client.priceDistributor ? client.priceDistributor : client.price
        ),
        active: client.active,
      };

      if (data && data.id) {
        onEdit(data.id, params);
      } else {
        onSave(params);
      }
      onHide(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validation]);

  useEffect(() => {
    if (!visible)
      setClient({
        name: '',
        price: '',
        priceStar: '',
        priceDistributor: '',
        type: 'product',
        active: true,
      });
  }, [visible]);

  useEffect(() => {
    if (data && data.id)
      setClient({
        id: data.id,
        name: data.name,
        price: data.price,
        priceStar: data.priceStar,
        priceDistributor: data.priceDistributor,
        type: data.type,
        active: data.active,
      });
  }, [data]);

  const validForm = () => {
    let name = 'valid',
      type = 'valid',
      price = 'valid';

    if (!client.name || client.name < 3) name = 'invalid';
    if (!client.type) type = 'invalid';
    if (!client.price || isNaN(parseFloat(client.price))) price = 'invalid';

    setValidation({
      ...validation,
      isValid: name === 'valid' && type === 'valid' && price === 'valid',
      name,
      type,
      price,
    });
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={visible}
      onClose={() => onHide(false)}
      aria-labelledby="dialog-title"
    >
      <DialogTitle id="dialog-title">
        {client.id ? 'Editar' : 'Nuevo'} producto
      </DialogTitle>
      <DialogContent>
        <Grid container direction="row" spacing={1}>
          <Grid item xs={12}>
            <TextField
              error={validation.name === 'invalid'}
              fullWidth
              id="name"
              margin="dense"
              label="Nombre"
              size="small"
              value={client.name}
              onChange={(ev) => handleChangeText('name', ev)}
              required={true}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              fullWidth
              margin="dense"
              size="small"
              required={true}
            >
              <InputLabel id="type-label">Tipo</InputLabel>
              <Select
                labelId="type-label"
                id="type"
                value={client.type}
                onChange={(ev) => handleChangeText('type', ev)}
                label="Tipo"
              >
                <MenuItem value={'product'}>Producto</MenuItem>
                <MenuItem value={'service'}>Tratamiento</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <CurrencyTextField
              label="Precio unitario"
              fullWidth
              margin="dense"
              required={true}
              size="small"
              variant="outlined"
              value={client.price}
              currencySymbol="$"
              outputFormat="string"
              onChange={(event, value) =>
                setClient({ ...client, price: value })
              }
            />
          </Grid>

          <Grid item xs={12}>
            <CurrencyTextField
              label="Precio especial"
              fullWidth
              margin="dense"
              required={true}
              size="small"
              variant="outlined"
              value={client.priceStar}
              currencySymbol="$"
              outputFormat="string"
              onChange={(event, value) =>
                setClient({ ...client, priceStar: value })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <CurrencyTextField
              label="Precio distribuidor"
              fullWidth
              margin="dense"
              required={true}
              size="small"
              variant="outlined"
              value={client.priceDistributor}
              currencySymbol="$"
              outputFormat="string"
              onChange={(event, value) =>
                setClient({ ...client, priceDistributor: value })
              }
            />
          </Grid>
          {data && data.id && (
            <Grid item xs={12}>
              <FormControl
                component="fieldset"
                fullWidth
                margin="dense"
                size="small"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={client.active}
                      onChange={(ev) =>
                        setClient({ ...client, active: ev.target.checked })
                      }
                      name="active"
                    />
                  }
                  label="Activo"
                />
              </FormControl>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onHide(false)} color="primary">
          Cerrar
        </Button>
        <div className={classes.wrapper}>
          <Button
            color="secondary"
            disableElevation
            disabled={loading}
            onClick={validForm}
          >
            {client.id ? 'Actualizar' : 'Guardar'}
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));
