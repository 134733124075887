import React from 'react';
import Card from '../../../../components/Card';
import { makeStyles } from '@material-ui/core/styles';
import {
  CardHeader,
  CardContent,
  Grid,
  List,
  ListSubheader,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  ListItemSecondaryAction,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import moment from 'moment';
import BirthdayIcon from '../../../../assets/images/birthdayLogoWhite.jpg';

const BirthdayListClients = ({ list, loading, onSelectItem }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Card style={{ padding: 0, borderRadius: 3 }}>
        <CardHeader
          title="Próximos cumpleañeros"
          subheader="Lista de clientes con actividad reciente"
        />
        <CardContent
          style={{
            height: 500,
            padding: 0,
          }}
        >
          {loading && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 500,
              }}
            >
              <CircularProgress size={42} />
            </div>
          )}
          {!loading && (
            <List className={classes.root} subheader={<li />}>
              {list.length &&
                list.map((dayOfMonth) => (
                  <li key={dayOfMonth.day} className={classes.listSection}>
                    <ul className={classes.ul}>
                      <ListSubheader>{dayOfMonth.label}</ListSubheader>
                      {dayOfMonth?.data?.map((person) => (
                        <ListItem
                          key={person.id}
                          button
                          onClick={() => onSelectItem(person.id)}
                        >
                          <ListItemAvatar>
                            <Avatar src={BirthdayIcon} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={person.name}
                            secondaryTypographyProps={{
                              component: 'div',
                            }}
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="div"
                                  variant="body2"
                                  color="textPrimary"
                                >
                                  {moment(person.birthday).format('ll')}
                                </Typography>
                                <Typography
                                  component="div"
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  última actividad:{' '}
                                  {person.lastActivityType.toLowerCase()}{' '}
                                  {moment(person.lastActivityAt).fromNow()}
                                </Typography>
                              </React.Fragment>
                            }
                          />
                          <ListItemSecondaryAction>
                            <Typography component="span" variant="body2">
                              {person.age} años
                            </Typography>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                    </ul>
                  </li>
                ))}
            </List>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflowY: 'auto',
    maxHeight: 500,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
}));

export default BirthdayListClients;
