import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useStyles } from './Profile-styles';
import AppointmentList from './components/AppointmentList';
import ActivityRegistry from './components/ActivityRegistry';
import clientActions from '../../../redux/client/actions';
import actions from '../../../redux/appointments/actions';
import { useSelector, useDispatch } from 'react-redux';
import Balance from './components/Balance';
import Notes from './components/Notes';

function Profile() {
  const classes = useStyles();

  const appointments = useSelector((state) => state.client.appointments);
  const client = useSelector((state) => state.client.selected);
  const balance = useSelector((state) => state.client.balance);
  const notes = useSelector((state) => state.client.notes);
  const notesLoading = useSelector((state) => state.client.notesLoading);

  const dispatch = useDispatch();
  const showDialog = (open, event, isNew) =>
    dispatch(actions.showDialog(open, event, isNew));
  const getObservations = (id) => dispatch(clientActions.getObservations(id));
  const getAppointments = (id) => dispatch(clientActions.getAppointments(id));
  const getBalance = (id) => dispatch(clientActions.getBalance(id));
  const setNewBalance = (quantity) =>
    dispatch(clientActions.setNewBalance(quantity));
  const removeProfile = () => dispatch(clientActions.selectProfileById(null));
  const getNotes = () => dispatch(clientActions.getNotes(client.id));

  useEffect(() => {
    if (client) {
      getObservations(client.id);
      getAppointments(client.id);
      getBalance(client.id);
      getNotes(client.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  return (
    <div>
      <Grid
        container
        className={classes.root}
        spacing={3}
        alignItems="flex-start"
      >
        <ActivityRegistry />
        <Grid item xs={5} container>
          <Balance {...{ balance, setNewBalance }} />
          <Notes {...{ notes }} loading={notesLoading} />
          <AppointmentList
            {...{ appointments, showDialog, client, removeProfile }}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default Profile;
