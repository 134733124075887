import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Toolbar,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { useStyles } from './CatalogAdministration.styles';
import { connect } from 'react-redux';
import actions from '../../../redux/catalogs/actions';
import CatalogDialogEdit from './components/CatalogDialogEdit';
import { useParams } from 'react-router-dom';
import CatalogDialogNew from './components/CatalogDialogNew';
import AddIcon from '@material-ui/icons/Add';

const CatalogAdministration = ({
  observations,
  appointmentTypes,
  rooms,
  employees,
  getAppointmentTypes,
  getObservations,
  getRooms,
  getEmployees,
  addCatalogItem,
  updateCatalogItem,
  deleteCatalogItem,
}) => {
  const [editDialogOpen, setEditDialogOpen] = useState({
    open: false,
    data: {},
  });
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const { catalogName } = useParams();
  const [catalogItems, setCatalogItems] = useState([]);
  const [title, setTitle] = useState('');
  const classes = useStyles();

  useEffect(() => {
    if (catalogName === 'appointmentTypes') {
      setCatalogItems(appointmentTypes);
    } else if (catalogName === 'observations') {
      setCatalogItems(observations);
    } else if (catalogName === 'rooms') {
      setCatalogItems(rooms);
    } else if (catalogName === 'employees') {
      setCatalogItems(employees);
    }
  }, [observations, appointmentTypes, rooms, employees, catalogName]);

  useEffect(() => {
    switch (catalogName) {
      case 'appointmentTypes': {
        getAppointmentTypes();
        setTitle('Tipos de consulta');
        return;
      }
      case 'observations': {
        getObservations();
        setTitle('Tipos de observación');
        return;
      }
      case 'rooms': {
        getRooms();
        setTitle('Espacios');
        return;
      }
      case 'employees': {
        getEmployees();
        setTitle('Personal');
        return;
      }
      default: {
      }
    }
  }, [
    catalogName,
    getAppointmentTypes,
    getEmployees,
    getObservations,
    getRooms,
  ]);

  const handleUpdateItem = (data) => {
    switch (catalogName) {
      case 'appointmentTypes': {
        updateCatalogItem('appointmentTypes', data);
        return;
      }
      case 'observations': {
        updateCatalogItem('observations', data);
        return;
      }
      case 'rooms': {
        updateCatalogItem('rooms', data);
        return;
      }
      case 'employees': {
        updateCatalogItem('employees', data);
        return;
      }
      default: {
      }
    }
  };
  const handleAddItem = (data) => {
    switch (catalogName) {
      case 'appointmentTypes': {
        addCatalogItem('appointmentTypes', data);
        return;
      }
      case 'observations': {
        addCatalogItem('observations', data);
        return;
      }
      case 'rooms': {
        addCatalogItem('rooms', data);
        return;
      }
      case 'employees': {
        addCatalogItem('employees', data);
        return;
      }
      default: {
      }
    }
  };

  // const handleDeleteItem = (data) => {
  //   switch (catalogName) {
  //     case 'appointmentTypes': {
  //       deleteCatalogItem('appointmentTypes', data);
  //       return;
  //     }
  //     case 'observations': {
  //       deleteCatalogItem('observations', data);
  //       return;
  //     }
  //     case 'rooms': {
  //       deleteCatalogItem('rooms', data);
  //       return;
  //     }
  //     case 'employees': {
  //       deleteCatalogItem('employees', data);
  //       return;
  //     }
  //     default: {
  //     }
  //   }
  // };

  return (
    <Paper>
      <CatalogDialogNew
        catalogName={catalogName}
        visible={addDialogOpen}
        onHide={() => {
          setAddDialogOpen(false);
        }}
        onSave={handleAddItem}
      />
      <CatalogDialogEdit
        data={editDialogOpen.data}
        visible={editDialogOpen.open}
        onHide={() => {
          setEditDialogOpen({ open: false, data: {} });
        }}
        onSave={handleUpdateItem}
      />

      {/* <Grid
				item
				container
				className={classes.titleSection}
				direction="row"
			>
				<Grid item xs={6} container>
					<Typography variant="h4">{title}</Typography>
				</Grid>
				<Grid item xs={6} container justifyContent="flex-end">
					<Button
						variant="contained"
						color="secondary"
                        disableElevation
                        onClick={()=>{setAddDialogOpen(true)}}
					>
						Agregar
					</Button>
				</Grid>
			</Grid> */}
      <Grid item container>
        <Toolbar className={classes.root}>
          <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {title}
          </Typography>
          <Tooltip title="Crear nuevo">
            <IconButton
              aria-label="create"
              onClick={() => {
                setAddDialogOpen(true);
              }}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="products table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell>Activo</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {catalogItems.map((row) => (
                <TableRow
                  key={row.name}
                  onClick={() => {
                    setEditDialogOpen({
                      open: true,
                      data: row,
                    });
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="left">
                    <Checkbox checked={row.active} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  loading: state.catalogs.loading,
  error: state.client.error,
  appointmentTypes: state.catalogs.types,
  observations: state.catalogs.observations,
  rooms: state.catalogs.rooms,
  employees: state.catalogs.employees,
});

const mapDispatchToProps = (dispatch) => ({
  getAppointmentTypes: () => dispatch(actions.getAppointmentTypes()),
  getObservations: () => dispatch(actions.getObservations()),
  getRooms: () => dispatch(actions.getRooms()),
  getEmployees: () => dispatch(actions.getEmployees()),
  addCatalogItem: (catalogName, data) =>
    dispatch(actions.addCatalogItem(catalogName, data)),
  updateCatalogItem: (catalogName, data) =>
    dispatch(actions.updateCatalogItem(catalogName, data)),
  deleteCatalogItem: (catalogName, data) =>
    dispatch(actions.deleteCatalogItem(catalogName, data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CatalogAdministration);
