import React from 'react';
import Card from '../../../../components/Card';
import {
  CardHeader,
  CardContent,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import { currency, currencyFormat } from 'src/utils';

const ClientsWithOutstandingBalance = ({ data, loading, onSelectItem }) => {
  return (
    <Grid item xs={12}>
      <Card style={{ padding: 0, borderRadius: 3 }}>
        <CardHeader
          title={
            <div>
              <Typography variant="h5">
                <span>Saldo Pendiente Total:</span>
                <span
                  style={{
                    marginLeft: 12,
                    color: '#EE4B2B',
                  }}
                >
                  {currencyFormat(data?.total) || ''}
                </span>
              </Typography>
            </div>
          }
          titleTypographyProps={{
            component: 'div',
          }}
          subheader="Lista de clientes con saldo pendiente"
        />
        <CardContent
          style={{
            height: 500,
            overflowY: 'auto',
          }}
        >
          {loading && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 400,
              }}
            >
              <CircularProgress size={42} />
            </div>
          )}
          {!loading && (
            <TableContainer>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Cliente</TableCell>
                    <TableCell align="right">Saldo</TableCell>
                    <TableCell align="right">Último movimiento</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.detail?.length > 0
                    ? data?.detail?.map((row) => (
                        <TableRow
                          key={row.name}
                          onClick={() => onSelectItem(row.id)}
                        >
                          <TableCell component="th" scope="row">
                            {row.name}
                          </TableCell>
                          <TableCell align="right">
                            {currency(row.balance)}
                          </TableCell>
                          <TableCell align="right">
                            {moment(row.lastActivityAt).fromNow()}
                          </TableCell>
                        </TableRow>
                      ))
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ClientsWithOutstandingBalance;
