import actions from "./actions";

const initialState = {
    loading: false,
    error: null,
    created: null,
    data: [],
}

const reducer = (state = initialState, action) => {
    const { payload, type } = action;
    switch (type) {
        case actions.GET_BY_CLIENT: {
            return {
                ...state,
                loading: true,
                error: null,
                data: [],
            }
        }
        case actions.CREATE:
        case actions.PAY:
        case actions.UPDATE: {
            return {
                ...state,
                loading: true,
                error: null,
                created: null,
            }
        }
        case actions.SET: {
            return {
                ...state,
                loading: false,
                error: null,
                [payload.type]: payload.data,
            };
        }

        default:
            return state;
    }
}

export default reducer;