import { all } from 'redux-saga/effects';
import userSaga from "./user/saga";
import clientSaga from "./client/saga";
import catalogSaga from "./catalogs/saga";
import appointmentSaga from "./appointments/saga";
import settingsSaga from "./settings/saga";
import productsSaga from "./products/saga";
import registrySaga from "./registry/saga";
import reportSaga from "./reports/saga";
import activitySaga from "./activities/saga";

export default function* rootSaga() {
    yield all([
        userSaga(),
        clientSaga(),
        catalogSaga(),
        appointmentSaga(),
        settingsSaga(),
        productsSaga(),
        registrySaga(),
        reportSaga(),
        activitySaga()
    ]);
}
