import React, { useState, useEffect } from 'react';
import {
  Dialog,
  makeStyles,
  DialogActions,
  DialogContent,
  TextField,
  Button,
  DialogTitle,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { BlockPicker } from 'react-color';
import { colors } from '../../../../utils/index';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 20,
    height: 'auto',
  },
  textField: {
    width: '100%',
  },
  textFieldNotes: {
    width: '100%',
    height: 80,
  },
  activeContainer: {
    marginTop: 25,
  },
}));

function DialogEditCatalog({ data, visible, onHide, onSave, onDelete }) {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [color, setColor] = useState('#FFFFFF');
  const [active, setActive] = useState(data.active);

  useEffect(() => {
    setName(data.name || '');
    setColor(data.color || '#FFFFFF');
    setActive(data.active ?? true);
  }, [data]);

  const done = () => {
    if (!name) return;

    if (data.color) {
      onSave({ ...data, name, color, active });
    } else {
      onSave({ ...data, name, active });
    }
    onHide();
  };
  const deleteAction = () => {
    onDelete(data);
    onHide();
  };
  return (
    <Dialog
      className={classes.container}
      open={visible}
      onClose={onHide}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Editar</DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={2}>
          <Grid item container>
            <TextField
              value={name}
              fullWidth
              autoFocus
              id="name"
              label="Nombre"
              variant="outlined"
              onChange={(ev) => setName(ev.target.value)}
            />
          </Grid>

          {data.color ? (
            <Grid item container justifyContent="center" alignItems="center">
              <Grid item xs={6}>
                <BlockPicker
                  width="100%"
                  {...{ color, colors }}
                  onChangeComplete={(color, event) => setColor(color.hex)}
                  onChange={(color, event) => setColor(color.hex)}
                />
              </Grid>
              {/* <Grid item xs={6}>
								<div
									style={{
										backgroundColor: color,
										height: 50,
										width: 50,
									}}
								></div>
							</Grid> */}
            </Grid>
          ) : null}
        </Grid>
        <Grid item container className={classes.activeContainer}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={active}
                  onChange={() => {
                    setActive(!active);
                  }}
                  name="activeCheck"
                ></Checkbox>
              }
              label="Activo"
            ></FormControlLabel>
          </FormGroup>
        </Grid>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between' }}>
        <Button
          onClick={deleteAction}
          color="primary"
          disableElevation
          disabled
        >
          Eliminar
        </Button>
        <div>
          <Button onClick={onHide} color="primary" disableElevation>
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="secondary"
            disableElevation
            onClick={done}
          >
            Guardar
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default DialogEditCatalog;
