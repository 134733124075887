import { put, takeEvery, takeLatest, call, all, select } from "redux-saga/effects";
import actions from "./actions";
import clientActions from "../client/actions";
import * as notifications from "../notifications";
import api from "../api";

const API = new api();
API.createEntity("registries");

function* getRegistriesByClient({ payload }) {
  try {
    const res = yield call(
      API.endpoints.registries.getAllPath,
      `/client/${payload.id}`
    );

    if (res.data) {
      all([
        yield put(actions.set("data", res.data)),
        yield put(actions.set("loading", false)),
      ]);
    }
  } catch (error) {
    let message = error.message;
    if (error.response && error.response.data)
      message = error.response.data.message;

    yield put(actions.set("error", message));
    yield put(notifications.show("error", message));
  }
}

function* create({ payload }) {
  try {
    const res = yield call(
      API.endpoints.registries.postPath,
      `/client/${payload.clientId}`,
      payload.data
    );

    if (res.data) {
      all([
        yield put(actions.set("loading", false)),
        yield put(notifications.show("success", "Registro creado con éxito")),
        yield put(actions.set("created", true)),
        yield call(getRegistriesByClient, {
          payload: { id: payload.clientId },
        }),
        yield put(clientActions.getBalance(payload.clientId)),
      ]);
    } else {
      yield put(notifications.show("error", "Ha ocurrido un error inesperado"));
    }
  } catch (error) {
    let message = error.message;
    if (error.response && error.response.data)
      message = error.response.data.message;

    yield put(actions.set("error", message));
    yield put(notifications.show("error", message));
  }
}

function* update({ payload }) {
  try {
    const res = yield call(
      API.endpoints.registries.update,
      payload.id,
      payload.data
    );

    if (res.data) {
      all([
        yield put(actions.set("loading", false)),
        yield put(notifications.show("success", "Registro actualizado")),
        yield put(actions.set("created", true)),
        yield call(getRegistriesByClient, {
          payload: { id: payload.clientId },
        }),
        yield put(clientActions.getBalance(payload.clientId)),
      ]);
    } else {
      yield put(notifications.show("error", "Ha ocurrido un error inesperado"));
    }
  } catch (error) {
    let message = error.message;
    if (error.response && error.response.data)
      message = error.response.data.message;

    yield put(actions.set("error", message));
    yield put(notifications.show("error", message));
  }
}

function* pay({ payload }) {
  try {
    const res = yield call(
      API.endpoints.registries.payRegistry,
      payload.id,
      payload.payment
    );

    if (res.data) {
      all([
        yield put(actions.set("loading", false)),
        yield put(notifications.show("success", "Pago exitoso")),
        yield call(getRegistriesByClient, {
          payload: { id: payload.clientId },
        }),
        yield put(clientActions.getBalance(payload.clientId)),
      ]);
    } else {
      const message =
        "Revisar que el monto sea menor o igual al monto pendiente";
      yield put(actions.set("error", message));
      yield put(notifications.show("error", message));
    }
  } catch (error) {
    let message = error.message;
    if (error.response && error.response.data)
      message = error.response.data.message;

    yield put(actions.set("error", message));
    yield put(notifications.show("error", message));
  }
}

function* changeDate({ payload }) {
  try {
    const res = yield call(
      API.endpoints.registries.changeRegistryDate,
      payload.id,
      payload.newDate
    );

    if (res.data) {
      all([
        yield put(notifications.show("success", "Cambio de fecha exitoso")),
        yield call(getRegistriesByClient, {
          payload: { id: payload.clientId },
        }),
      ]);
    } else {
      const message =
        "Revisar que el monto sea menor o igual al monto pendiente";
      yield put(actions.set("error", message));
      yield put(notifications.show("error", message));
    }
  } catch (error) {
    let message = error.message;
    if (error.response && error.response.data)
      message = error.response.data.message;

    yield put(actions.set("error", message));
    yield put(notifications.show("error", message));
  }
}

function* deleteRegistry({ payload }) {
  try {
    const res = yield call(API.endpoints.registries.deletePath, `/${payload.id}`);
    
    if(res.data) {
      const client = yield select(s => s.client.selected);
      yield put(notifications.show("success", "Actividad eliminada"));
      yield call(getRegistriesByClient, { payload: { id: client.id } });
      yield put(clientActions.getBalance(client.id));
    }

  } catch(error) {
    let message = error.message;
    if (error.response && error.response.data)
      message = error.response.data.message;

    yield put(actions.set("error", message));
    yield put(notifications.show("error", message));
  }
}

export default function* index() {
  yield takeLatest(actions.CREATE, create);
  yield takeLatest(actions.UPDATE, update);
  yield takeLatest(actions.PAY, pay);
  yield takeLatest(actions.CHANGE_DATE, changeDate);
  yield takeLatest(actions.GET_BY_CLIENT, getRegistriesByClient);
  yield takeEvery(actions.DELETE, deleteRegistry);
}
