import { makeStyles } from "@material-ui/core";

const drawerWidth = 355;
const profileDrawerWidth = 380;

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  // drawer: {
  //   width: drawerWidth,
  //   flexShrink: 0,
  // },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.primary.main
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: "#f7f7f7",
  },
  logo: {
    width: 'auto',
    height: 200,
    margin: '65px auto 40px',
  },
  iconContainer: {
    minWidth: 'auto',
    margin: '0 15px',
  },
  icon: {
    color: 'rgba(255,255,255,0.6)',
    fontSize: 28
  },
  text: {
    fontSize: 22,
    color: 'rgba(255,255,255,0.6)',
    fontWeight: 400
  },
  subtext: {
    fontSize: 18,
    color: 'rgba(255,255,255,0.6)',
    fontWeight: 400,
    marginLeft: 30
  },
  active: {
    backgroundColor: theme.palette.action.selected,
    '& $text': {
      color: theme.palette.secondary.main,
      fontWeight: 600
    },
    '& $icon': {
      color: theme.palette.secondary.main,
    }
  },
  profileDrawer: {
    width: profileDrawerWidth,
    paddingTop: 30,
    backgroundColor: theme.palette.primary.main
  },
  userAvatar: {
    height: 50,
    width: 50,
  },
  backIcon: {
    color: "white",
    // height: 40,
    // width: 45
  },
  profileNameText: {
    display: 'inline-block',
    fontWeight: "bold",
    color: 'white',
    letterSpacing: 1,
    verticalAlign: 'middle',
  },
  primaryText: {
    color: 'white'
  },
  secondaryText: {
    color: "gray"
  },
  profileDataSection: {
    marginTop: 20,
    paddingRight: 10,
    paddingLeft: 30,
  },
  sectionTitle: {
    color: "white",
    fontWeight: "bold",
    marginBottom: 25,
    letterSpacing: 1,
  },
  addAppointmentSection: {
    marginTop: 50,
    justifyContent: "center"
  },
  appointmentButton: {
    color: "white",
    width: "75%",
    height: 45,
    borderRadius: 9
  },
  spaceTop: {
    marginTop: 10
  },
  rowName: { 
    display: 'flex', 
    padding: '0 0 0 10px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  rowIcons: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
  },
  nestedListItem:{
    paddingLeft: theme.spacing(6),
  },
  settingsIcon:{
    color:'rgba(255,255,255,0.6)',
    height:35,
    width:35
  },
  toolbarContainer:{
    padding:10
  }
}));