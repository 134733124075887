import { takeEvery, put, call } from "redux-saga/effects";
import actions from "./actions";
import * as notifications from "../notifications";
import API from "../api";

const api = new API();
api.createEntities(["activities", "reports"]);

function* getAll({ payload }) {
    try {
        const res = yield call(api.endpoints.activities.getAllPath, `?startDate=${payload.startDate}&endDate=${payload.endDate}`);
        yield put(actions.setActivities(res.data ? res.data : []));

    } catch (error) {
        console.log("Error: ",error)
        let message = error.message;
        if (error.response && error.response.data)
            message = error.response.data.message;
        yield put(actions.set("error", message));
        yield put(notifications.show('error', message));
    }
}

function* getSalesByMethod({ payload }) {
    try {
        const res = yield call(api.endpoints.activities.getAllPath, `/sales/method?startDate=${payload.startDate}&endDate=${payload.endDate}`);
        yield put(actions.setSalesByMethod(res.data));
    } catch (error) {
        let message = error.message;
        if (error.response && error.response.data)
            message = error.response.data.message;
        yield put(actions.setSalesByMethod([]));
        yield put(notifications.show('error', message));
    }
}


export default function* index() {
    yield takeEvery(actions.GET_ALL, getAll);
    yield takeEvery(actions.GET_SALES_BY_METHOD, getSalesByMethod);
}
