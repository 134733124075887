import React, { useState } from 'react';
import {
  Dialog,
  makeStyles,
  DialogActions,
  DialogContent,
  TextField,
  Button,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import { BlockPicker } from 'react-color';
import { colors } from '../../../../utils/index';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 20,
    height: 'auto',
  },
  textField: {
    width: '100%',
  },
  textFieldNotes: {
    width: '100%',
    height: 80,
  },
}));

function CatalogDialogNew({ catalogName, visible, onHide, onSave }) {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [colorPicker, setColorPicker] = useState('#FFFFFF');

  const done = () => {
    if (!name) return;

    if (catalogName === 'appointmentTypes') {
      onSave({ name, color: colorPicker });
    } else {
      onSave({ name });
    }
    onHide();
  };
  const onClosing = () => {
    setName('#FFFFFF');
    setColorPicker('');
    onHide();
  };
  const onColorChange = (color) => {
    setColorPicker(color.hex);
  };
  return (
    <Dialog
      className={classes.container}
      open={visible}
      onClose={onClosing}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Agregar</DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={2}>
          <Grid item container>
            <TextField
              value={name}
              fullWidth
              autoFocus
              id="name"
              label="Nombre"
              variant="outlined"
              onChange={(ev) => setName(ev.target.value)}
            />
          </Grid>
          {catalogName === 'appointmentTypes' ? (
            <Grid item container justifyContent="center" alignItems="center">
              <Grid item xs={6}>
                <BlockPicker
                  width="100%"
                  color={colorPicker}
                  {...{ colors }}
                  onChangeComplete={onColorChange}
                  onChange={(color, event) => setColorPicker(color.hex)}
                />
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onHide} color="primary" disableElevation>
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disableElevation
          onClick={done}
        >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CatalogDialogNew;
