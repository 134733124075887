const actions = {
  GET_ALL: 'CLIENT/GET_ALL',
  GET_BY_ID: 'CLIENT/GET_BY_ID',
  SEARCH: 'CLIENT/SEARCH',
  CREATE: 'CLIENT/CREATE',
  UPDATE: 'CLIENT/UPDATE',
  REMOVE: 'CLIENT/REMOVE',
  SET: 'CLIENT/SET',
  SHOW_DIALOG: 'CLIENT/SHOW_DIALOG',
  DISTINGUISH: 'CLIENT/DISTINGUISH',
  GET_OBSERVATIONS: 'CLIENT/GET_OBSERVATIONS',
  ADD_OBSERVATION: 'CLIENT/ADD_OBSERVATION',
  REMOVE_OBSERVATION: 'CLIENT/REMOVE_OBSERVATION',
  GET_APPOINTMENTS: 'CLIENT/GET_APPOINTMENTS',
  SELECT_PROFILE: 'CLIENT/SELECT_PROFILE',
  GET_BALANCE: 'CLIENT/GET_BALANCE',
  ADD_TO_BALANCE: 'CLIENT/ADD_TO_BALANCE',
  SET_NEW_BALANCE: 'CLIENT/SET_NEW_BALANCE',
  EXPORT_EXCEL: 'CLIENT/EXPORT_EXCEL',
  GET_NOTES: 'CLIENT/GET_NOTES',
  SAVE_NOTES: 'CLIENT/SAVE_NOTES',
  GET_BIRTHDAY_LIST: 'CLIENT/GET_BIRTHDAY_LIST',

  getAll: (params) => ({
    type: actions.GET_ALL,
    payload: { params },
  }),
  getById: (id) => ({
    type: actions.GET_BY_ID,
    payload: { id },
  }),
  getBirthdayList: (params) => ({
    type: actions.GET_BIRTHDAY_LIST,
    payload: { params },
  }),
  search: (text) => ({
    type: actions.SEARCH,
    payload: { text },
  }),
  create: (client) => ({
    type: actions.CREATE,
    payload: { client },
  }),
  update: (id, client) => ({
    type: actions.UPDATE,
    payload: { id, client },
  }),
  remove: (id) => ({
    type: actions.REMOVE,
    payload: { id },
  }),
  set: (type, data) => ({
    type: actions.SET,
    payload: { type, data },
  }),
  showDialog: (open, data) => ({
    type: actions.SHOW_DIALOG,
    payload: { open, data },
  }),
  distinguish: (id, value) => ({
    type: actions.DISTINGUISH,
    payload: { id, value },
  }),
  getObservations: (id) => ({
    type: actions.GET_OBSERVATIONS,
    payload: { id },
  }),
  addObservation: (clientId, observationId, comments) => ({
    type: actions.ADD_OBSERVATION,
    payload: { clientId, observationId, comments },
  }),
  removeObservation: (clientId, observationId) => ({
    type: actions.REMOVE_OBSERVATION,
    payload: { clientId, observationId },
  }),
  getAppointments: (clientId) => ({
    type: actions.GET_APPOINTMENTS,
    payload: { clientId },
  }),
  getBalance: (clientId) => ({
    type: actions.GET_BALANCE,
    payload: { clientId },
  }),
  addQuantityToBalance: (clientId, quantity) => ({
    type: actions.ADD_TO_BALANCE,
    payload: { clientId, quantity },
  }),
  setNewBalance: (quantity) => ({
    type: actions.SET_NEW_BALANCE,
    payload: { quantity },
  }),
  selectProfileById: (id) => ({
    type: actions.SELECT_PROFILE,
    payload: { id },
  }),
  exportToExcel: () => ({
    type: actions.EXPORT_EXCEL,
  }),
  getNotes: (clientId) => ({
    type: actions.GET_NOTES,
    payload: { clientId },
  }),
  saveNotes: (notes) => ({
    type: actions.SAVE_NOTES,
    payload: { notes },
  }),
};

export default actions;
