import actions from "./actions";

const initialState = {
    data: [],
    isLoading: false,
    salesByMethod: {
        loading: false,
        data: []
    },
}

const reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch(type) {

        case actions.GET_ALL: {
            return {
              ...state,
              data: [],
              isLoading: true,
            };
        }

        case actions.GET_SALES_BY_METHOD: {
            return {
                ...state,
                salesByMethod: {
                    data: [],
                    isLoading: true
                }
            }
        }

        case actions.SET_SALES_BY_METHOD: {
            return {
                ...state,
                salesByMethod: {
                    data: payload.data,
                    isLoading: false
                }
            }
        }

        case actions.SET_ACTIVITIES: {
            return {
                ...state,
                isLoading: false,
                data: payload.data
            }
        }

        case actions.SET: {
            return {
              ...state,
              isLoading: false,
              [payload.type]: payload.data,
            };
        }

        default:
            return state;
    }
}

export default reducer;