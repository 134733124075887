import { put, takeEvery, call } from "redux-saga/effects";
import actions from "./actions";
import * as notifications from "../notifications";
import { history } from "../store";
import api from "../api";

const API = new api();
API.createEntity("auth");
API.createEntity("users");

function* login({ payload }) {
    try {
        const res = yield call(API.endpoints.auth.postPath, "/login", payload.credentials);
        if (res.data) {
            localStorage.setItem('user_mp', res.data);
            localStorage.setItem('token', res.data.token);
            localStorage.setItem('id', res.data.id);
            yield put(actions.set("user", res.data));
            yield history.push('/main');
        }
    } catch (error) {
        let message = error.message;
        if (error.response && error.response.data)
            message = error.response.data.message
        yield put(actions.set("error", message));
        yield put(notifications.show('error', message));
    }
}

function* logout() {
    try {
        localStorage.clear();
        yield history.push("/login");

    } catch (error) {
        yield put(actions.set("error", error))
    }
}
function* changePassword({payload}) {
    const userId = localStorage.getItem("id");
    try {
        const res = yield call(API.endpoints.users.changePassword, userId, payload.data);
        if (res.data) {
            yield put(notifications.show('success', 'Contraseña cambiada'))
        }
    } catch (error) {
        let message = error.message;
        if (error.response && error.response.data)
            message = error.response.data.message
        yield put(actions.set("error", message));
        yield put(notifications.show('error', message));
    }
}

export default function* index() {
    yield takeEvery(actions.LOGIN, login);
    yield takeEvery(actions.LOGOUT, logout);
    yield takeEvery(actions.CHANGE_PASSWORD, changePassword);
}