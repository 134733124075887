import React, { useState, useEffect } from "react";
import {
  Dialog,
  makeStyles,
  DialogActions,
  DialogContent,
  TextField,
  Button,
  DialogTitle,
} from "@material-ui/core";
import { capitalize } from "../../../utils";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 20,
    height: "auto",
  },
  textField: {
    width: "100%",
  },
  textFieldNotes: {
    width: "100%",
    height: 80,
  },
}));

function DialogObservations({ data, visible, onHide, onSave, onRemove }) {
  const classes = useStyles();
  const [text, setText] = useState("");

  useEffect(() => {
    setText(data.observations || "");
  }, [data]);

  const done = () => {
    onSave(text);
    onHide();
  };

  const remove = () => {
    onRemove();
    onHide();
  };

  return (
    <Dialog
      className={classes.container}
      open={visible}
      onClose={onHide}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>{data.title}</DialogTitle>
      <DialogContent>
        <TextField
          value={text}
          fullWidth
          id="observations-text"
          label="Observaciones"
          multiline
          rows={4}
          variant="outlined"
          onChange={(ev) => setText(capitalize(ev.target.value))}
        />
      </DialogContent>
      <DialogActions style={{ justifyContent: "space-between" }}>
        {data.checked && (
          <Button onClick={remove} color="secondary" disableElevation>
            Quitar
          </Button>
        )}
        {!data.checked && <div />}
        <div>
          <Button onClick={onHide} color="primary" disableElevation>
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="secondary"
            disableElevation
            onClick={done}
          >
            Guardar
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default DialogObservations;
