import {combineReducers} from "redux";
import {connectRouter} from "connected-react-router";
import * as notifications from "./notifications";
import auth from "./user/reducer";
import client from "./client/reducer";
import settings from "./settings/reducer";
import catalogs from "./catalogs/reducer";
import appointments from "./appointments/reducer";
import profile from "./profile/reducer";
import products from "./products/reducer";
import registries from "./registry/reducer";
import report from "./reports/reducer";
import activities from "./activities/reducer";

export default history => combineReducers({
    router: connectRouter(history),
    notifications: notifications.reducer,
    auth,
    client,
    settings,
    catalogs,
    appointments,
    profile,
    products,
    registries,
    report,
    activities,
});