import React from 'react';
import { Switch, Route } from "react-router-dom";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import { ThemeProvider } from '@material-ui/styles';
import { ConnectedRouter } from "connected-react-router";
import theme from "../assets/theme";
import { store, persistor, history } from "../redux/store";
import Login from "./auth/Login";
import Main from "./main/Main";
import PrivateRoute from "../components/PrivateRoute";
import Dashboard from './main/dashboard/Dashboard';
import Calendar from './main/calendar/Calendar';
import Profile from "./main/profile/Profile";
import Activity from "./main/activity/Activity";
import Clients from "./main/clients/Clients";
import CatalogAdministration from "./main/catalogAdministration/CatalogAdministration";

import Products from "./main/products/Products";

function App() {
	return (
		<ThemeProvider theme={theme}>
			<Provider store={store}>
				<PersistGate persistor={persistor}>
					<ConnectedRouter history={history}>
						<Switch>
							<Route path="/login" component={Login} />
							{/* <PrivateRoute exact path={["/", "/main"]} component={Main} /> */}
							<Main>
								<PrivateRoute exact path={["/", "/main"]} component={Dashboard} />
								<PrivateRoute exact path="/main/calendar" component={Calendar} />
								<PrivateRoute exact path="/main/calendar/:clientId" component={Calendar} />
								<PrivateRoute exact path="/main/profile" component={Profile} />
								<PrivateRoute exact path="/main/clients" component={Clients} />
								<PrivateRoute exact path="/main/catalogs/:catalogName" component={CatalogAdministration} />
								<PrivateRoute exact path="/main/products" component={Products} />
								<PrivateRoute exact path="/main/activity" component={Activity} />
							</Main>
						</Switch>
					</ConnectedRouter>
				</PersistGate>
			</Provider>
		</ThemeProvider>
	);
}

export default App;
