import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Button,
  AppBar,
  Toolbar,
  CardHeader,
  CardContent,
  makeStyles,
  CardActions,
  ListItem as ListItemMaterial,
  ListItemText,
  ListItemSecondaryAction,
  List,
  Divider,
  CircularProgress,
  Tooltip,
  ButtonBase,
} from '@material-ui/core';
import Card from '../../../../components/Card';
import { currency, getPrettyDate, getLongDate } from '../../../../utils';
import TimelineIcon from '@material-ui/icons/Timeline';
import MoneyIcon from '@material-ui/icons/AttachMoneyRounded';
// import DialogRegistry from "../../shared/DialogRegistry";
import DialogRegistry from '../../shared/DialogRegistryV2';
import productsActions from '../../../../redux/products/actions';
import registryActions from '../../../../redux/registry/actions';
import catalogsActions from '../../../../redux/catalogs/actions';

import * as notifyActions from '../../../../redux/notifications';
import { useDispatch, useSelector } from 'react-redux';
import RegistryService from '../service/RegistryService';
import EditDateRegistryDialog from './EditDateRegistryDialog';

function getProductName(name, quantity) {
  return quantity > 1 ? name + ' (x' + quantity + ')' : name;
}

function ListItem({ classes, item }) {
  return (
    <ListItemMaterial>
      <ListItemText
        primary={getProductName(item.name, item.quantity)}
        secondary={item.comments}
        style={{ margin: 0 }}
      />
      <ListItemSecondaryAction>
        <Typography variant="caption" className={classes.itemRowPrice}>
          {currency(item.price)}
        </Typography>
      </ListItemSecondaryAction>
    </ListItemMaterial>
  );
}

function ActivityRegistry() {
  const classes = useStyles();
  const [dialogRegistry, setDialogRegistry] = useState({
    open: false,
    registry: {},
    isNew: true,
    index: null,
  });
  const [dialogPayment, setDialogPayment] = useState({
    open: false,
    registryId: null,
    total: 0,
  });
  const [dialogDate, setDialogDate] = useState({
    open: false,
    data: null,
  });

  function showPayDialog(id, total) {
    setDialogPayment({ open: true, registryId: id, total });
  }

  function hidePayDialog() {
    setDialogPayment({ open: false, registryId: null });
  }

  function showDateDialog(ev, item) {
    ev.stopPropagation();
    console.log(item);
    setDialogDate({ open: true, data: item });
  }

  function hideDateDialog() {
    setDialogDate({ open: false, data: null });
  }

  function onSavePayDialog(payment) {
    pay(dialogPayment.registryId, payment);
    hidePayDialog();
  }

  function handleDialogRegistry(isNew, registry, index) {
    setDialogRegistry({ open: true, registry: registry, isNew: isNew, index });
  }

  const products = useSelector((state) => state.products.data);
  const profile = useSelector((state) => state.client.selected);
  const loading = useSelector((state) => state.registries.loading);
  const created = useSelector((state) => state.registries.created);
  const registries = useSelector((state) => state.registries.data);
  const balance = useSelector((state) => state.client.balance);
  const employees = useSelector((state) => state.catalogs.employees);

  const dispatch = useDispatch();
  const getProducts = () => dispatch(productsActions.getAllActives());
  const getEmployees = () => dispatch(catalogsActions.getEmployees());
  const create = (data) => dispatch(registryActions.create(profile.id, data));
  const pay = (id, payment) =>
    dispatch(registryActions.pay(id, payment, profile.id));
  const changeDate = (id, newDate) =>
    dispatch(registryActions.changeDate(id, newDate, profile.id));
  const update = (id, data) =>
    dispatch(registryActions.update(id, profile.id, data));
  const getRegistriesByClient = (id) =>
    dispatch(registryActions.getRegistriesByClient(id));
  const notify = (type, message) => dispatch(notifyActions.show(type, message));

  useEffect(() => {
    if (profile) {
      getProducts(profile.id);
      getEmployees();
      getRegistriesByClient(profile.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  useEffect(() => {
    if (created) setDialogRegistry({ open: false });
  }, [created]);

  const onItemSelect = (item, index) =>
    handleDialogRegistry(false, item, index);

  const onRegistryDateChange = (id, newDate) => {
    changeDate(id, newDate);
    hideDateDialog();
  };

  return (
    <Grid item xs={7}>
      <DialogRegistry
        open={dialogRegistry.open}
        registry={dialogRegistry.registry}
        isNew={dialogRegistry.isNew}
        index={dialogRegistry.index}
        {...{ products, employees, create, update, loading, balance, notify }}
        handleOpen={() => setDialogRegistry({ open: false })}
      />

      <EditDateRegistryDialog
        visible={dialogDate.open}
        data={dialogDate.data}
        onHide={hideDateDialog}
        onSave={onRegistryDateChange}
      />

      <RegistryService.PaymentDialog
        visible={dialogPayment.open}
        onHide={hidePayDialog}
        onSave={onSavePayDialog}
        total={dialogPayment.total}
        {...{ classes, notify }}
      />

      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar variant="dense" className={classes.appbar}>
          <TimelineIcon className={classes.headerIcon} />
          <Typography
            variant="h6"
            color="inherit"
            className={classes.appbartitle}
          >
            Actividad
          </Typography>

          <Button
            variant="contained"
            color="secondary"
            disableElevation
            className={classes.addNewBtn}
            onClick={() => handleDialogRegistry(true, null, null)}
          >
            Agregar
          </Button>
        </Toolbar>
      </AppBar>

      {registries &&
        registries.length > 0 &&
        registries.map((registry, index) => (
          <Card
            style={{ marginBottom: 15, padding: 0 }}
            key={index}
            onClick={() => onItemSelect(registry, index)}
          >
            <CardHeader
              title={registry.type}
              titleTypographyProps={{
                style: { fontSize: '1.2rem' },
              }}
              subheader={registry.comments}
              subheaderTypographyProps={{
                style: {
                  whiteSpace: 'pre-line',
                  marginTop: 10,
                  textTransform: 'capitalize',
                },
              }}
              style={{
                padding: '16px 20px 0',
              }}
              action={
                registry.outstandingBalance > 0 && (
                  <div className={classes.wrapper}>
                    <Button
                      color="secondary"
                      disabled={loading}
                      startIcon={<MoneyIcon />}
                      onClick={(ev) => {
                        ev.stopPropagation();
                        showPayDialog(registry.id);
                      }}
                    >
                      Pagar
                    </Button>
                    {loading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                )
              }
            />
            <CardContent className={classes.cardContent}>
              {/* <div>
								<Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>{registry.comments}</Typography>
						</div> */}
              <div>
                {/* <ul className={classes.list}> */}
                <List dense={true} className={classes.list}>
                  {registry.products.map((item, index) => (
                    <ListItem key={index} {...{ classes, item }} />
                  ))}
                  {registry.amount > 0 && (
                    <ListItem
                      {...{ classes }}
                      item={{
                        name: 'Costo consulta',
                        quantity: 1,
                        price: registry.amount,
                      }}
                    />
                  )}
                </List>
                {/* </ul> */}
              </div>
            </CardContent>
            <Divider />
            <CardActions className={classes.cardBottom}>
              <ButtonBase
                style={{ marginLeft: 15 }}
                onClick={(ev) => showDateDialog(ev, registry)}
              >
                <Tooltip title={getLongDate(registry.createdAt)}>
                  <Typography variant="caption" style={{ padding: '0 5px' }}>
                    {getPrettyDate(registry.createdAt)}
                  </Typography>
                </Tooltip>
              </ButtonBase>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                }}
              >
                {registry.discount > 0 && (
                  <div className={classes.totalRow}>
                    <Typography variant="caption" align="right">
                      Descuento
                    </Typography>
                    <Typography variant="body1" align="right">
                      -{currency(registry.discount)}
                    </Typography>
                  </div>
                )}
                <div className={classes.totalRow}>
                  <Typography variant="caption" align="right">
                    Total
                  </Typography>
                  <Typography variant="body1" align="right">
                    {currency(registry.total)}
                  </Typography>
                </div>
                {registry.outstandingBalance > 0 && (
                  <div className={classes.totalRow}>
                    <Typography variant="caption" align="right">
                      Monto Pendiente
                    </Typography>
                    <Typography variant="body1" align="right" color="secondary">
                      {currency(registry.outstandingBalance)}
                    </Typography>
                  </div>
                )}
              </div>
            </CardActions>
          </Card>
        ))}
      {(!registries || registries.length === 0) && (
        <Card style={{ textAlign: 'center' }}>
          <Typography variant="caption">
            No hay actividad registrada aún
          </Typography>
        </Card>
      )}
    </Grid>
  );
}

export default ActivityRegistry;

export const useStyles = makeStyles((theme) => ({
  list: {
    marginTop: 0,
    paddingLeft: 15,
    listStyle: 'none',
    '& > li': {
      position: 'relative',
      '&::before': {
        content: '""',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.secondary.main,
        borderRadius: 100,
        width: 8,
        height: 8,
        position: 'absolute',
        left: -4,
        top: '42%',
      },
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  itemRowTitle: {
    width: '90%',
    borderBottom: '1px dotted #ddd',
    lineHeight: '8px',
    '& > span': {
      backgroundColor: 'white',
      paddingRight: 4,
    },
  },
  itemRowPrice: {
    width: '10%',
    textAlign: 'right',
  },
  headerIcon: {
    height: 30,
    width: 30,
    marginRight: 15,
  },
  appbar: {
    padding: 0,
    marginBottom: 15,
  },
  appbartitle: {
    flexGrow: 1,
    fontWeight: 600,
    fontSize: 22,
  },
  spaceBottom: {
    marginBottom: 25,
  },
  addNewBtn: {
    borderRadius: 8,
  },
  cardContent: {
    // paddingTop: 0,
    // paddingBottom: 0,
    padding: 0,
  },
  cardBottom: {
    justifyContent: 'space-between',
    padding: '4px 10px 4px 0',
    alignItems: 'flex-end',
  },
  totalRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '&>span': {
      marginRight: 10,
    },
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));
