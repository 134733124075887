import React from "react";
import { store } from "../redux/store";
import { Redirect, Route } from "react-router-dom";

let sesion = store.getState();
store.subscribe(() => {
    sesion = store.getState();
});

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props => sesion.auth.user.id
            ? (<Component {...props} />)
            : (<Redirect to={{ pathname: "/login", state: { from: props.location } }} />)
        }
    />
);

export default PrivateRoute;