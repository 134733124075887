import { put, takeLatest, call, all } from 'redux-saga/effects';
import actions from './actions';
import * as notifications from '../notifications';
import clientActions from '../client/actions';
import api from '../api';
import moment from 'moment';

const API = new api();
API.createEntity('appointments');

function* getAll({ payload }) {
  const { type, date } = payload;
  const paramDate = moment(date).format('YYYY-MM-DD');
  try {
    const res = yield call(
      API.endpoints.appointments.getAllPath,
      `?type=${type}&date=${paramDate}`
    );
    yield put(actions.set('data', res.data));
  } catch (error) {
    console.log(error);
    let message = error.message;
    if (error.response && error.response.data)
      message = error.response.data.message;
    yield put(actions.set('error', message));
  }
}

function* create({ payload }) {
  try {
    const res = yield call(
      API.endpoints.appointments.post,
      payload.appointment
    );
    all([
      yield put(actions.set('created', res.data)),
      yield put(notifications.show('success', 'Nueva cita creada')),
      yield getAll({ payload: { type: 'month', date: res.data.start } }),
      yield put(clientActions.getAppointments(payload.appointment.clientId)),
    ]);
  } catch (error) {
    console.log(error);
    let message = error.message;
    if (error.response && error.response.data)
      message = error.response.data.message;
    yield put(actions.set('error', message));
    yield put(notifications.show('error', message));
  }
}

function* update({ payload }) {
  try {
    const res = yield call(
      API.endpoints.appointments.update,
      payload.appointment.id,
      payload.appointment
    );
    all([
      yield put(actions.set('created', res.data)),
      yield put(notifications.show('success', 'Cita actualizada')),
      yield getAll({ payload: { type: 'month', date: res.data.start } }),
      yield put(clientActions.getAppointments(payload.appointment.clientId)),
    ]);
  } catch (error) {
    console.log(error);
    let message = error.message;
    if (error.response && error.response.data)
      message = error.response.data.message;
    yield put(actions.set('error', message));
    yield put(notifications.show('error', message));
  }
}

function* cancel({ payload }) {
  try {
    yield call(API.endpoints.appointments.delete, payload.id);

    let todoList = [
      yield put(notifications.show('success', 'Cita cancelada')),
      yield getAll({ payload: { type: 'month', date: new Date() } }),
    ];

    if (payload.clientId)
      todoList.push(yield put(clientActions.getAppointments(payload.clientId)));

    all(todoList);
  } catch (error) {
    console.log(error);
    let message = error.message;
    if (error.response && error.response.data)
      message = error.response.data.message;
    yield put(actions.set('error', message));
    yield put(notifications.show('error', message));
  } finally {
    yield put(actions.set('canceling', false));
  }
}

export default function* index() {
  yield takeLatest(actions.GET_ALL, getAll);
  yield takeLatest(actions.CREATE, create);
  yield takeLatest(actions.UPDATE, update);
  yield takeLatest(actions.CANCEL, cancel);
}
