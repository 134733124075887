import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@material-ui/core";

function ConfirmationDialog({ open, title, message, onClose, onDelete, buttonMessage }) {
    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle disableTypography>
                <Typography variant="h6"> {title} </Typography>
            </DialogTitle>
            <DialogContent>
                {message && (
                    <DialogContentText >
                        {message}
                    </DialogContentText>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    Volver
                </Button>
                <Button
                    onClick={() => {
                        onDelete();
                        onClose();
                    }}
                    color="primary"
                    variant="contained"
                    disableElevation
                >
                    {buttonMessage ? buttonMessage : 'Sí, eliminar'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmationDialog;