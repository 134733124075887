import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import Card from "../../../../components/Card";
import { CardHeader, CardContent } from '@material-ui/core';

const DemographicChart = ({demographic}) => {

  const [categories, setCategories] = useState([]);
  const [series, setSeries] = useState([]);

  useEffect(() => {
    const {data} = demographic;
    const categories = [];
    const series = [];
    
    if (data) {
      data.forEach(item => {
        categories.push(item.label);
        series.push(item.total);
      });

      setCategories(categories);
      setSeries(series);
    }

  }, [demographic]);

  const defaults = {
    options: {
      chart: {
        stacked: true,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: true
        }
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0
          }
        }
      }],
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      colors: ['#FF5F5F', '#1E1E1E'],

      xaxis: {
        categories: categories,
      },
      legend: {
        position: 'top'
      },
      fill: {
        opacity: 1
      }
    },
    series: [{
      name: 'Paceintes',
      data: series
    }]
  };

  return (
    <Card style={{ padding: 0, borderRadius: 3 }}>
      <CardHeader title='Demográfico' subheader='Paceintes por rango de edad' />
      <CardContent>
        <ReactApexChart
          options={defaults.options}
          series={defaults.series}
          type="bar"
          width="100%"
          height="500"
        />
      </CardContent>
    </Card>
  );
}

export default DemographicChart;