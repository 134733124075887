import actions from "./actions";

const initialState = {
    dialogObservations: {
        data: {},
        visible: false,
    },
}

const reducer = (state = initialState, action) => {
    const { payload, type } = action;
    switch (type) {

        case actions.SHOW_DIALOG: {
            return {
                ...state,
                dialogObservations: {
                    visible: true,
                    data: payload.data,
                },
            };
        }
        case actions.HIDE_DIALOG: {
            return {
                ...state,
                dialogObservations: {
                    visible: false,
                    data: {},
                },
            };
        }

        default:
            return state;
    }
}

export default reducer;