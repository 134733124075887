import actions from "./actions";

const initialState = {
    data: [],
    list: [],
    loading: false,
    creating: false,
    error: null,
}

const reducer = (state = initialState, action) => {
    const { payload, type } = action;
    switch (type) {

        case actions.GET_ALL_ACTIVES: {
            return {
                ...state,
                loading: true,
                error: null,
                data: [],
            }
        }
        case actions.GET_ALL: {
            return {
                ...state,
                loading: true,
                creating: false,
                error: null,
                list: [],
            }
        }
        case actions.SET: {
            return {
                ...state,
                loading: false,
                data: [],
                error: null,
                [payload.type]: payload.data,
            };
        }
        case actions.CREATE:
        case actions.UPDATE: {
            return {
                ...state,
                creating: true,
            };
        }

        default:
            return state;
    }
}

export default reducer;