import React from 'react';
import { MoreVert, Done } from "@material-ui/icons";

const EventItem = ({ width = "100%", event, classes }) => (
  <div
    className={classes.event}
    style={{ backgroundColor: event.color, maxWidth: width }}
  >
    {/* <small>{event.title}</small> */}
    {event.confirmed && <Done className={classes.doneIcon} />}
    <div
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      {event.title}
    </div>
    <MoreVert className={classes.eventIcon} />
  </div>
);

export default EventItem;