import React from 'react';
import { Grid } from '@material-ui/core';
import InsightCard from '../../dashboard/components/InsightCard';
import { currency } from 'src/utils';
import {
  CreditCardTwoTone,
  MonetizationOnTwoTone,
  AccountBalanceTwoTone,
} from '@material-ui/icons';

import { useSelector } from 'react-redux';

function TotalCard() {
  const salesByMethod = useSelector((state) => state.activities.salesByMethod);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ marginBottom: 30 }}
    >
      {salesByMethod.data.map((item) => {
        let icon;
        switch (item.label) {
          case 'Efectivo/Cheque':
            icon = <MonetizationOnTwoTone />;
            break;
          case 'Transferencia':
            icon = <AccountBalanceTwoTone />;
            break;
          case 'Tarjeta':
            icon = <CreditCardTwoTone />;
            break;
          default:
            icon = <></>;
            break;
        }
        return (
          <Grid key={item.label} item xs={3} style={{ padding: '0px 20px' }}>
            <InsightCard
              title={item.label}
              quantity={currency(item.total)}
              icon={icon}
              color={item.label === 'Saldo Pendiente' && '#EE4B2B'}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}

export default TotalCard;
