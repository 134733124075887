import { createTheme } from '@material-ui/core/styles';
import tinycolor from 'tinycolor2';

const primary = '#1E1E1E';
const secondary = '#FF5F5F';

const lightenRate = 7.5;
const darkenRate = 15;

const theme = createTheme({
  typography: {
    typography: {
      fontFamily: 'Baloo Tamma 2',
    },
  },
  palette: {
    type: 'light',
    primary: {
      main: primary,
      light: tinycolor(primary).lighten(lightenRate).toHexString(),
      dark: tinycolor(primary).darken(darkenRate).toHexString(),
    },
    secondary: {
      main: secondary,
      light: tinycolor(secondary).lighten(lightenRate).toHexString(),
      dark: tinycolor(secondary).darken(darkenRate).toHexString(),
      contrastText: '#FFFFFF',
    },
  },
  overrides: {
    MuiTableCell: {
      sizeSmall: {
        padding: '4px 10px',
      },
    },
  },
});

export default theme;
