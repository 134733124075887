import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
  TextField,
  Grid,
  Avatar,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { isNumber, validateEmail } from "../../../utils";
import AutocompletePeopleRecommend from "../../../components/AutocompletePeopleRecommend";
import DialogRemoveClientConfirmation from "./DialogRemoveClientConfirmation";
require("moment/locale/es.js");

export default function ClientDialog({
  visible,
  onHide,
  onSave,
  onEdit,
  onRemove,
  data,
}) {
  const classes = useStyles();
  const [client, setClient] = useState({});
  const [validation, setValidation] = useState({
    isValid: false,
    name: data.name ? "valid" : "incomplete",
    phone: "incomplete",
    email: "incomplete",
    birthday: "valid",
  });
  const [visibleConfirmation, setVisibleConfirmation] = useState(false);

  const showConfirm = () => setVisibleConfirmation(true);
  const hideConfirm = () => setVisibleConfirmation(false);

  const handleChangeText = (name, e) =>
    setClient({ ...client, [name]: e.target.value });

  useEffect(() => {
    if (validation.isValid) {
      let params = {
        name: client.name,
        phone: client.phone,
        emergencyPhone: client.emergencyPhone ? client.emergencyPhone : null,
        email: client.email ? client.email : null,
        recommendedBy: client.recommendedBy ? client.recommendedBy : null,
        birthday: client.birthday ? client.birthday : null,
      };

      if (data.id) {
        onEdit(data.id, params);
      } else {
        onSave(params);
      }
      onHide(false);
    }
  }, [validation]);

  useEffect(() => {
    let date = new Date();
    if (data.birthday) date = moment(data.birthday).toDate();

    setClient({
      name: data.name || "",
      phone: data.phone || "",
      emergencyPhone: data.emergencyPhone || "",
      email: data.email || "",
      birthday: date,
      recommendedBy: data.recommendedBy || "",
    });
  }, [visible, data]);

  const validForm = () => {
    let name = "valid",
      email = "valid",
      phone = "valid",
      emergencyPhone = "valid",
      birthday = "valid";

    if (!client.name || client.name < 3) name = "invalid";
    if (client.email && !validateEmail(client.email)) email = "invalid";
    if (!client.phone || client.phone.length !== 10) phone = "invalid";
    if (client.emergencyPhone && client.emergencyPhone.length !== 10)
      emergencyPhone = "invalid";
    if (!moment(client.birthday).isValid()) birthday = "invalid";

    setValidation({
      ...validation,
      isValid:
        name === "valid" &&
        email === "valid" &&
        phone === "valid" &&
        emergencyPhone === "valid" &&
        birthday === "valid",
      name,
      email,
      phone,
      emergencyPhone,
      birthday,
    });
  };

  const remove = () => {
    onRemove(data.id);
    hideConfirm();
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={visible}
        onClose={() => onHide(false)}
        aria-labelledby="dialog-title"
      >
        <DialogTitle id="dialog-title">
          {data.id ? "Editar" : "Nuevo"} paciente
        </DialogTitle>
        <DialogContent>
          <Grid container direction="row" spacing={1}>
            <Grid item xs={12}>
              <Avatar className={classes.avatar} src={client.profileUrl} />
            </Grid>

            <Grid item xs={12}>
              <TextField
                error={validation.name === "invalid"}
                fullWidth
                id="name"
                margin="dense"
                label="Nombre"
                size="small"
                value={client.name}
                onChange={(ev) => handleChangeText("name", ev)}
                required={true}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  fullWidth
                  margin="dense"
                  autoOk
                  disableFuture
                  inputVariant="outlined"
                  size="small"
                  format="DD-MM-YYYY"
                  id="birthday"
                  invalidDateMessage="Formato de fecha inválido"
                  label="Fecha nacimiento"
                  value={client.birthday}
                  onChange={(date, value) => {
                    setClient({
                      ...client,
                      birthday: moment.isMoment(date) ? date.set({hour: 7}).toDate() : value,
                    });
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "birthday",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                margin="dense"
                error={validation.email === "invalid"}
                helperText={
                  validation.email === "invalid"
                    ? "El correo electrónico es incorrecto"
                    : ""
                }
                id="email"
                label="Correo electrónico"
                size="small"
                onChange={(ev) => handleChangeText("email", ev)}
                value={client.email}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                margin="dense"
                error={validation.phone === "invalid"}
                id="phone"
                label="Teléfono"
                required={true}
                size="small"
                onChange={(ev) => {
                  const { value } = ev.target;

                  if (!value || (isNumber(value) && value.length <= 10))
                    handleChangeText("phone", ev);
                }}
                value={client.phone}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                margin="dense"
                id="emergencyPhone"
                error={validation.emergencyPhone === "invalid"}
                label="Teléfono de emergencias"
                size="small"
                onChange={(ev) => {
                  const { value } = ev.target;

                  if (!value || (isNumber(value) && value.length <= 10))
                    handleChangeText("emergencyPhone", ev);
                }}
                value={client.emergencyPhone}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12}>
              <AutocompletePeopleRecommend
                value={client.recommendedBy}
                setValue={(item) =>
                  setClient({ ...client, recommendedBy: item ? item.name : "" })
                }
              />
              {/* <TextField
              id="recommendedBy"
              fullWidth
              margin="dense"
              size="small"
              label="Recomendado por"
              value={client.recommendedBy}
              onChange={(ev) => handleChangeText('recommendedBy', ev)}
              variant="outlined" /> */}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ justifyContent: "space-between" }}>
          {!data.id && <div/>}
          {data.id && (
            <Button onClick={showConfirm} color="secondary">
              Eliminar
            </Button>
          )}
          <div>
            <Button onClick={() => onHide(false)} color="primary">
              Cerrar
            </Button>
            <Button onClick={validForm} color="secondary">
              {data.id ? "Actualizar" : "Guardar"}
            </Button>
          </div>
        </DialogActions>
      </Dialog>

      <DialogRemoveClientConfirmation
        onAgree={remove}
        visible={visibleConfirmation}
        onHide={hideConfirm}
      />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  avatar: {
    height: theme.spacing(12),
    width: theme.spacing(12),
    margin: "0 auto",
  },
}));
