const actions = {
  GET_ALL: 'APPOINTMENTS/GET_ALL',
  CREATE: 'APPOINTMENTS/CREATE',
  UPDATE: 'APPOINTMENTS/UPDATE',
  CANCEL: 'APPOINTMENTS/CANCEL',
  SET: 'APPOINTMENTS/SET',
  SHOW_DIALOG: 'APPOINTMENTS/SHOW_DIALOG',
  INIT_DIALOG: 'APPOINTMENTS/INIT_DIALOG',

  getAll: (type, date) => ({
    type: actions.GET_ALL,
    payload: { type, date },
  }),
  create: (appointment) => ({
    type: actions.CREATE,
    payload: { appointment },
  }),
  update: (id, appointment) => ({
    type: actions.UPDATE,
    payload: { id, appointment },
  }),
  cancel: (id, clientId) => ({
    type: actions.CANCEL,
    payload: { id, clientId },
  }),
  set: (type, data) => ({
    type: actions.SET,
    payload: { type, data },
  }),
  showDialog: (open, event, isNew, selectedClientId) => ({
    type: actions.SHOW_DIALOG,
    payload: { open, event, isNew, selectedClientId },
  }),
  initDialog: () => ({
    type: actions.INIT_DIALOG,
  }),
};

export default actions;
