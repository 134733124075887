import React from 'react';
import Card from "../../../../components/Card";
import { CardHeader, CardContent, Grid } from '@material-ui/core';

const FillChart = ({ fill }) => {

  return (
    <Grid item xs={12}>
      <Card style={{ padding: 0, borderRadius: 3 }}>
        <CardHeader
          title='Porcentaje de llenado'
          subheader="Representa la cantidad del campo llenado"
        />
        <CardContent>
          <ul>
            <li>Fecha nacimiento: {fill.data.birthday}%</li>
            <li>Teléfono: {fill.data.phone}%</li>
            <li>Email: {fill.data.email}%</li>
          </ul>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default FillChart;