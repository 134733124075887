import React from 'react';
import CurrencyFormat from 'react-currency-format';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import 'moment/min/moment-with-locales';
import 'moment/locale/es-us';

moment.locale('es-us');

let numberFormatUS = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const currencyFormat = (value) => {
  return numberFormatUS.format(value || 0.0) || '';
};

export const isNull = (value, toReturn) => {
  return value !== null && value !== undefined ? value : toReturn;
};

export const currency = (val) => (
  <CurrencyFormat
    value={parseFloat(val ? val : 0)}
    decimalScale={2}
    fixedDecimalScale={true}
    displayType={'text'}
    thousandSeparator={true}
    prefix={'$'}
    style={{ verticalAlign: 'middle' }}
  />
);

export const NumberFormatCustom = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      prefix="$"
    />
  );
};

export const getMonthName = (date) => {
  var objDate = new Date(date);
  var month = objDate.toLocaleString('es-mx', { month: 'long' });

  return (
    month.charAt(0).toUpperCase() + month.slice(1) + ' ' + objDate.getFullYear()
  );
};

export const isDefaultDate = (value, toReturn) => {
  return value !== null && value !== undefined && value !== '0001/01/01'
    ? value
    : toReturn;
};

export const includesString = (container, value) => {
  const newContainer = container.toLowerCase();
  const newValue = value.toLowerCase();
  return newContainer.includes(newValue);
};

export const getMinutes = (date1, date2) => {
  var diff = (date2.getTime() - date1.getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
};

// export const getRelativeTime = (date) => moment(date).fromNow();

export const getTextDate = (date) => moment(date).calendar();

export const isNumber = (text) => !isNaN(parseInt(text)) && isFinite(text);

export const validateEmail = (email) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLocaleLowerCase());
};

export const getShortName = (fullname) =>
  fullname ? fullname.split(' ').slice(0, -1).join(' ') : fullname;

export const getShortDate = (date) => {
  const dateMoment = moment(date);
  if (dateMoment.isValid()) return dateMoment.format('DD/MM/YYYY');
  return date;
};

export const getRelativeTime = (date) => {
  const dateMoment = moment(date);
  if (dateMoment.isValid()) return dateMoment.fromNow();
  return date;
};

export const getPrettyDate = (date) => {
  const dateMoment = moment(date);
  if (dateMoment.isValid()) return dateMoment.calendar();
  return date;
};

export const getTimeString = (date) => {
  const dateMoment = moment(date);
  if (dateMoment.isValid()) return dateMoment.format('LT');
  return date;
};

export const getLongDate = (date) => {
  const dateMoment = moment(date);
  if (dateMoment.isValid()) return dateMoment.format('LLLL');
  return date;
};

export const round = (num) => Math.round(num * 100) / 100;

// export const capitalize = (text) =>  {
// 	const lastChar = text.substr(text.length - 1);
// 	if(lastChar === " ") return text;

// 	let sentences = [];
// 	for (let s of text.split("\n")) {
// 	  const sentence = s.replace(/\w+/g, _.startCase);
// 	  sentences.push(sentence);
// 	}

// 	if (sentences.length === 0) sentences.push(text.replace(/\w+/g, _.startCase));

// 	return sentences.join("\n");
//   };

export const capitalize = (text) => {
  let sentences = [];
  for (let s of text.split('\n')) {
    let sentence = _upperFirstLetter(s);
    sentences.push(sentence);
  }

  return sentences.join('\n');
};

function _upperFirstLetter(sentence) {
  let re = /(^|[.!?]\s+)([a-z])/g;
  return sentence.replace(re, (m, $1, $2) => $1 + $2.toUpperCase());
}

export const colors = [
  '#1B1B3A',
  '#5300EB',
  '#6A0DAD',
  '#8E94F2',
  '#8895B3',
  '#484A47',
  '#ff00ff',
  '#A74482',
  '#F47373',
  '#FF8A65',
  '#F78DA7',
  '#BFACAA',
  '#A37774',
  '#5B6057',
  '#563635',
  '#7c342b',
  '#7D1538',
  '#e91e63',
  '#D21F3C',
  '#F21B3F',
  '#f44336',
  '#05204A',
  '#3f51b5',
  '#5564eb',
  '#7F7CAF',
  '#0072d8',
  '#2196f3',
  '#03a9f4',
  '#40E0D0',
  '#D3F3EE',
  '#59a4b3',
  '#03B5AA',
  '#555B6E',
  '#0a0a0a',
  '#654321',
  '#ff5722',
  '#B1740F',
  '#ffa500',
  '#ffc107',
  '#aa9b14',
  '#d5bd68',
  '#2a6f33',
  '#009688',
  '#00A86B',
  '#4caf50',
  '#29BF12',
  '#8bc34a',
  '#ccff00',
];
