import axios from 'axios';

class API {
  constructor() {
    this.url = 'https://admin.maguepanini.com/api';
    // this.url = 'http://localhost:3000/api';
    this.endpoints = {};
  }

  /**
   * Create and store a single entity's endpoints
   * @param {A entity Object} entity
   */
  createEntity(entity) {
    this.endpoints[entity] = this.createEndpoints(entity);
  }

  createEntities(arrayOfEntity) {
    arrayOfEntity.forEach(this.createEntity.bind(this));
  }
  /**
   * Create the basic endpoints handlers for CRUD operations
   * @param {A entity Object} entity
   */
  createEndpoints(modulo) {
    var endpoints = {};
    const resourceURL = `${this.url}/${modulo}`;

    endpoints.getAll = () =>
      axios.get(resourceURL, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      });
    endpoints.getAllPath = (path) =>
      axios.get(resourceURL + path, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      });

    endpoints.getOne = (id) =>
      axios.get(`${resourceURL}/${id}`, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      });
    endpoints.getOnePath = (id, path) =>
      axios.get(`${resourceURL + path}/${id}`, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      });

    endpoints.post = (post) =>
      axios.post(resourceURL, post, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      });
    endpoints.postPath = (path, post) =>
      axios.post(resourceURL + path, post, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      });

    endpoints.update = (id, toUpdate) =>
      axios.put(`${resourceURL}/${id}`, toUpdate, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      });
    endpoints.updatePath = (path, id, toUpdate) =>
      axios.put(`${resourceURL + path}/${id}`, toUpdate, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      });

    endpoints.updateNoId = (path, toUpdate) =>
      axios.put(`${resourceURL + path}`, toUpdate, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      });

    endpoints.delete = (id) =>
      axios.delete(`${resourceURL}/${id}`, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      });
    endpoints.deletePath = (path) =>
      axios.delete(resourceURL + path, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      });

    endpoints.getClients = (params) => {
      let url = resourceURL + '?';
      if (params.text) url += `text=${params.text}&`;
      url += `page=${params.page}&limit=${params.limit}`;
      return axios.get(url, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      });
    };
    endpoints.searchClients = (text) =>
      axios.get(`${resourceURL}/multi/search?text=${text}`, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      });

    endpoints.distinguish = (id, value) =>
      axios.put(`${resourceURL}/${id}/distinguish?value=${value}`, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      });

    endpoints.getObservations = (id) =>
      axios.get(`${resourceURL}/${id}/observations`, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      });

    endpoints.getBalance = (id) =>
      axios.get(`${resourceURL}/${id}/balance`, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      });

    // endpoints.addQuantityToBalance = (id, quantity) =>
    endpoints.setNewBalance = (id, quantity) =>
      axios.put(
        `${resourceURL}/${id}/balance`,
        {
          quantity,
        },
        {
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        }
      );

    endpoints.addObservation = (clientId, observationId, comments) =>
      axios.put(
        `${resourceURL}/${clientId}/observations?observation=${observationId}`,
        { comments },
        {
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        }
      );

    endpoints.removeObservation = (clientId, observationId) =>
      axios.delete(
        `${resourceURL}/${clientId}/observations?observation=${observationId}`,
        {
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        }
      );

    endpoints.getAppointmentsByClient = (client) =>
      axios.get(`${resourceURL}?client=${client}`, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      });

    endpoints.changePassword = (userId, payload) =>
      axios.put(`${resourceURL}/${userId}/change-password`, payload, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      });

    endpoints.payRegistry = (id, payment) =>
      axios.put(`${resourceURL}/${id}/pay`, payment, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      });

    endpoints.saveNotes = (id, notes) =>
      axios.put(`${resourceURL}/${id}/notes?notes=${notes}`, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      });

    endpoints.getNotes = (id) =>
      axios.get(`${resourceURL}/${id}/notes`, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      });

    endpoints.changeRegistryDate = (id, newDate) =>
      axios.put(
        `${resourceURL}/${id}/change/date`,
        { newDate },
        {
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        }
      );

    endpoints.exportData = (path, filename) =>
      axios({
        url: resourceURL + path,
        method: 'GET',
        responseType: 'blob',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${filename}.xlsx`);
        document.body.appendChild(link);
        link.click();
      });

    return endpoints;
  }
}

export default API;
