import React from 'react';
import { Grid, Chip, Popover, makeStyles, Typography } from '@material-ui/core';
import { DateRange as CalendarIcon } from '@material-ui/icons';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'moment/locale/es';
import LocaleEs from 'date-fns/locale/es';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '20px 0px',
  },
  icon: {
    fontSize: 16,
  },
  dateRangeText: {
    fontSize: 14,
  },
  button: {
    textTransform: 'capitalize',
    fontSize: 12,
  },
  optionsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));

const DateRangePickerComponent = ({ dateRange, onDateChange }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handlePickerOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePickerClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      className={classes.root}
    >
      <Chip
        variant="default"
        onClick={handlePickerOpen}
        icon={<CalendarIcon className={classes.icon} />}
        label={
          <Typography className={classes.dateRangeText}>
            {' '}
            {moment(dateRange.startDate).format('DD MMMM YYYY')} -{' '}
            {moment(dateRange.endDate).format('DD MMMM YYYY')}{' '}
          </Typography>
        }
      />

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePickerClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <DateRangePicker
          onChange={(item) => {
            onDateChange(item.selection);
            if (item.selection.startDate !== item.selection.endDate)
              handlePickerClose();
          }}
          showSelectionPreview={false}
          moveRangeOnFirstSelection={false}
          showDateDisplay={false}
          showPreview={false}
          months={2}
          ranges={[dateRange]}
          direction="horizontal"
          rangeColors={['#FF5F5F']}
          locale={LocaleEs}
          weekStartsOn={1}
        />
      </Popover>
    </Grid>
  );
};

export default DateRangePickerComponent;
