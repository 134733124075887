import actions from './actions';
import moment from 'moment';

const initialState = {
  data: [],
  loading: false,
  error: null,
  created: false,
  canceling: false,
  dialog: {
    event: null,
    open: false,
    isNew: true,
    selectedClientId: null,
  },
};

const reducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case actions.GET_ALL: {
      return {
        ...state,
        loading: true,
        error: null,
        data: [],
      };
    }
    case actions.CREATE: {
      return {
        ...state,
        loading: true,
        error: null,
        created: false,
      };
    }
    case actions.UPDATE: {
      return {
        ...state,
        loading: true,
        error: null,
        created: false,
      };
    }
    case actions.CANCEL: {
      return {
        ...state,
        canceling: true,
        error: null,
        created: false,
      };
    }
    case actions.SHOW_DIALOG: {
      return {
        ...state,
        dialog: {
          open: payload.open,
          event: payload.event,
          isNew: payload.isNew,
          selectedClientId: payload.selectedClientId,
        },
      };
    }
    case actions.SET: {
      let newState = {
        ...state,
        loading: false,
        error: null,
        [payload.type]: payload.data,
      };

      if (payload.type === 'data') {
        newState[payload.type] = payload.data.map((item) => {
          return {
            ...item,
            start: moment(item.start).toDate(),
            end: moment(item.end).toDate(),
          };
        });
      }
      return newState;
    }
    case actions.INIT_DIALOG: {
      return {
        ...state,
        loading: false,
        error: null,
        created: false,
        canceling: false,
      };
    }

    default:
      return state;
  }
};

export default reducer;
