import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import InsightCard from "./InsightCard";

function Insights({ title, total, percentage, icon }) {
  const classes = useStyles();
  return (
    <Grid item xs={2} className={classes.insight}>
      <InsightCard {...{ title, percentage, icon }} quantity={total} />

      {/* {data.map((v, k) => (
                <Grid key={k} item xs={3} className={classes.insight}>
                    <InsightCard
                        key={k}
                        title={v.title}
                        quantity={v.quantity}
                        percentage={v.percentage}
                    />
                </Grid>
            ))} */}
      {/* <Grid item xs={3} className={classes.insight}>
                <Grid container className={classes.addInsight}>
                    <Typography className={classes.addInsightText}>
                        <AddIcon style={{ fontSize: 18 }} />
                        Añadir insight
                    </Typography>
                </Grid>
            </Grid> */}
    </Grid>
  );
}

export default Insights;

const useStyles = makeStyles((theme) => ({
  insight: {
    // padding: "5px 10px"
  },
  addInsight: {
    border: "1.5px dashed #cccccc",
    maxHeight: 79,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    transition: "all 0.3s",
    "&:hover": {
      border: "1.5px dashed #999999",
      "& svg": {
        color: "#333",
      },
      "& span": {
        color: "#333",
      },
      "& p": {
        color: "#333",
      },
    },
  },
  addInsightText: {
    fontWeight: 600,
    color: "#cccccc",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "all 0.3s",
  },
}));
