import { makeStyles, createTheme } from '@material-ui/core/styles';
import tinycolor from 'tinycolor2';

export const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
    backgroundColor: theme.palette.primary.main,
  },
  layout: {
    width: 'auto',
    display: 'block', // Fix IE11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(600 + theme.spacing(4))]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    backgroundColor: 'transparent',
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    position: 'absolute',
    bottom: 0,
    right: 5,
  },
  logo_container: {
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  logo: {
    // height: "40px",
    width: '230px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    // border: "0px"
  },
  formField: {
    padding: '0px',
  },
  textfield: {
    // border: "#FF5F5F 1px solid",
    width: '100%',
    // color: "#ffffff",
    // padding: 10,
    marginBottom: 15,
  },
  title: {
    color: theme.palette.common.white,
    fontSize: '0.9em',
    padding: '20px 0 5px',
  },
  colorPrimary: {
    color: theme.palette.primary.main,
  },
  wrapper: {
    // margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const primary = '#FF5F5F';
const secondary = '#1E1E1E';

const lightenRate = 7.5;
const darkenRate = 15;

export const theme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: primary,
      light: tinycolor(primary).lighten(lightenRate).toHexString(),
      dark: tinycolor(primary).darken(darkenRate).toHexString(),
    },
    secondary: {
      main: secondary,
      light: tinycolor(secondary).lighten(lightenRate).toHexString(),
      dark: tinycolor(secondary).darken(darkenRate).toHexString(),
      contrastText: '#FFF',
    },
  },
  overrides: {
    MuiInputBase: {
      root: {
        color: '#FFF',
      },
    },
  },
});
