const actions = {    
    SHOW_DIALOG: "PROFILE/SHOW_DIALOG",
    HIDE_DIALOG: "PROFILE/HIDE_DIALOG",

    showDialog: (data) => ({
        type: actions.SHOW_DIALOG,
        payload: { data }
    }),
    hideDialog: () => ({
        type: actions.HIDE_DIALOG,
    }),
};

export default actions;