const actions = {
    GET_REPORT: "REPORTS/GET_REPORT",
    SET: "REPORTS/SET",

    getReport: () => ({
        type: actions.GET_REPORT,
    }),
    set: (type, data) => ({
        type: actions.SET,
        payload: { type, data }
    }),
};

export default actions;