import React, { useState, Fragment } from 'react';

import { capitalize, currency, getPrettyDate } from '../../../../utils';
import {
  TableHead,
  TableRow,
  TableCell,
  AppBar,
  Toolbar,
  IconButton,
  createTheme,
  ThemeProvider,
  FormControl,
  Select,
  MenuItem,
  Button,
  CircularProgress,
  Typography,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  InputLabel,
  Grid,
  Card,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemIcon,
} from '@material-ui/core';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleIcon from '@material-ui/icons/AddCircleOutlineRounded';
import DeleteIcon from '@material-ui/icons/Close';
import RemoveIcon from '@material-ui/icons/Delete';
import theme from '../../../../assets/theme';
import ConfirmationDialog from '../../shared/ConfirmationDialog';

import { useDispatch } from 'react-redux';
import registryActions from '../../../../redux/registry/actions';

const primary = '#fff';
const secondary = '#FF5F5F';

const darkTheme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: primary,
    },
    secondary: {
      main: secondary,
    },
  },
});

const marginTop = {
  marginTop: 20,
};
const totalStyle = {
  padding: 6,
  fontSize: '1.3em',
};
const redStyle = {
  color: theme.palette.secondary.main,
};

const Type = {
  CONSULTA: 'CONSULTA',
  VENTA: 'VENTA',
};

const RegistryService = {
  Type,

  getInitState() {
    return {
      id: null,
      type: '',
      amountPaid: '',
      paymentAmount: '',
      paymentMethod: '',
      isPaymentAdded: false,
      comments: '',
      attendedBy: [],
      discount: '',
      products: [],
      subtotal: 0,
      total: 0,
      outstandingBalance: 0,
      positiveBalance: 0,
    };
  },

  changePriceType(id, priceType, list, cb) {
    const products = list.map((product) => {
      if (product.id === id) {
        product.priceType = priceType;
        product.amount =
          product.quantity *
          (priceType === 'star'
            ? product.priceStar || product.price
            : priceType === 'distributor'
            ? product.priceDistributor || product.price
            : product.price);
      }
      return product;
    });
    cb(products);
  },
  addProduct(newProduct, priceType, list, cb) {
    let exists = false;
    let products = list.map((item) => {
      if (item.id === newProduct.id) {
        item.quantity += 1;
        item.amount =
          item.quantity *
          (priceType === 'star'
            ? item.priceStar || item.price
            : priceType === 'distributor'
            ? item.priceDistributor || item.price
            : item.price);
        exists = true;
      }
      return item;
    });

    if (!exists) {
      const product = {
        ...newProduct,
        quantity: 1,
        comments: '',
        priceType: priceType,
        amount:
          priceType === 'star'
            ? newProduct.priceStar
            : priceType === 'distributor'
            ? newProduct.priceDistributor
            : newProduct.price,
      };

      cb([...list, product]);
    } else {
      cb(products);
    }
  },

  changeQuantityToProduct(id, quantity, priceType, list, cb) {
    quantity = parseInt(quantity);
    if (isNaN(quantity) || quantity < 1) quantity = 1;

    const products = list.map((product) => {
      if (product.id === id) {
        product.quantity = quantity;
        product.amount =
          product.quantity *
          (priceType === 'star'
            ? product.priceStar || product.price
            : priceType === 'distributor'
            ? product.priceDistributor || product.price
            : product.price);
      }

      return product;
    });

    cb(products);
  },

  changeCommentsToProduct(id, comments, list, cb) {
    const products = list.map((product) => {
      if (product.id === id) {
        product.comments = capitalize(comments);
      }

      return product;
    });

    cb(products);
  },

  _calculateSubtotal(products) {
    return products.reduce((total, item) => {
      return (total +=
        item.quantity *
        (item.priceType === 'star'
          ? item.priceStar || item.price
          : item.priceType === 'distributor'
          ? item.priceDistributor || item.price
          : item.price));
    }, 0);
  },

  _calculateOutstandingBalance(data, balance, total) {
    let positiveBalance = 0;
    let outstandingBalance = 0;

    if (data.id) {
      let availableBalance = 0;
      if (data.balanceUsed > 0) {
        availableBalance = data.balanceUsed;
      }

      positiveBalance = availableBalance > total ? total : availableBalance;
    } else if (balance > 0) {
      positiveBalance = balance > total ? total : balance;
    }

    outstandingBalance = total - positiveBalance;
    if (data.isPaymentAdded) {
      outstandingBalance -=
        data.paymentAmount > outstandingBalance
          ? outstandingBalance
          : data.paymentAmount;
    }

    if (data.payments && data.payments.length > 0) {
      let paymentsTotal = data.payments.reduce(
        (total, item) => total + item.amount,
        0
      );
      outstandingBalance -=
        paymentsTotal > outstandingBalance ? outstandingBalance : paymentsTotal;
    }

    return {
      outstandingBalance,
      positiveBalance,
    };
  },

  calculateTotal(data, balance, cb) {
    let total = 0;
    let subtotal = this._calculateSubtotal(data.products);

    let discount = parseFloat(data.discount);
    discount = isNaN(discount) ? 0 : discount;

    total = subtotal - discount;

    const result = this._calculateOutstandingBalance(data, balance, total);

    cb({
      ...data,
      outstandingBalance: result.outstandingBalance,
      positiveBalance: result.positiveBalance,
      subtotal,
      total,
    });
  },

  Toolbar({
    classes,
    loading,
    isNew,
    data,
    onClose,
    onSave,
    setData,
    isEditable,
  }) {
    const dispatch = useDispatch();
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const deleteRegistry = (id) => dispatch(registryActions.deleteRegistry(id));

    function handleDelete() {
      deleteRegistry(data.id);
      onClose();
    }

    return (
      <Fragment>
        <ConfirmationDialog
          open={confirmationOpen}
          title="¿Deseas eliminar esta actividad?"
          message="No habrá manera de recuperar la información"
          onClose={() => setConfirmationOpen(false)}
          onDelete={handleDelete}
        />

        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>

            {/* <Typography variant="h6" className={classes.title}>
            NUEVA
          </Typography> */}

            <div className={classes.title}>
              <ThemeProvider theme={darkTheme}>
                <FormControl required={true} error={!data.type}>
                  <Select
                    value={data.type}
                    onChange={(ev) =>
                      setData({ ...data, type: ev.target.value })
                    }
                    displayEmpty
                    className={classes.selectType}
                    inputProps={{ 'aria-label': 'type' }}
                    readOnly={!isEditable}
                  >
                    <MenuItem value="" disabled>
                      {isNew ? 'Nueva' : 'Editar'}
                    </MenuItem>
                    <MenuItem value={Type.CONSULTA}>{Type.CONSULTA}</MenuItem>
                    <MenuItem value={Type.VENTA}>{Type.VENTA}</MenuItem>
                  </Select>
                </FormControl>
              </ThemeProvider>
            </div>

            {isEditable && (
              <div className={classes.wrapper}>
                {data.id && (
                  <Button
                    variant="contained"
                    disableElevation
                    className={classes.deleteButton}
                    onClick={() => setConfirmationOpen(true)}
                  >
                    <RemoveIcon /> Eliminar
                  </Button>
                )}
                <Button color="inherit" disabled={loading} onClick={onSave}>
                  Guardar
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            )}
          </Toolbar>
        </AppBar>
      </Fragment>
    );
  },

  HeaderProductsTable() {
    return (
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell>Cantidad</TableCell>
          <TableCell>Nombre</TableCell>
          <TableCell>Notas</TableCell>
          <TableCell align="right">Tipo de Precio</TableCell>
          <TableCell align="right">PU</TableCell>
          <TableCell align="right">P. Total</TableCell>
        </TableRow>
      </TableHead>
    );
  },

  EmptyMessage({ products }) {
    return !products || products.length === 0 ? (
      <Typography variant="body1" align="center" style={{ margin: 15 }}>
        No hay productos agregados
      </Typography>
    ) : (
      <></>
    );
  },

  SimpleTableRow({ title, value, type = 'normal' }) {
    let style;
    switch (type) {
      case 'red':
        style = { ...totalStyle, ...redStyle };
        break;
      case 'big':
        style = { ...totalStyle };
        break;
      default:
        style = {};
        break;
    }

    return (
      <TableRow style={{ height: 49 }}>
        <TableCell component="th" scope="row">
          {title}
        </TableCell>
        <TableCell align="right" style={{ ...style }}>
          {currency(value)}
        </TableCell>
      </TableRow>
    );
  },

  TotalsCard({ data, balance, setData, showPayDialog }) {
    const removePayment = () => {
      setData({
        ...data,
        isPaymentAdded: false,
        paymentAmount: '',
        paymentMethod: '',
      });
    };

    return (
      <Grid container alignItems="flex-start" justifyContent="flex-end">
        <Grid item xs={12} md={5} lg={4} xl={3}>
          <Card style={{ ...marginTop }}>
            <List dense={true}>
              <ListItem>
                <ListItemText primary="Subtotal" />
                <ListItemSecondaryAction>
                  <Typography variant="caption">
                    {currency(data.subtotal)}
                  </Typography>
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem>
                <ListItemText primary="Descuento" />
                <ListItemSecondaryAction>
                  <CurrencyTextField
                    fullWidth
                    style={{ margin: 0 }}
                    size="small"
                    variant="outlined"
                    value={data.discount}
                    currencySymbol="$"
                    outputFormat="number"
                    onChange={(event, value) =>
                      setData({ ...data, discount: value })
                    }
                  />
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem>
                <ListItemText primary="Total" />
                <ListItemSecondaryAction>
                  <Typography variant="caption" style={{ ...totalStyle }}>
                    {currency(data.total)}
                  </Typography>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </Card>

          <Card style={{ ...marginTop }}>
            <List dense={true}>
              <ListItem>
                <ListItemText primary="Balance paciente" />
                <ListItemSecondaryAction>
                  <Typography
                    variant="caption"
                    style={{
                      color:
                        balance > 0
                          ? '#ffc107'
                          : balance < 0
                          ? theme.palette.secondary.main
                          : '#333',
                    }}
                  >
                    {currency(balance)}
                  </Typography>
                </ListItemSecondaryAction>
              </ListItem>
              {/* <ListItem>
                <ListItemText primary="Balance usado" />
                <ListItemSecondaryAction>
                  <Typography variant="caption">
                    {currency(data.positiveBalance)}
                  </Typography>
                </ListItemSecondaryAction>
              </ListItem> */}
              {data.isPaymentAdded && (
                <ListItem>
                  <ListItemIcon>
                    <IconButton
                      aria-label="removePayment"
                      color="primary"
                      onClick={removePayment}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </ListItemIcon>
                  <ListItemText primary={data.paymentMethod} />
                  <ListItemSecondaryAction>
                    <Typography variant="caption">
                      {currency(data.paymentAmount)}
                    </Typography>
                  </ListItemSecondaryAction>
                </ListItem>
              )}
              {!data.isPaymentAdded && (
                <ListItem>
                  <ListItemText primary="Agregar método de pago" />
                  <ListItemSecondaryAction>
                    <IconButton
                      aria-label="addPayment"
                      color="secondary"
                      onClick={showPayDialog}
                    >
                      <AddCircleIcon fontSize="small" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )}
              {data.payments &&
                data.payments.length > 0 &&
                data.payments.map((pay) => (
                  <ListItem key={pay.createdAt}>
                    <ListItemText
                      primary={pay.method}
                      secondary={getPrettyDate(pay.createdAt)}
                    />
                    <ListItemSecondaryAction>
                      <Typography variant="caption">
                        {currency(pay.amount)}
                      </Typography>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
            </List>
          </Card>

          <Card style={{ ...marginTop }}>
            <List dense={true}>
              <ListItem>
                <ListItemText primary="Total por pagar" />
                <ListItemSecondaryAction>
                  <Typography
                    variant="caption"
                    style={{
                      ...totalStyle,
                      color:
                        data.outstandingBalance > 0
                          ? theme.palette.secondary.main
                          : '#333',
                    }}
                  >
                    {currency(data.outstandingBalance)}
                  </Typography>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </Card>
        </Grid>
      </Grid>
    );
  },

  PaymentDialog({ visible, onHide, onSave, total, notify, classes }) {
    const [payment, setPayment] = useState({
      method: '',
      amount: '',
    });

    const isValid = () => {
      if (payment.method && payment.amount > 0)
        if (payment.amount > total) {
          notify('info', 'El pago no puede ser mayor que el total a pagar');
        } else {
          onSave(payment);
          setPayment({
            method: '',
            amount: '',
          });
        }
    };

    const hide = () => {
      onHide();
      setPayment({
        method: '',
        amount: '',
      });
    };

    return (
      <Dialog
        open={visible}
        onClose={onHide}
        aria-labelledby="dialog-pay"
        maxWidth="xs"
        BackdropProps={{
          classes: {
            root: classes.root,
          },
        }}
      >
        <DialogTitle id="dialog-pay">Agregar pago</DialogTitle>
        <DialogContent>
          <FormControl
            fullWidth
            size="small"
            margin="normal"
            variant="outlined"
            required={true}
            error={!payment.method}
          >
            <InputLabel id="payment-method-label">Método de pago</InputLabel>
            <Select
              labelId="payment-method-label"
              id="payment-method"
              value={payment.method}
              onChange={(ev) =>
                setPayment({ ...payment, method: ev.target.value })
              }
              label="Método de pago"
            >
              <MenuItem value="Efectivo/Cheque">Efectivo/Cheque</MenuItem>
              <MenuItem value="Tarjeta">Tarjeta</MenuItem>
              <MenuItem value="Transferencia">Transferencia</MenuItem>
            </Select>
          </FormControl>

          <CurrencyTextField
            fullWidth
            required={true}
            error={payment.amount <= 0}
            label="Cantidad"
            size="small"
            margin="normal"
            variant="outlined"
            value={payment.amount}
            currencySymbol="$"
            outputFormat="number"
            onChange={(event, value) =>
              setPayment({ ...payment, amount: value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={hide} color="primary">
            Cancelar
          </Button>
          <Button onClick={isValid} color="secondary">
            Agregar
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
};

export default RegistryService;
