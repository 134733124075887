import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';
import { Menu, ButtonBase } from '@material-ui/core';
import moment from 'moment';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
class CustomToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { anchorMenu: null, miniCalendarDate: new Date() };
  }
  handleNavigateToDate(date) {
    this.props.onView('day');
    this.props.onNavigate('DATE', new Date(moment(date).format()));
    this.setState({ anchorMenu: null });
  }
  render() {
    let {
      localizer: { messages },
      label,
    } = this.props;
    let navigate = {
      PREVIOUS: 'PREV',
      NEXT: 'NEXT',
      TODAY: 'TODAY',
      DATE: 'DATE',
    };

    // let views = {
    //   MONTH: "month",
    //   WEEK: "week",
    //   WORK_WEEK: "work_week",
    //   DAY: "day",
    //   AGENDA: "agenda",
    // };

    return (
      <div className="rbc-toolbar">
        <span className="rbc-btn-group">
          <button
            type="button"
            onClick={this.navigate.bind(null, navigate.TODAY)}
          >
            {messages.today}
          </button>
          <button
            type="button"
            onClick={this.navigate.bind(null, navigate.PREVIOUS)}
          >
            {messages.previous}
          </button>
          <button
            type="button"
            onClick={this.navigate.bind(null, navigate.NEXT)}
          >
            {messages.next}
          </button>
        </span>

        <ButtonBase
          style={{ flexGrow: 1 }}
          onClick={(event) => {
            this.setState({ anchorMenu: event.currentTarget });
          }}
        >
          <span style={{ cursor: 'default' }} className="rbc-toolbar-label">
            {label}
          </span>
        </ButtonBase>
        <Menu
          id="miniCalendarMenu"
          anchorEl={this.state.anchorMenu}
          keepMounted
          open={Boolean(this.state.anchorMenu)}
          onClose={() => {
            this.setState({ anchorMenu: null });
          }}
        >
          <DayPicker
            onDayClick={(day, modifiers, e) => {
              this.handleNavigateToDate(day);
            }}
          ></DayPicker>
        </Menu>
        <span className="rbc-btn-group">{this.viewNamesGroup(messages)}</span>
      </div>
    );
  }

  navigate = (action) => {
    this.props.onNavigate(action);
  };

  view = (view) => {
    this.props.onView(view);
  };

  viewNamesGroup(messages) {
    let viewNames = this.props.views;
    const view = this.props.view;

    if (viewNames.length > 1) {
      return viewNames.map((name) => (
        <button
          type="button"
          key={name}
          className={clsx({ 'rbc-active': view === name })}
          onClick={this.view.bind(null, name)}
        >
          {messages[name]}
        </button>
      ));
    }
  }
}

CustomToolbar.propTypes = {
  view: PropTypes.string.isRequired,
  views: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.node.isRequired,
  localizer: PropTypes.object,
  onNavigate: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
};

export default CustomToolbar;
